import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const OWNER_REQUEST = 'OWNER_REQUEST';
export const OWNER_SUCCESS = 'OWNER_SUCCESS';
export const OWNER_FAILURE = 'OWNER_FAILURE';

function fetchOwner() {
  return {
    [CALL_API]: {
      types: [OWNER_REQUEST, OWNER_SUCCESS, OWNER_FAILURE],
      endpoint: Routing.generate('owner_index', null, true),
      schema: Schemas.OWNER,
    },
  };
}

export function loadOwner(requiredFields = []) {
  return (dispatch, getState) => {
    const owner = getState().entities.owner;
    const outdated = getState().outdated.owner;

    if (!outdated && owner && requiredFields.every((key) => sample(owner).hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchOwner());
  };
}

export const OWNER_UPDATE_REQUEST = 'OWNER_UPDATE_REQUEST';
export const OWNER_UPDATE_SUCCESS = 'OWNER_UPDATE_SUCCESS';
export const OWNER_UPDATE_FAILURE = 'OWNER_UPDATE_FAILURE';

function writeOwner(form, ownerId) {
  return {
    [CALL_API]: {
      types: [OWNER_UPDATE_REQUEST, OWNER_UPDATE_SUCCESS, OWNER_UPDATE_FAILURE],
      endpoint: Routing.generate('owner_update', { id: ownerId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.OWNER,
    },
  };
}

export function updateOwner(form, ownerId = null) {
  return (dispatch) => {
    return dispatch(writeOwner(form, ownerId));
  };
}

export const OWNER_FORM_REQUEST = 'OWNER_FORM_REQUEST';
export const OWNER_FORM_SUCCESS = 'OWNER_FORM_SUCCESS';
export const OWNER_FORM_FAILURE = 'OWNER_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [OWNER_FORM_REQUEST, OWNER_FORM_SUCCESS, OWNER_FORM_FAILURE],
      endpoint: Routing.generate('owner_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

export enum GenderEnum {
  GENDER_MALE = 'male',
  GENDER_FEMALE = 'female',
  GENDER_COMPANY = 'company',
  GENDER_NON_BINARY = 'nonbinary',
  GENDER_VARIOUS = 'various',
}

export enum ShapeEnum {
  SHAPE_CIRCLE = 'circle',
  SHAPE_RECTANGLE = 'rectangle',
}

export enum FontEnum {
  FONT_REGULAR = 'regular',
  FONT_BOLD = 'bold',
  FONT_DEMI = 'demi',
  FONT_LIGHT = 'light',
  FONT_HEADLINE = 'headline',
  FONT_CUSTOM = 'custom',
}

export enum AlignmentEnum {
  ALIGN_LEFT = 'left',
  ALIGN_CENTER = 'center',
  ALIGN_RIGHT = 'right',
}

export enum MailingModuleModeEnum {
  MODE_TEXT = 'text',
  MODE_QRCODE = 'qr_code',
  MODE_OBJECT = 'object',
}

export enum TagEnum {
  TAG_COMMERCIAL_VEHICLE = 'cv',
  TAG_ANOTA = 'anota',
  TAG_PASSENGER_CAR = 'pc',
  TAG_PASSENGER_SMART = 'sg',
  TAG_BRAND_FUSO = 'fuso',
  TAG_BRAND_MB = 'mb',
  TAG_BRAND_DAF = 'daf',
  TAG_BRAND_VW = 'vw',
  TAG_BRAND_SKODA = 'skoda',
  TAG_BRAND_HYUNDAI = 'hyu',
  TAG_CLASSIFICATION_PASSENGERCAR = 'passengercar',
  TAG_CLASSIFICATION_TRUCK = 'truck',
  TAG_CLASSIFICATION_TRANSPORTER = 'transporter',
  TAG_CLASSIFICATION_TRAILER = 'trailer',
  TAG_CLASSIFICATION_BUS = 'bus',
  TAG_CLASSIFICATION_OTHER = 'other',
  TAG_WARRANTY = 'warranty',
  TAG_IDLE = 'idle',
  TAG_SERVICETEILEPARTNER = 'service_teile_partner',
  TAG_COVER_PC_MB_FEMALE = 'pc_mb_female',
  TAG_COVER_PC_MB_MALE = 'pc_mb_male',
  TAG_COVER_PC_MB_BUSINESS = 'pc_mb_business',
  TAG_COVER_SMART = 'smart',
  TAG_COVER_TRAPO_PRIVATE = 'trapo_private',
  TAG_COVER_TRAPO_BUSINESS = 'trapo_business',
}

export enum EncryptionEnum {
  ENCRYPTION_SSL = 'ssl',
  ENCRYPTION_TLS = 'tls',
}

export enum EmailTransmitterEnum {
  EMAIL_TRANSMITTER_INXMAIL = 'inxmail',
  EMAIL_TRANSMITTER_SMTP = 'smtp',
}

export enum SeedsModeEnum {
  EELOY = 'eeloy',
  MBVD = 'mbvd',
}

import { CALL_API } from './middleware';
import { Schemas } from './schemas';

export const CUSTOMERMEMO_FORM_REQUEST = 'CUSTOMERMEMO_FORM_REQUEST';
export const CUSTOMERMEMO_FORM_SUCCESS = 'CUSTOMERMEMO_FORM_SUCCESS';
export const CUSTOMERMEMO_FORM_FAILURE = 'CUSTOMERMEMO_FORM_FAILURE';

function fetchCustomerMemo() {
  return {
    [CALL_API]: {
      types: [CUSTOMERMEMO_FORM_REQUEST, CUSTOMERMEMO_FORM_SUCCESS, CUSTOMERMEMO_FORM_FAILURE],
      endpoint: Routing.generate('customer_memo', true),
      schema: Schemas.FORM,
    },
  };
}

export function initCustomerMemo() {
  return (dispatch, getState) => {
    const form = getState().forms.customer_memo;

    if (form) {
      return null;
    }

    return dispatch(fetchCustomerMemo());
  };
}

export const CUSTOMERMEMO_UPDATE_REQUEST = 'CUSTOMERMEMO_UPDATE_REQUEST';
export const CUSTOMERMEMO_UPDATE_SUCCESS = 'CUSTOMERMEMO_UPDATE_SUCCESS';
export const CUSTOMERMEMO_UPDATE_FAILURE = 'CUSTOMERMEMO_UPDATE_FAILURE';

function writeCustomerMemo(customerId, form) {
  return {
    [CALL_API]: {
      types: [CUSTOMERMEMO_UPDATE_REQUEST, CUSTOMERMEMO_UPDATE_SUCCESS, CUSTOMERMEMO_UPDATE_FAILURE],
      endpoint: Routing.generate('customer_memoupdate', { id: customerId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.CUSTOMER,
    },
  };
}

export function updateCustomerMemo(customerId, form) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    return dispatch(writeCustomerMemo(customerId, form));
  };
}

import React, { memo } from 'react';
import { Box, Container, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import Translate from '../../components/service/Translate';
import catImage from '../../theme/images/error/cat.svg';
import foxImage from '../../theme/images/error/fox.svg';
import owlImage from '../../theme/images/error/owl.svg';
import robotImage from '../../theme/images/error/robot.svg';
import serverImage from '../../theme/images/error/server.svg';
import { AccentColorButton, ColorActions } from '../../components/StyledElements/StyledElements';

type ErrorPageProps = {
  status?: number;
  showButton?: boolean;
};

const errorImages = [catImage, foxImage, owlImage, robotImage, serverImage];
const generateRndInt = (max: number): number => Math.floor(Math.random() * max);

function ErrorPage({ status, showButton = true }: ErrorPageProps) {
  const navigate = useNavigate();
  const randomImage = errorImages[generateRndInt(errorImages.length)];

  const getStatusText = (status: number | undefined) => {
    switch (status) {
      case 400:
        return '400: General software error';
      case 403:
        return "403: You don't have access";
      case 404:
        return '404: The page you are looking for is not available';
      case 500:
        return '500: Error';
      default:
        return 'An error occurred';
    }
  };

  const handleGoBack = () => {
    if (window.history.length > 1) {
      navigate(-1);
    } else {
      navigate('/');
    }
  };

  return (
    <Box
      component="main"
      sx={{
        alignItems: 'center',
        display: 'flex',
        flexGrow: 1,
        py: '80px',
      }}
    >
      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            mb: 6,
          }}
        >
          <Box
            alt="Error image"
            component="img"
            src={randomImage}
            sx={{
              height: 250,
              width: 'auto',
            }}
          />
        </Box>
        <Typography align="center" variant="h5">
          <Translate>{getStatusText(status)}</Translate>
        </Typography>
        <Typography align="center" color="text.secondary" sx={{ mt: 0.5 }}>
          <Translate>Please contact an administrator.</Translate>
        </Typography>
        {showButton && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mt: 6,
            }}
          >
            <ColorActions>
              <AccentColorButton variant="contained" color="primary" onClick={handleGoBack}>
                <ArrowBack />
                <Translate>Back</Translate>
              </AccentColorButton>
            </ColorActions>
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default memo(ErrorPage);

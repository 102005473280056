import { Box } from '@mui/material';
import React from 'react';

type IFrameProps = {
  title: string;
  url: string;
};

function IFrame({ title, url }: IFrameProps) {
  return (
    <Box
      sx={{
        m: `24px`,
        height: 'calc(100vh - (2*2*3*8px) - (2*3*8px) - 16px)',
        boxShadow:
          '0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)',
      }}
    >
      <iframe src={url} style={{ border: 0 }} width="100%" height="100%" title={title} />
    </Box>
  );
}

export default IFrame;

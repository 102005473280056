import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';

import { OWNER_INTERFACE_TYPE } from '../../utils/Constants';

import ACL from '../../components/ACL';
import Translate from '../../components/service/Translate';
import Summary from './components/Summary';
import List from './components/List';
import { Typography } from '@mui/material';
import ErrorBoundary from '../../components/ErrorBoundary';
import { ColorActions, Spacer, Title, ToolbarRoot } from '../../components/StyledElements/StyledElements';
import { SeedsModeEnum } from '../../utils/Enums';

class OwnerInterfaceIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
    summaryTypes: PropTypes.array,
    owner: PropTypes.object,
    ownerInterface: PropTypes.object,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_ADMIN'],
    resultset: [],
    summaryTypes: [
      OWNER_INTERFACE_TYPE.TYPE_ALPHAPLUS,
      OWNER_INTERFACE_TYPE.TYPE_ALPHAPLUS_RZ,
      OWNER_INTERFACE_TYPE.TYPE_CARE,
      OWNER_INTERFACE_TYPE.TYPE_CARE_AHP,
      OWNER_INTERFACE_TYPE.TYPE_CIDCAR,
      OWNER_INTERFACE_TYPE.TYPE_CustomerOne,
      OWNER_INTERFACE_TYPE.TYPE_AUTOLINE,
    ],
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return !isEqual(nextProps.owner, this.props.owner) || !isEqual(nextProps.ownerInterface, this.props.ownerInterface);
  }

  renderActionbar = () => {
    return <div />;
  };

  renderContent = () => {
    const { owner, ownerInterface, summaryTypes, inMbvdMode } = this.props;

    if (!ownerInterface || !owner) {
      return null;
    }

    if (summaryTypes.includes(ownerInterface.type)) {
      return (
        <ErrorBoundary>
          <Summary ownerInterface={ownerInterface} />
        </ErrorBoundary>
      );
    }

    return (
      <ErrorBoundary>
        {' '}
        <List mbvdMode={inMbvdMode} />
      </ErrorBoundary>
    );
  };

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        <div>
          <ToolbarRoot>
            <Title>
              <Typography variant="h5" id="tableTitle">
                <Translate>Schnittstellenstatus</Translate>
              </Typography>
            </Title>
            <Spacer />
            <ColorActions>{this.renderActionbar()}</ColorActions>
          </ToolbarRoot>
        </div>
        {this.renderContent()}
      </ACL>
    );
  }
}

function mapStateToProps(state) {
  const {
    app: { seedsMode },
    entities: { owner, ownerinterface },
  } = state;

  const ownerInstance = typeof owner !== 'undefined' ? owner[1] : null;
  const ownerInterface = typeof ownerinterface !== 'undefined' ? ownerinterface[1] : null;

  return {
    inMbvdMode: seedsMode === SeedsModeEnum.MBVD,
    owner: ownerInstance,
    ownerInterface: ownerInterface,
  };
}

export default connect(mapStateToProps, {})(OwnerInterfaceIndex);

import { ThemeOptions } from '@mui/material/styles/createTheme';
import { createComponents } from './CreateComponents';
import { createPalette } from './CreatePalette';
import { createShadows } from './CreateShadows';
import type { ColorPreset, Contrast } from '..';

interface Config {
  colorPreset?: ColorPreset;
  contrast?: Contrast;
}

// eslint-disable-next-line import/prefer-default-export
export const createOptions = ({ colorPreset, contrast }: Config): ThemeOptions => {
  const palette = createPalette({ colorPreset, contrast });
  const components = createComponents({ palette });
  const shadows = createShadows();

  return {
    components,
    palette,
    shadows,
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { Input, MenuItem, TextField } from '@mui/material';
import Form from '../../../components/Form';
import { MAILING_TYPE, MAILING_FORMAT, MAILING_PERIOD } from '../../../utils/Constants';
import Translate from '../../../components/service/Translate';
import { HiddenInput } from '../../../components/StyledElements/StyledElements';

let MailingForm = (props) => {
  const { values, onSubmit, handleBlur, touched, errors } = props;

  const renderFieldset = (values) => {
    return (
      // eslint-disable-next-line no-undef
      <form onSubmit={handleSubmit} ref={this.handleMount}>
        <TextField select name="type" id="type" label={<Translate>Typ</Translate>} fullWidth>
          <MenuItem value={MAILING_TYPE.STANDARD}>Standard</MenuItem>
          <MenuItem value={MAILING_TYPE.CSV_EXPORT}>CSV Export</MenuItem>
          <MenuItem value={MAILING_TYPE.WARRANTY}>Garantieverlängerung</MenuItem>
          <MenuItem value={MAILING_TYPE.TIRE}>Reifenangebote</MenuItem>
        </TextField>
        <TextField
          id="config_key"
          label={<Translate>Konfigschlüssel</Translate>}
          name="season[config_key]"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="crm_id"
          label={<Translate>CRM ID</Translate>}
          name="season[crm_id]"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="autoselect"
          label={<Translate>AutoSelect</Translate>}
          name="autoselect"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="title"
          label={<Translate>Titel</Translate>}
          name="season[title]"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="starting_url"
          label={<Translate>URL technische Beschreibung</Translate>}
          name="starting_url"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <HiddenInput type="hidden" name="season[campaign_id]" value={values.season.campaignId} />
        <span>Briefpapier</span>
        <TextField select name="format" id="format" label={<Translate>Format</Translate>} fullWidth>
          <MenuItem value={MAILING_FORMAT.LETTER_DEFAULT} primaryText="Brief Standard" />
          <MenuItem value={MAILING_FORMAT.LETTER_REPLY} primaryText="Brief mit Antwortschreiben" />
          <MenuItem value={MAILING_FORMAT.LETTER_PREMIUM} primaryText="Premiumbrief mit Gutschein" />
          <MenuItem value={MAILING_FORMAT.LETTER_VARIABLE} primaryText="Brief mit variabler zweiter Seite" />
          <MenuItem value={MAILING_FORMAT.CARD_DEFAULT} primaryText="Postkarte Standard" />
          <MenuItem value={MAILING_FORMAT.CARD_FOLDED} primaryText="Klappkarte" />
          <MenuItem value={MAILING_FORMAT.CUSTOM_WARRANTY} primaryText="Garantieverlängerung" />
          <MenuItem value={MAILING_FORMAT.CUSTOM_ABSENCE} primaryText="SuperFernbleiber" />
        </TextField>
        <TextField select name="letter" id="letter" label={<Translate>Briefpapier</Translate>} fullWidth></TextField>
        <TextField
          id="location_aware"
          label={<Translate>Standortabhängig?</Translate>}
          name="location_aware"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="decider"
          label={<Translate>Entscheider für Mailing-Bausteine</Translate>}
          name="decider"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <span>Selektion</span>
        <TextField
          id="selection"
          label={<Translate>Format</Translate>}
          name="selection"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="respect_last_execution"
          label={<Translate>Letztes Ausführungsdatum für folgende Kriterien beachten</Translate>}
          name="mailing[respect_last_execution]"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="combine"
          label={<Translate>Mehrere Schreiben pro Kunde zusammenfassen</Translate>}
          name="combine"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <span> Ablaufsteuerung</span>
        <TextField
          id="approval_period"
          label={<Translate>Freigabe bis X Tage vor Monatsende</Translate>}
          name="mailing[approvalPeriod]"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="print_period"
          label={<Translate>Druck bis X Tage nach Monatsanfang</Translate>}
          name="printPeriod"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="archived"
          label={<Translate>Archivieren</Translate>}
          name="archived"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField select name="recurring" id="recurring" label={<Translate>Versand</Translate>} fullWidth>
          <MenuItem value={MAILING_PERIOD.MONTLY} primaryText="monatlich" />
          <MenuItem value={MAILING_FORMAT.INDIVIDUALLY} primaryText="individuell" />
        </TextField>
        <span>Preise</span>
        <TextField
          id="fee_setup"
          label={<Translate>Einrichtungskosten</Translate>}
          name="fee_setup"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="fee_postage"
          label={<Translate>Porto ab</Translate>}
          name="fee_postage"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="fee_basic"
          label={<Translate>Grundgebühr ab</Translate>}
          name="fee_basic"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
        <TextField
          id="fee_print"
          label={<Translate>Druckkosten ab</Translate>}
          name="fee_print"
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
          fullWidth
        />
      </form>
    );
  };

  return <Form initialValues={{}} values={values} onSubmit={onSubmit} name="mailing" renderFieldset={renderFieldset} />;
};

MailingForm.propTypes = {
  values: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default MailingForm;

import selectors from '../../selectors';
import { pickBy, keys, merge, pick } from 'lodash';
import { makeLocationsByAffiliate } from '../Location/selectors_deprecated';

const getStaffs = (state) => state.entities.staff;
const getSignatures = (state) => state.entities.signature;

const getStaffFilter = (state, props) => {
  if (!props) {
    return null;
  }

  if (props.staffId) {
    return parseInt(props.staffId);
  }

  if (props?.match?.params?.staffId) {
    parseInt(props.match.params.staffId);
  }

  return null;
};

export const makeStaffById = () => {
  return selectors([getStaffFilter, getStaffs], (staffId, staffs) => {
    return staffs[staffId];
  });
};

const getStaffById = makeStaffById();
export const makeStaffWithSignatures = () => {
  return selectors([getStaffById, getSignatures], (staff, signatures) => {
    if (staff.mailingSignatures && signatures) {
      let staffWithSignatures = merge({}, staff);
      staffWithSignatures.mailingSignatures = Object.values(pick(signatures, staff.mailingSignatures));
      return staffWithSignatures;
    } else {
      return staff;
    }
  });
};

const getLocationsByAffiliate = makeLocationsByAffiliate();
export const makeStaffByAffiliate = () => {
  return selectors([getLocationsByAffiliate, getStaffs], (locations, staffs) => {
    const locationIds = keys(locations);

    if (!locationIds.length) {
      return {};
    }

    return pickBy(staffs, (obj) => {
      return obj.locations.some((loc) => locationIds.includes(loc.toString()));
    });
  });
};

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper, Stack } from '@mui/material';
import Translate from '../../../components/service/Translate';
import { isEqual } from 'lodash';
import Log from './Log';
import Edit from './Edit';
import Show from './Show';
import StaffList from '../../Staff/List';
import LocationList from '../../Location/List';
import FullWidthTabs from '../../../components/FullWidthTabs';
import ErrorBoundary from '../../../components/ErrorBoundary';

class Grid extends Component {
  static propTypes = {
    owner: PropTypes.object.isRequired,
    onFormMount: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    mode: PropTypes.string,
  };

  static defaultProps = {
    mode: 'show',
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return !isEqual(nextProps.owner, this.props.owner) || !isEqual(nextProps.mode, this.props.mode);
  };

  renderContent() {
    const { owner, onFormMount, onSubmit, onValidityChange, mode, bindSubmitForm } = this.props;

    if (mode === 'editing') {
      return (
        <ErrorBoundary>
          <Edit
            bindSubmitForm={bindSubmitForm}
            onMount={onFormMount}
            onSubmit={onSubmit}
            onValidityChange={onValidityChange}
            ownerId={owner.id}
          />
        </ErrorBoundary>
      );
    }

    if (!owner) {
      return <Translate>No entry found.</Translate>;
    }

    return (
      <ErrorBoundary>
        <Show owner={owner} />
      </ErrorBoundary>
    );
  }

  render() {
    const { owner } = this.props;

    if (!owner) {
      return null;
    }

    const tabs = [
      { title: 'Settings', content: this.renderContent() },
      {
        title: 'Log',
        content: (
          <ErrorBoundary>
            <Log owner={owner} />
          </ErrorBoundary>
        ),
      },
    ];

    const tabSecond = [
      {
        title: 'Locations',
        content: (
          <ErrorBoundary>
            <LocationList showLocationSetting={false} embedded={true} displaySelectAll={false} fitted={true} />
          </ErrorBoundary>
        ),
      },
      {
        title: 'Mitarbeiter',
        content: (
          <ErrorBoundary>
            <StaffList embedded={true} displaySelectAll={false} fitted={true} />
          </ErrorBoundary>
        ),
      },
    ];

    return (
      <Stack spacing={3}>
        <Paper>
          <ErrorBoundary>
            <FullWidthTabs tabs={tabs} />
          </ErrorBoundary>
        </Paper>
        <Paper>
          <ErrorBoundary>
            <FullWidthTabs tabs={tabSecond} />
          </ErrorBoundary>
        </Paper>
      </Stack>
    );
  }
}

export default Grid;

import React from 'react';
import {
  Paper,
  Typography,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import { Recipient } from '../../StatisticCampaignTypes';
import { calcRateToString } from '../../utils';
import Translate from '../../../../components/service/Translate';

type StatisticTableProps = {
  title: string;
  header: string;
  rows: Recipient;
  context: string;
};

export default function StatisticTable({ title, header, rows, context }: StatisticTableProps) {
  return (
    <Paper sx={{ width: '100%', p: 2 }}>
      <Typography variant="h5" gutterBottom>
        {title}
      </Typography>
      <TableContainer component={Box}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{header}</TableCell>
              <TableCell align="right">Anzahl</TableCell>
              <TableCell align="right">Anteil</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.data.map((row) => (
              <TableRow key={row.label} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">
                  <Translate context={context}>{row.label}</Translate>
                </TableCell>
                <TableCell align="right">{row.count.toLocaleString('de-DE')}</TableCell>
                <TableCell align="right">{calcRateToString(row.count, rows.total)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}

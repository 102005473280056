import selectors from '../../selectors';
import { pick, pickBy, isEmpty, merge, findKey, omitBy } from 'lodash';

// eslint-disable-next-line no-unused-vars
const getCampaigns = (state, props) => state.entities.campaign;
// eslint-disable-next-line no-unused-vars
const getSeasons = (state, props) => state.entities.season;
// eslint-disable-next-line no-unused-vars
const getCausalities = (state, props) => state.entities.seasonCausality;
const getCampaignConfigFiles = (state) => state.entities.campaignConfigFile;

const getCampaignFilter = (state, props) =>
  props?.match?.params?.campaignId ? parseInt(props.match.params.campaignId) : (props.campaignId ?? null);
const getSeasonTypeFilter = (state, props) => props.seasonType;
const getCampaignConfigParamMode = (state, props) => props?.match?.params?.mode;
const getCampaignConfigParamId = (state, props) =>
  props?.match?.params?.campaignConfigParamId
    ? parseInt(props.match.params.campaignConfigParamId)
    : (props.campaignConfigParamId ?? null);
// eslint-disable-next-line no-unused-vars
const getCampaignConfigParams = (state, props) => state.entities.campaignConfigParam;

export const makeCampaignById = () => {
  return selectors([getCampaignFilter, getCampaigns], (campaignId, campaigns) => {
    return campaigns && campaigns[campaignId] ? campaigns[campaignId] : null;
  });
};
const getCampaignById = makeCampaignById();

export const makeCampaignWithConfigFiles = () => {
  return selectors([getCampaignById, getCampaignConfigFiles], (campaign, campaignConfigFiles) => {
    if (campaign && campaignConfigFiles && !isEmpty(campaign.campaignConfigFiles)) {
      return Object.assign({}, campaign, {
        campaignConfigFiles: Object.values(pick(campaignConfigFiles, campaign.campaignConfigFiles)),
      });
    }
    return campaign;
  });
};

export const makeCampaignsBySingularSeason = () => {
  return selectors(
    [getCampaigns, getCampaignConfigParams, getCampaignConfigParamMode, getCampaignConfigParamId],
    (campaigns, params, mode, id) => {
      const out = pickBy(campaigns, { isSingularCampaign: true });
      return params && mode === 'duplicate' && !isEmpty(params) && params[id].campaign
        ? omitBy(out, { id: params[id].campaign })
        : out;
    },
  );
};

export const makeCampaignSeasons = () => {
  return selectors([getSeasons, getCampaignById], (seasons, campaign) => {
    if (campaign && campaign.seasons && seasons) {
      return pick(seasons, campaign.seasons);
    }

    return null;
  });
};

const getCampaignSeasons = makeCampaignSeasons();
export const makeCampaignSeasonsByType = () => {
  return selectors([getSeasonTypeFilter, getCampaignSeasons], (seasonType, seasons) => {
    if (seasonType && !isEmpty(seasons)) {
      return pickBy(seasons, { type: seasonType });
    }

    return null;
  });
};

const getCampaignByIdFilter = (state, props) =>
  props?.match?.params?.campaignId ? parseInt(props.match.params.campaignId) : null;

const getCampaignsSelector = () => {
  return selectors([getCampaigns, getSeasons], (campaigns, seasons) => {
    if (campaigns === undefined) {
      return [];
    } else if (seasons === undefined) {
      return Object.values(campaigns).sort(group);
    } else {
      return Object.values(campaigns).sort(group);
    }
  });
};

const getCampaignByIdSelector = () => {
  return selectors([getCampaigns, getCampaignByIdFilter], (campaigns, filter) => {
    if (campaigns === undefined) {
      return null;
    } else {
      return pickBy(campaigns, { id: filter });
    }
  });
};

//Sort
const group = (campaignA, campaignB) => {
  return campaignA.rootCampaignId - campaignB.rootCampaignId;
};

const getSeasonId = (state, props) => (props.seasonId ? parseInt(props.seasonId) : null);

export const makeCausalityBySeasonId = () => {
  return selectors([getSeasonId, getCausalities], (seasonId, causalities) => {
    if (!isEmpty(causalities)) {
      return causalities[findKey(causalities, { season: seasonId })];
    }
    return {};
  });
};

export const makeCauseSeasons = () => {
  return selectors([getSeasonId, getSeasons], (seasonId, seasons) => {
    return merge(
      {},
      pickBy(seasons, (value) => {
        return value.type !== 'calling' && value.id !== seasonId;
      }),
    );
  });
};

export { getCampaignsSelector, getCampaignByIdSelector, getSeasons, getCampaigns };

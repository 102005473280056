import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEmpty, findKey, merge, sortBy } from 'lodash';
import Form from '../../../components/Form';
import Translate from '../../../components/service/Translate';
import { TextField, MenuItem, Input, Paper } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { initForm } from '../actions';
import ValidationErrors from '../../../ValidationErrors';
import * as Yup from 'yup';
import { withRouter } from '../../../withRouter';
import { EmptyMenuItem, HiddenInput } from '../../../components/StyledElements/StyledElements';
import { compose } from 'redux';
import Grid from '@mui/material/Grid2';

let validationSchema = Yup.object().shape({
  setting: Yup.object()
    .shape({
      id: Yup.string(),
      setting: Yup.string().required(ValidationErrors.required),
    })
    .required(ValidationErrors.required),
  value: Yup.string().required(ValidationErrors.required),
  location: Yup.number().required(ValidationErrors.required),
});

class LocationSettingEdit extends Component {
  static propTypes = {
    values: PropTypes.object,
    style: PropTypes.object,
  };

  static defaultProps = {
    values: {},
    style: {},
  };

  state = {
    key: false,
    children: [],
  };

  componentDidMount = () => {
    this.props.initForm();

    this.setState({ key: Math.random() });
  };

  getSettingId = (event, value, setFieldValue) => {
    setFieldValue(
      'setting[id]',
      findKey(this.props.setting, (s) => {
        return s.primaryText === value;
      }),
    );
    setFieldValue('setting[setting]', value);
  };

  renderSelect = (item) => {
    return (
      <MenuItem key={Math.random()} value={item.id}>
        {item.name} - {item.city}{' '}
        {item.tag ? (
          <>
            <> (</>
            <Translate>{item.tag}</Translate>
            <>) </>
          </>
        ) : (
          ''
        )}
      </MenuItem>
    );
  };

  renderFieldset = (props) => {
    const { locationSettingId, location, setting } = this.props;
    const { values, handleChange, handleBlur, setFieldValue, touched, errors } = props;

    const dataSource = setting ? Object.values(setting) : [];

    return (
      <Grid container spacing={3}>
        {locationSettingId === null ? (
          <Grid size={12}>
            <TextField
              select
              id="location"
              name="location"
              label={<Translate>Standort</Translate>}
              onChange={handleChange('location')}
              value={values.location ? values.location : ''}
              helperText={touched.location ? errors.location : ''}
              error={touched.location && Boolean(errors.location)}
            >
              <EmptyMenuItem key="locationNull" value={''} />
              {location ? sortBy(location, ['city', 'tag']).map(this.renderSelect) : ''}
            </TextField>
          </Grid>
        ) : null}
        <HiddenInput type="hidden" name="updateToken" defaultValue={values.updateToken ? values.updateToken : ''} />
        {locationSettingId === null ? (
          <Grid size={12}>
            <Autocomplete
              freeSolo
              id="setting"
              name="setting"
              size="small"
              options={dataSource}
              getOptionLabel={(dataSource) => dataSource.primaryText}
              onInputChange={(event, value) => this.getSettingId(event, value, setFieldValue)}
              renderInput={(params) => (
                <TextField
                  fullWidth
                  {...params}
                  label={<Translate>Schlüssel</Translate>}
                  name="setting"
                  helperText={touched.setting ? errors.setting : ''}
                  error={touched.setting && Boolean(errors.setting)}
                />
              )}
            />
          </Grid>
        ) : null}
        <Grid size={12}>
          <TextField
            fullWidth
            multiline
            id="value"
            name="value"
            label={<Translate>Einstellung</Translate>}
            onChange={handleChange}
            onBlur={handleBlur}
            defaultValue={locationSettingId && values.value ? values.value : ''}
            helperText={touched.value ? errors.value : ''}
            error={touched.value && Boolean(errors.value)}
          />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { locationSetting, location, setting, locationSettingId, OnMount, onCancel, form, token, onSubmit } =
      this.props;
    const { ...defaultValues } = this.props.values;
    let initialValues;

    if ((locationSettingId && isEmpty(setting)) || isEmpty(location)) {
      return null;
    }

    if (!locationSettingId && isEmpty(form)) {
      // if locationId is missing form should not be empty
      return null;
    }

    if (!isEmpty(form)) {
      defaultValues.updateToken = token;
    }

    initialValues =
      locationSetting && locationSettingId
        ? merge(
            {},
            locationSetting[locationSettingId],
            {
              setting: {
                id: locationSetting[locationSettingId].setting,
                setting: setting[locationSetting[locationSettingId].setting].primaryText,
              },
            },
            { location: locationSetting[locationSettingId].location.id },
          )
        : defaultValues;

    return (
      <Paper>
        <Form
          onMount={OnMount}
          name="locationSetting"
          onSubmit={onSubmit}
          onCancel={onCancel}
          values={initialValues}
          validationSchema={validationSchema}
          initialValues={initialValues}
          renderFieldset={this.renderFieldset}
        />
      </Paper>
    );
  }
}

const makeMapStateToProps = () => {
  return (state, props) => {
    const {
      entities: { locationsetting, setting, location },
      forms: { locationsetting: form },
    } = state;

    if (!form) {
      return {};
    }

    return {
      locationSetting: locationsetting,
      setting: setting,
      location: location,
      locationSettingId: props?.match?.params?.locationSettingId ?? null,
      token: !isEmpty(form) ? form.updateToken : '',
      form: form,
    };
  };
};

const enhance = compose(withRouter, connect(makeMapStateToProps, { initForm }));

export default enhance(LocationSettingEdit);

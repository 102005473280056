import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const CAMPAIGNCONFIGPARAMS_REQUEST = 'CAMPAIGNCONFIGPARAMS_REQUEST';
export const CAMPAIGNCONFIGPARAMS_SUCCESS = 'CAMPAIGNCONFIGPARAMS_SUCCESS';
export const CAMPAIGNCONFIGPARAMS_FAILURE = 'CAMPAIGNCONFIGPARAMS_FAILURE';

function fetchCampaignConfigParams(campaignId, sortColumns, sortOrders) {
  if (campaignId) {
    return {
      [CALL_API]: {
        types: [CAMPAIGNCONFIGPARAMS_REQUEST, CAMPAIGNCONFIGPARAMS_SUCCESS, CAMPAIGNCONFIGPARAMS_FAILURE],
        endpoint: Routing.generate('campaignconfigparam_campaign', { id: campaignId }, true),
        schema: Schemas.CAMPAIGNCONFIGPARAMS,
      },
    };
  } else {
    return {
      [CALL_API]: {
        types: [CAMPAIGNCONFIGPARAMS_REQUEST, CAMPAIGNCONFIGPARAMS_SUCCESS, CAMPAIGNCONFIGPARAMS_FAILURE],
        endpoint: Routing.generate(
          'campaignconfigparam_index',
          { sortColumns: sortColumns, sortOrders: sortOrders },
          true,
        ),
        schema: Schemas.CAMPAIGNCONFIGPARAMS,
      },
    };
  }
}

export function loadCampaignConfigParams(campaignId = null, sortColumns = [], sortOrders = [], requiredFields = []) {
  return (dispatch, getState) => {
    const campaignConfigParam = getState().entities.campaignConfigParam;
    const resultset = getState().resultsets.campaignConfigParam;
    const outdated = getState().outdated.campaignConfigParam;

    if (
      !outdated
      && resultset
      && resultset.length
      && campaignConfigParam
      && requiredFields.every((key) => sample(campaignConfigParam).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchCampaignConfigParams(campaignId, sortColumns, sortOrders));
  };
}

export const CAMPAIGNCONFIGPARAM_REQUEST = 'CAMPAIGNCONFIGPARAM_REQUEST';
export const CAMPAIGNCONFIGPARAM_SUCCESS = 'CAMPAIGNCONFIGPARAM_SUCCESS';
export const CAMPAIGNCONFIGPARAM_FAILURE = 'CAMPAIGNCONFIGPARAM_FAILURE';

function fetchCampaignConfigParam(id) {
  return {
    [CALL_API]: {
      types: [CAMPAIGNCONFIGPARAM_REQUEST, CAMPAIGNCONFIGPARAM_SUCCESS, CAMPAIGNCONFIGPARAM_FAILURE],
      endpoint: Routing.generate('campaignconfigparam_show', { id: id }, true),
      schema: Schemas.CAMPAIGNCONFIGPARAM,
    },
  };
}

export function loadCampaignConfigParam(id, requiredFields = []) {
  return (dispatch, getState) => {
    const campaignConfigParams = getState().entities.campaignConfigParams;

    if (
      campaignConfigParams
      && campaignConfigParams[id]
      && requiredFields.every((key) => campaignConfigParams[id].hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchCampaignConfigParam(id));
  };
}

export const CAMPAIGNCONFIGPARAM_UPDATE_REQUEST = 'CAMPAIGNCONFIGPARAM_UPDATE_REQUEST';
export const CAMPAIGNCONFIGPARAM_UPDATE_SUCCESS = 'CAMPAIGNCONFIGPARAM_UPDATE_SUCCESS';
export const CAMPAIGNCONFIGPARAM_UPDATE_FAILURE = 'CAMPAIGNCONFIGPARAM_UPDATE_FAILURE';

function writeCampaignConfigParam(form, campaignConfigParamId) {
  return {
    [CALL_API]: {
      types: [
        CAMPAIGNCONFIGPARAM_UPDATE_REQUEST,
        CAMPAIGNCONFIGPARAM_UPDATE_SUCCESS,
        CAMPAIGNCONFIGPARAM_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate('campaignconfigparam_update', { id: campaignConfigParamId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.CAMPAIGNCONFIGPARAM,
    },
  };
}

export function updateCampaignConfigParam(form, campaignConfigParamId = null) {
  return (dispatch) => {
    return dispatch(writeCampaignConfigParam(form, campaignConfigParamId));
  };
}

export const CAMPAIGNCONFIGPARAM_DELETE_REQUEST = 'CAMPAIGNCONFIGPARAM_DELETE_REQUEST';
export const CAMPAIGNCONFIGPARAM_DELETE_SUCCESS = 'CAMPAIGNCONFIGPARAM_DELETE_SUCCESS';
export const CAMPAIGNCONFIGPARAM_DELETE_FAILURE = 'CAMPAIGNCONFIGPARAM_DELETE_FAILURE';

function deleteCampaignConfigParam(campaignConfigParamId, deleteToken) {
  return {
    [CALL_API]: {
      types: [
        CAMPAIGNCONFIGPARAM_DELETE_REQUEST,
        CAMPAIGNCONFIGPARAM_DELETE_SUCCESS,
        CAMPAIGNCONFIGPARAM_DELETE_FAILURE,
      ],
      endpoint: Routing.generate(
        'campaignconfigparam_delete',
        { id: campaignConfigParamId, deleteToken: deleteToken },
        true,
      ),
      method: 'DELETE',
    },
  };
}

export function removeCampaignConfigParam(campaignConfigParamId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteCampaignConfigParam(campaignConfigParamId, deleteToken));
  };
}

export const CAMPAIGNCONFIGPARAMS_PAGED_REQUEST = 'CAMPAIGNCONFIGPARAMS_PAGED_REQUEST';
export const CAMPAIGNCONFIGPARAMS_PAGED_SUCCESS = 'CAMPAIGNCONFIGPARAMS_PAGED_SUCCESS';
export const CAMPAIGNCONFIGPARAMS_PAGED_FAILURE = 'CAMPAIGNCONFIGPARAMS_PAGED_FAILURE';

function fetchCampaignConfigParamsPaged(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join,
  filter = [],
  campaignId = null,
) {
  if (campaignId) {
    return {
      [CALL_API]: {
        types: [
          CAMPAIGNCONFIGPARAMS_PAGED_REQUEST,
          CAMPAIGNCONFIGPARAMS_PAGED_SUCCESS,
          CAMPAIGNCONFIGPARAMS_PAGED_FAILURE,
        ],
        endpoint: Routing.generate(
          'campaignconfigparam_campaign',
          {
            id: campaignId,
            sortColumns: sortColumns,
            sortOrders: sortOrders,
            offset: offset,
            limit: limit,
            join: join,
            filter: filter,
          },
          true,
        ),
        schema: Schemas.CAMPAIGNCONFIGPARAMS,
      },
    };
  } else {
    return {
      [CALL_API]: {
        types: [
          CAMPAIGNCONFIGPARAMS_PAGED_REQUEST,
          CAMPAIGNCONFIGPARAMS_PAGED_SUCCESS,
          CAMPAIGNCONFIGPARAMS_PAGED_FAILURE,
        ],
        endpoint: Routing.generate(
          'campaignconfigparam_index',
          {
            sortColumns: sortColumns,
            sortOrders: sortOrders,
            offset: offset,
            limit: limit,
            join: join,
            filter: filter,
          },
          true,
        ),
        schema: Schemas.CAMPAIGNCONFIGPARAMS,
      },
    };
  }
}

export function loadNext(
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
  campaignId = null,
) {
  return (dispatch, getState) => {
    const campaignConfigParam = getState().entities.campaignConfigParam;
    const pagination =
      campaignConfigParam && getState().pagination.campaignConfigParam
        ? getState().pagination.campaignConfigParam
        : false;

    if (!campaignConfigParam || !pagination) {
      return null;
    }

    return dispatch(fetchCampaignConfigParamsPaged(sortColumns, sortOrders, offset, limit, join, filter, campaignId));
  };
}

export const CAMPAIGNCONFIGPARAM_FORM_REQUEST = 'CAMPAIGNCONFIGPARAM_FORM_REQUEST';
export const CAMPAIGNCONFIGPARAM_FORM_SUCCESS = 'CAMPAIGNCONFIGPARAM_FORM_SUCCESS';
export const CAMPAIGNCONFIGPARAM_FORM_FAILURE = 'CAMPAIGNCONFIGPARAM_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [CAMPAIGNCONFIGPARAM_FORM_REQUEST, CAMPAIGNCONFIGPARAM_FORM_SUCCESS, CAMPAIGNCONFIGPARAM_FORM_FAILURE],
      endpoint: Routing.generate('campaignconfigparam_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

export const CAMPAIGNCONFIGPARAM_DUPLICATE_REQUEST = 'CAMPAIGNCONFIGPARAM_DUPLICATE_REQUEST';
export const CAMPAIGNCONFIGPARAM_DUPLICATE_SUCCESS = 'CAMPAIGNCONFIGPARAM_DUPLICATE_SUCCESS';
export const CAMPAIGNCONFIGPARAM_DUPLICATE_FAILURE = 'CAMPAIGNCONFIGPARAM_DUPLICATE_FAILURE';

export function duplicateCampaignConfigParams(form) {
  return {
    [CALL_API]: {
      types: [
        CAMPAIGNCONFIGPARAM_DUPLICATE_REQUEST,
        CAMPAIGNCONFIGPARAM_DUPLICATE_SUCCESS,
        CAMPAIGNCONFIGPARAM_DUPLICATE_FAILURE,
      ],
      endpoint: Routing.generate('campaignconfigparam_duplicate', {}, true),
      method: 'POST',
      data: form,
      //schema: Schemas.CAMPAIGNCONFIGPARAMS
    },
  };
}

export function duplicateCampaignConfigParamsWithCampaign(form = null) {
  return (dispatch) => {
    return dispatch(duplicateCampaignConfigParams(form));
  };
}

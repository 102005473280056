const FORM_MODE = {
  ADD: 'adding',
  EDIT: 'editing',
  SHOW: 'show',
  NEW: 'new',
};

const SEASON_TYPE = {
  MAILING: 'mailing',
  EMAIL: 'email',
  SMS: 'sms',
};

const MAILING_TYPE = {
  STANDARD: 'common',
  CSV_EXPORT: 'export',
  WARRANTY: 'warranty',
  TIRE: 'tyre',
};

const MAILING_FORMAT = {
  CARD_DEFAULT: 'card_default',
  CARD_FOLDED: 'card_folded',
  CUSTOM_TYRE: 'custom_tyre',
  CUSTOM_WARRANTY: 'custom_warranty',
  LETTER_DEFAULT: 'letter_default',
  LETTER_REPLY: 'letter_reply',
  LETTER_VOUCHER: 'letter_voucher',
  LETTER_PREMIUM: 'letter_premium',
  LETTER_VARIABLE: 'letter_variable',
  CUSTOM_ABSENCE: 'custom_absence',
  RETURN_MAILER: 'return_mailer',
  FLYSHEET_FOUR: 'flysheet_four',
};

const MAILING_PERIOD = {
  MONTLY: 'monthly',
  INDIVIDUALLY: 'individual',
};

const OWNER_INTERFACE_TYPE = {
  TYPE_UPLOAD: 'Upload',
  TYPE_ALPHAPLUS: 'AlphaPlus',
  TYPE_ALPHAPLUS_RZ: 'AlphaPlusRz',
  TYPE_CARE: 'Care',
  TYPE_CARE_AHP: 'CareAhp',
  TYPE_CIDCAR: 'Cidcar',
  TYPE_CustomerOne: 'CustomerOne',
  TYPE_AUTOLINE: 'Autoline',
  TYPE_AUTOLINE_NDL: 'AutolineNdl',
  TYPE_SEMAS: 'SEMAS',
};

const REQUEST_STATUS = {
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

export { FORM_MODE, SEASON_TYPE, MAILING_TYPE, MAILING_FORMAT, MAILING_PERIOD, OWNER_INTERFACE_TYPE, REQUEST_STATUS };

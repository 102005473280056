import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import Owner from './Owner';
import { SeedsModeEnum } from '../../utils/Enums';
import { connect } from 'react-redux';

class OwnerIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_ADMIN'],
    requiredRolesMbvd: ['ROLE_SUPERADMIN'],
  };

  render() {
    const { inMbvdMode, requiredRolesMbvd, requiredRoles } = this.props;

    return (
      <ACL authorized={inMbvdMode ? requiredRolesMbvd : requiredRoles}>
        <Owner />
      </ACL>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: { seedsMode },
  } = state;

  return {
    inMbvdMode: seedsMode === SeedsModeEnum.MBVD,
  };
};

export default connect(mapStateToProps, {})(OwnerIndex);

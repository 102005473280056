import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, isEqual } from 'lodash';
import ErrorBoundary from '../../../components/ErrorBoundary';
import DataGridTable from '../../../components/DataGrid/DataGrid';
import RenderCellExpand from '../../../components/DataGrid/RenderCellExpand';

class CcsScanList extends Component {
  static propTypes = {
    scans: PropTypes.object,
    resultset: PropTypes.array.isRequired,
    initialGridState: PropTypes.object,
    onSortModelChange: PropTypes.func,
  };

  state = {
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        type: 'number',
        width: 30,
      },
      {
        field: 'primaryText',
        headerName: 'Datei',
        relation: null,
        type: 'string',
        id: 'primaryText',
        sort: 'tag.massnId',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'channel',
        headerName: 'Kanal',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'created',
        headerName: 'Erstellt am',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => RenderCellExpand({ ...params, modifier: 'datetime' }),
      },
      {
        field: 'scaned',
        headerName: 'Geprüfte Kunden',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'denied',
        headerName: 'Abgelehnte Kunden',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'imported',
        headerName: 'Eingelesen am',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => RenderCellExpand({ ...params, modifier: 'datetime' }),
      },
    ],
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.scans, this.props.scans)
      || !isEqual(nextProps.resultset, this.props.resultset)
      || !isEqual(nextProps.pagination, this.props.pagination)
    );
  };

  render() {
    const { scans, resultset, pagination, onLoadNext, initialGridState, onSortModelChange } = this.props;
    const { columns } = this.state;

    if (isEmpty(scans)) {
      return null;
    }

    return (
      <ErrorBoundary>
        <DataGridTable
          title="CCS Abgleiche"
          rows={scans}
          iteratableRows={resultset}
          columns={columns}
          disableToolbar={true}
          pagination={pagination}
          onLoadNext={onLoadNext}
          disableSelectionOnClick={true}
          onSortModelChange={onSortModelChange}
          initialState={initialGridState}
        />
      </ErrorBoundary>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, props) {
  const {
    entities: { ccsScan },
    resultsets: { ccsScan: resultset },
    pagination: { ccsScan: pagination },
  } = state;

  return {
    scans: !isEmpty(ccsScan) ? ccsScan : {},
    resultset: resultset,
    pagination: pagination,
  };
}

export default connect(mapStateToProps, {})(CcsScanList);

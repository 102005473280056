const tyreConditions = [
  {
    type: 'General',
    label: 'Einlagerer mit Bedarf - min. ein Angebot',
    description: 'Prüft ob für Einlagerer mit Bedarf min. ein passendes Räder/Reifen Angebot vorliegt.',
    before:
      '{% if wheelPass(car, job.season.campaign, "replacementNeeded") and hasTyreSelection(job.season.campaign, car) %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Einlagerer mit Bedarf - kein Angebot',
    description: 'Prüft ob für Einlagerer mit Bedarf keine passenden Angebote vorliegen.',
    before:
      '{% if wheelPass(car, job.season.campaign, "replacementNeeded") and not hasTyreSelection(job.season.campaign, car) %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Räder/Reifen Auswahl - min. ein Angebot',
    description: 'Prüft ob min. ein passendes Räder/Reifen Angebot vorliegt, unabhängig vom Bedarf.',
    before: '{% if hasTyreSelection(job.season.campaign, car) %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Räder/Reifen Auswahl - kein Angebot',
    description: 'Prüft ob kein passendes Räder/Reifen Angebot vorliegt, unabhängig vom Bedarf.',
    before: '{% if not hasTyreSelection(job.season.campaign, car) %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Einlagerer mit Bedarf',
    description: 'Prüft ob ein Einlagerer Bedarf hat.',
    before: '{% if wheelPass(car, job.season.campaign, "replacementNeeded") %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Einlagerer ohne Bedarf',
    description: 'Prüft ob ein Einlagerer keinen Bedarf hat.',
    before: '{% if not wheelPass(car, job.season.campaign, "replacementNeeded") %}',
    after: '{% endif %}',
  },
];

export default tyreConditions;

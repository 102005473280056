import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import { Table, TableData } from '../../../components/StyledElements/StyledElements';
import { Box } from '@mui/material';

class Show extends Component {
  static propTypes = {
    affiliateId: PropTypes.number.isRequired,
    affiliate: PropTypes.object,
  };

  render() {
    const { affiliate } = this.props;

    if (!affiliate) {
      return null;
    }

    return (
      <Box sx={{ pt: 3 }}>
        <Table width="100%" tableLayout="fixed">
          <tbody>
            <tr>
              <TableData width="20%">
                <Translate>Name</Translate>
              </TableData>
              <TableData width="30%">{affiliate.name}</TableData>
              <TableData width="20%">
                <Translate>City</Translate>
              </TableData>
              <TableData width="30%">{affiliate.city}</TableData>
            </tr>
            <tr>
              <TableData width="20%">
                <Translate>Interface ID</Translate>
              </TableData>
              <TableData width="30%">{affiliate.interfaceId}</TableData>
              <TableData width="20%">
                <Translate>represented by</Translate>
              </TableData>
              <TableData width="30%">
                <Translate>{affiliate.representation}</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'text.secondary',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Sender</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="20%">
                <Translate>Postbox</Translate>
              </TableData>
              <TableData width="30%">{affiliate.poBox}</TableData>
              <TableData width="20%">
                <Translate>Postbox ZIP</Translate>
              </TableData>
              <TableData width="30%">{affiliate.poZip}</TableData>
            </tr>
            <tr>
              <TableData width="20%">
                <Translate>Postbox City</Translate>
              </TableData>
              <TableData width="30%">{affiliate.poCity}</TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'text.secondary',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Appointment contact</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="20%">
                <Translate>Phone</Translate>
              </TableData>
              <TableData width="30%">{affiliate.appointmentPhone}</TableData>
              <TableData width="20%">
                <Translate>Web</Translate>
              </TableData>
              <TableData width="30%">{affiliate.appointmentWww}</TableData>
            </tr>
            <tr>
              <TableData width="20%">
                <Translate>Email</Translate>
              </TableData>
              <TableData width="30%">{affiliate.appointmentEmail}</TableData>
            </tr>
            <tr>
              <TableData
                sx={{
                  color: 'text.secondary',
                  width: 200,
                  pt: 3,
                  fontWeight: 500,
                }}
                colSpan="4"
              >
                <Translate>Letter paper</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="20%">
                <Translate>Premium address?</Translate>
              </TableData>
              <TableData width="30%">
                <Translate>{affiliate.premium ? 'yes' : 'no'}</Translate>
              </TableData>
              <TableData width="20%">
                <Translate>Tag</Translate>
              </TableData>
              <TableData width="30%">
                <Translate>{affiliate.tag}</Translate>
              </TableData>
            </tr>
            <tr>
              <TableData width="20%">
                <Translate>Showing locations below title?</Translate>
              </TableData>
              <TableData width="30%">
                <Translate>{affiliate.showingLocations}</Translate>
              </TableData>
              <TableData width="20%">
                <Translate>Showing city ahead of date?</Translate>
              </TableData>
              <TableData width="30%">
                <Translate>{affiliate.showingCity}</Translate>
              </TableData>
            </tr>
          </tbody>
        </Table>
      </Box>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const affiliateId = ownProps.affiliateId;
  const {
    entities: { affiliate },
  } = state;

  return {
    affiliate: affiliate[affiliateId],
  };
}

export default connect(mapStateToProps, {})(Show);

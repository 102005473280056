import PropTypes from 'prop-types';
import React, { Component } from 'react';
import dateformat from 'dateformat';
import Translate from './Translate';

class DateTime extends Component {
  static propTypes = {
    format: PropTypes.string,
  };

  static defaultProps = {
    format: 'dd.mm.yyyy',
  };

  render() {
    const date =
      this.props.children instanceof Object && this.props.children.date
        ? new Date(this.props.children.date)
        : new Date(this.props.children);

    if (this.props.children && date.getTime() === date.getTime()) {
      return (
        <span>
          <Translate context="date">{dateformat(date, this.props.format)}</Translate>
        </span>
      );
    }

    return null;
  }
}

export default DateTime;

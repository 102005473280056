import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Paper } from '@mui/material';
import Translate from '../../../components/service/Translate';
import { isEmpty } from 'lodash';
import Log from './Log';
import Edit from './Edit';
import Show from './Show';
import Children from './Children';
import StaffList from '../../Staff/List';
import LocationList from '../../Location/List';
import FullWidthTabs from '../../../components/FullWidthTabs';
import ClippedDrawer from '../../../components/ClippedDrawer';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { PaperWidthDockedList } from '../../../components/StyledElements/StyledElements';
import AffiliateSettingIndex from '../../AffiliateSetting/Index';

class Grid extends Component {
  static defaultProps = {
    mode: 'show',
  };

  renderContent() {
    const { affiliates, onFormMount, onSubmit, onValidityChange, mode, current, bindSubmitForm } = this.props;

    if (mode === 'adding') {
      return (
        <ErrorBoundary>
          <Edit
            onMount={onFormMount}
            bindSubmitForm={bindSubmitForm}
            onSubmit={onSubmit}
            onValidityChange={onValidityChange}
            affiliateId={null}
          />
        </ErrorBoundary>
      );
    }

    if (mode === 'editing') {
      return (
        <ErrorBoundary>
          <Edit
            onMount={onFormMount}
            bindSubmitForm={bindSubmitForm}
            onSubmit={onSubmit}
            onValidityChange={onValidityChange}
            affiliateId={current.id}
          />
        </ErrorBoundary>
      );
    }

    if (isEmpty(affiliates)) {
      return <Translate>No entries found.</Translate>;
    }

    if (current) {
      return (
        <ErrorBoundary>
          <Show affiliateId={current ? current.id : null} />
        </ErrorBoundary>
      );
    }
  }

  render() {
    const { affiliates, current, onSelection, resultset, loading, success } = this.props;

    const tabs = [
      { title: 'Settings', content: this.renderContent() },
      {
        title: 'Tags',
        content: (
          <ErrorBoundary>
            <Children affiliateId={current ? current.id : null} />
          </ErrorBoundary>
        ),
      },
      {
        title: 'Log',
        content: (
          <ErrorBoundary>
            <Log affiliateId={current ? current.id : null} />
          </ErrorBoundary>
        ),
      },
    ];

    const tabSecond = [
      {
        title: 'Locations',
        content: (
          <ErrorBoundary>
            <LocationList
              affiliateId={current ? current.id : null}
              showLocationSetting={false}
              displaySelectAll={false}
              fitted={true}
              embedded={true}
            />
          </ErrorBoundary>
        ),
      },
      {
        title: 'Mitarbeiter',
        content: (
          <ErrorBoundary>
            <StaffList
              affiliateId={current ? current.id : null}
              displaySelectAll={false}
              fitted={true}
              embedded={true}
            />
          </ErrorBoundary>
        ),
      },
      {
        title: 'Settings',
        content: (
          <ErrorBoundary>
            <AffiliateSettingIndex
              affiliateId={current ? current.id : null}
              displaySelectAll={false}
              fitted={true}
              embedded={true}
            />
          </ErrorBoundary>
        ),
      },
    ];

    if (!isEmpty(loading) && !success.includes('AFFILIATES')) {
      return (
        <Paper>
          {' '}
          <Translate>Data loading...</Translate>{' '}
        </Paper>
      );
    }

    return (
      <>
        <ErrorBoundary>
          <ClippedDrawer
            onItemSelect={onSelection}
            resultset={resultset}
            listItems={affiliates}
            defaultSelectedIndex={current ? current.id : null}
          />
        </ErrorBoundary>

        <PaperWidthDockedList>
          <ErrorBoundary>
            {' '}
            <FullWidthTabs tabs={tabs} />
          </ErrorBoundary>
        </PaperWidthDockedList>

        <PaperWidthDockedList>
          <ErrorBoundary>
            {' '}
            <FullWidthTabs tabs={tabSecond} />
          </ErrorBoundary>
        </PaperWidthDockedList>
      </>
    );
  }
}

Grid.propTypes = {
  resultset: PropTypes.array.isRequired,
  affiliates: PropTypes.object.isRequired,
  onFormMount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onValidityChange: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
  mode: PropTypes.string,
  current: PropTypes.object,
};

export default Grid;

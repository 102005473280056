import React, { Component } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { MenuItem, Button, Menu, ListItemIcon, ListItemText, Box } from '@mui/material';
import ActionAccountCircle from '@mui/icons-material/AccountCircle';
import ActionSettings from '@mui/icons-material/Settings';
import ActionPowerSettingsNew from '@mui/icons-material/PowerSettingsNew';
import Translate from '../../../components/service/Translate';
import { loadCurrentUser } from '../actions';
import { outdateEntity } from '../../../actions';
import { RootState } from '../../../reducers';
import { withRouter, WithRouterProps } from '../../../withRouter';

type UserMenuState = {
  anchorEl: HTMLElement | null;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & WithRouterProps;

class UserMenu extends Component<Props, UserMenuState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null,
    };
  }

  componentDidMount() {
    const { loadCurrentUser, outdateEntity } = this.props;

    loadCurrentUser(['firstname', 'name']);
    outdateEntity('staff'); // otherwise staff list won't load
  }

  logout = () => {
    this.setState({ anchorEl: null });
    window.location.href = Routing.generate('authentication_logout', null, true);
  };

  onTouchProfile = () => {
    const { navigate } = this.props;

    this.setState({ anchorEl: null });
    navigate(Routing.generate('staff_profile'));
  };

  handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { currentUser } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    if (!currentUser) {
      return null;
    }

    return (
      <Box
        sx={{
          top: 8,
          color: 'textColor',
          fill: 'textColor',
          paddingRight: 3,
        }}
      >
        <Button
          color="inherit"
          aria-label="More"
          aria-haspopup="true"
          onClick={this.handleMenuOpen}
          startIcon={<ActionAccountCircle />}
        >
          {currentUser.primaryText}
        </Button>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={this.handleMenuClose}
          elevation={0}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <MenuItem key="profile" value="profile" onClick={this.onTouchProfile}>
            <ListItemIcon>
              <ActionSettings color="inherit" />
            </ListItemIcon>
            <ListItemText primary={<Translate>Profile</Translate>} />
          </MenuItem>
          <MenuItem key="logout" value="logout" onClick={this.logout}>
            <ListItemIcon>
              <ActionPowerSettingsNew color="inherit" />
            </ListItemIcon>
            <ListItemText primary={<Translate>Logout</Translate>} />
          </MenuItem>
        </Menu>
      </Box>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state: RootState) {
  const {
    entities: { currentuser, staff },
  } = state;

  if (!currentuser || !staff) {
    return {};
  }

  const current = staff[currentuser.current.staff];

  return {
    currentUser: current,
  };
}

const connector = connect(mapStateToProps, { outdateEntity, loadCurrentUser });

export default withRouter(connector(UserMenu));

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import { connect } from 'react-redux';
import { withRouter } from '../../withRouter';
import CampaignConfigParam from './CampaignConfigParam';
import { compose } from 'redux';
import { resetDataGridOptions } from './CampaignConfigParamSlice';

class CampaignConfigParamIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
    mode: PropTypes.string,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN'],
    resultset: [],
    mode: 'show',
  };

  componentWillUnmount() {
    const { resetDataGridOptions } = this.props;

    resetDataGridOptions();
  }

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        <CampaignConfigParam mode={this.props.mode} />
      </ACL>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const params = ownProps?.match?.params;

  return {
    mode: params ? params.mode : 'show',
  };
}

const enhance = compose(withRouter, connect(mapStateToProps, { resetDataGridOptions }));

export default enhance(CampaignConfigParamIndex);

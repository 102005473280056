import { createSelector } from '@reduxjs/toolkit';
import { selectAllCampaignConfigParams } from './CampaignConfigParamSlice';
import { RootState } from '../../reducers';

const getCampaignConfigParamResultset = (state: RootState) => state.campaignConfigParam.resultset;
const selectCampaigns = (state: RootState) => state.campaign.entities;

export const makeCampaignConfigParamsByResultset = () =>
  createSelector(
    [selectAllCampaignConfigParams, getCampaignConfigParamResultset],
    (campaignConfigParams, resultset) => {
      if (resultset) {
        return resultset
          .map((id) => campaignConfigParams.find((param) => param.id === id))
          .filter((param) => param !== undefined);
      }
      return [];
    },
  );

const selectCampaignConfigParamsByResultset = makeCampaignConfigParamsByResultset();
export const makeCampaignConfigParamsWithCampaignsByResultset = () =>
  createSelector([selectCampaignConfigParamsByResultset, selectCampaigns], (campaignConfigParams, campaigns) => {
    if (campaignConfigParams) {
      return campaignConfigParams.map((campaignConfigParam) => ({
        ...campaignConfigParam,
        campaign: campaigns[campaignConfigParam!.campaign],
      }));
    }
    return [];
  });

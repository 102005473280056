import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';

export const SMSQUEUE_REQUEST = 'SMSQUEUE_REQUEST';
export const SMSQUEUE_SUCCESS = 'SMSQUEUE_SUCCESS';
export const SMSQUEUE_FAILURE = 'SMSQUEUE_FAILURE';

function fetchSmsQueues(sortColumns, sortOrders) {
  return {
    [CALL_API]: {
      types: [SMSQUEUE_REQUEST, SMSQUEUE_SUCCESS, SMSQUEUE_FAILURE],
      endpoint: Routing.generate('smsqueue_index', { sortColumns: sortColumns, sortOrders: sortOrders }, true),
      schema: Schemas.SMSS,
    },
  };
}

export function loadSmsQueues(sortColumns = [], sortOrders = []) {
  return (dispatch) => {
    return dispatch(fetchSmsQueues(sortColumns, sortOrders));
  };
}

export const CUSTOMERS_REQUEST = 'CUSTOMERS_REQUEST';
export const CUSTOMERS_SUCCESS = 'CUSTOMERS_SUCCESS';
export const CUSTOMERS_FAILURE = 'CUSTOMERS_FAILURE';

function fetchCustomers(jobId, filter, sortColumns, sortOrders, offset = null, limit = null) {
  return {
    [CALL_API]: {
      types: [CUSTOMERS_REQUEST, CUSTOMERS_SUCCESS, CUSTOMERS_FAILURE],
      endpoint: Routing.generate(
        'smsqueue_customer',
        {
          seasonJob: jobId,
          filter: filter,
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
        },
        true,
      ),
      schema: Schemas.CUSTOMERLIST,
    },
  };
}

export const CUSTOMER_INFINITESCROLL_REQUEST = 'CUSTOMER_INFINITESCROLL_REQUEST';
export const CUSTOMER_INFINITESCROLL_SUCCESS = 'CUSTOMER_INFINITESCROLL_SUCCESS';
export const CUSTOMER_INFINITESCROLL_FAILURE = 'CUSTOMER_INFINITESCROLL_FAILURE';

function fetchCustomersPaged(
  jobId,
  filter,
  path,
  sortColumns,
  sortOrders,
  customerListCustomerId = null,
  offset = null,
  limit = null,
) {
  if (customerListCustomerId) {
    return {
      [CALL_API]: {
        types: [CUSTOMER_INFINITESCROLL_REQUEST, CUSTOMER_INFINITESCROLL_SUCCESS, CUSTOMER_INFINITESCROLL_FAILURE],
        endpoint: Routing.generate(
          'smsqueue_detail',
          {
            seasonJob: jobId,
            filter: filter,
            sortColumns: sortColumns,
            sortOrders: sortOrders,
            offset: offset,
            limit: limit,
            customerListCustomerId: customerListCustomerId,
          },
          true,
        ),
        schema: Schemas.CUSTOMERLIST,
        path,
      },
    };
  } else {
    return {
      [CALL_API]: {
        types: [CUSTOMER_INFINITESCROLL_REQUEST, CUSTOMER_INFINITESCROLL_SUCCESS, CUSTOMER_INFINITESCROLL_FAILURE],
        endpoint: Routing.generate(
          'smsqueue_customer',
          {
            seasonJob: jobId,
            filter: filter,
            sortColumns: sortColumns,
            sortOrders: sortOrders,
            offset: offset,
            limit: limit,
          },
          true,
        ),
        schema: Schemas.CUSTOMERLIST,
        path,
      },
    };
  }
}

export function loadSmsQueueCustomers(jobId, filter = [], sortColumns = [], sortOrders = []) {
  return (dispatch) => {
    let offset = null;
    let limit = null;

    return dispatch(fetchCustomers(jobId, filter, sortColumns, sortOrders, offset, limit));
  };
}

export function loadSmsQueueCustomersPaged(
  jobId,
  filter = [],
  sortColumns = [],
  sortOrders = [],
  customerListCustomerId = null,
) {
  return (dispatch, getState) => {
    const smsJob = getState().entities.smsJob ? getState().entities.smsJob[jobId] : false;
    const path = 'customerList[' + smsJob.customerList + '].customerListCustomers';

    let offset = 0;
    let limit = 50;

    return dispatch(
      fetchCustomersPaged(jobId, filter, path, sortColumns, sortOrders, customerListCustomerId, offset, limit),
    );
  };
}

export function loadNext(jobId, filter = [], sortColumns = [], sortOrders = [], customerListCustomerId = null) {
  return (dispatch, getState) => {
    const smsJob = getState().entities.smsJob ? getState().entities.smsJob[jobId] : false;
    const pagination =
      smsJob && getState().pagination.customerList ? getState().pagination.customerList[smsJob.customerList] : false;

    if (!smsJob || !pagination) {
      return null;
    }

    const path = 'customerList[' + smsJob.customerList + '].customerListCustomers';

    return dispatch(
      fetchCustomersPaged(
        jobId,
        filter,
        path,
        sortColumns,
        sortOrders,
        customerListCustomerId,
        pagination.next,
        pagination.limit,
      ),
    );
  };
}

export const SMSBODY_REQUEST = 'SMSBODY_REQUEST';
export const SMSBODY_SUCCESS = 'SMSBODY_SUCCESS';
export const SMSBODY_FAILURE = 'SMSBODY_FAILURE';

function fetchSms(smsQueue, sortColumns, sortOrders) {
  return {
    [CALL_API]: {
      types: [SMSBODY_REQUEST, SMSBODY_SUCCESS, SMSBODY_FAILURE],
      endpoint: Routing.generate(
        'smsqueue_show',
        {
          smsQueue: smsQueue,
          sortColumns: sortColumns,
          sortOrders: sortOrders,
        },
        true,
      ),
      schema: Schemas.SMSQUEUE,
    },
  };
}

export function loadSmsQueueSms(jobId, sortColumns = [], sortOrders = []) {
  return (dispatch) => {
    return dispatch(fetchSms(jobId, sortColumns, sortOrders));
  };
}

export const SEND_SMS_REQUEST = 'SEND_SMS_REQUEST';
export const SEND_SMS_SUCCESS = 'SEND_SMS_SUCCESS';
export const SEND_SMS_FAILURE = 'SEND_SMS_FAILURE';

function sendSms(smsInfo) {
  return {
    [CALL_API]: {
      types: [SEND_SMS_REQUEST, SEND_SMS_SUCCESS, SEND_SMS_FAILURE],
      endpoint: Routing.generate('sms_send_preview', {}, true),
      method: 'POST',
      data: smsInfo,
      schema: Schemas.FORM,
    },
  };
}

export function dispatchSms(smsInfo) {
  return (dispatch) => {
    return dispatch(sendSms(smsInfo));
  };
}

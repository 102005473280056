import type { ReactNode } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link, Stack } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { eeloyBlue } from '../../theme/Colors';

import gradient from '../../theme/images/login/gradient-bg.svg';
import EeloyIcon from '../../theme/icons/EeloyIcon';
import Translate from '../../components/service/Translate';

type SecurityLayoutProps = {
  children: ReactNode;
  img: string;
};

function SecurityLayout({ children, img }: SecurityLayoutProps) {
  return (
    <Box
      sx={{
        backgroundColor: 'background.default',
        height: '100vh',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: {
          xs: 'column-reverse',
          md: 'row',
        },
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          backgroundColor: alpha(eeloyBlue.main, 0.07),
          backgroundImage: `url(${gradient})`,
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          display: 'flex',
          flex: {
            xs: '0 0 auto',
            md: '1 1 auto',
          },
          justifyContent: 'center',
          p: {
            xs: 4,
            md: 8,
          },
        }}
      >
        <Box maxWidth="md">
          <Stack direction="row" alignItems="center" sx={{ mb: 2 }}>
            <EeloyIcon
              style={{
                width: 40,
                height: 'auto',
              }}
            />
            <Typography variant="h4">
              <Translate>{' Welcome'}</Translate>
            </Typography>
          </Stack>
          <Typography color="text.primary" variant="body1">
            <Translate>The autopilot for car dealership marketing. Log in and get started.</Translate>
          </Typography>
          <Typography color="text.primary" sx={{ mb: 6 }} variant="body1">
            <Translate>{'No access yet? More info '}</Translate>
            <Link href="https://www.eeloy.com/seeds/" underline="always" color="text.primary" variant="body1">
              <Translate>here.</Translate>
            </Link>
          </Typography>
          <Box display={{ xs: 'none', md: 'flex' }} justifyContent="center">
            <Box
              component="img"
              src={img}
              sx={{
                height: 'auto',
                width: 400,
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          flex: {
            xs: '1 1 auto',
            md: '0 0 auto',
          },
          flexDirection: 'column',
          justifyContent: {
            md: 'center',
          },
          maxWidth: '100%',
          p: {
            xs: 4,
            md: 8,
          },
          width: {
            md: '25%',
          },
        }}
      >
        {children}
      </Box>
    </Box>
  );
}

export default SecurityLayout;

import React from 'react';
import { Paper, Typography, Avatar, Box } from '@mui/material';

type KeyIndicatorProps = {
  icon: React.ReactNode;
  title: string;
  value: string | number;
  subheader1: string | number;
  subheader2: string | number;
};

export default function KeyIndicator({ icon, title, value, subheader1, subheader2 }: KeyIndicatorProps) {
  return (
    <Paper sx={{ width: '100%', display: 'inline-flex', alignItems: 'center', p: 2 }}>
      <Avatar sx={{ mr: 2, ml: 2 }}>{icon}</Avatar>
      <Box>
        <Typography variant="body1" color="textPrimary" gutterBottom>
          {title}
        </Typography>
        <Typography variant="h5">{value}</Typography>
        <Typography variant="body2">{subheader1}</Typography>
        <Typography variant="body2">{subheader2}</Typography>
      </Box>
    </Paper>
  );
}

import React, { JSX, memo, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridInitialState,
  GridPaginationModel,
  GridRenderCellParams,
  GridRowId,
  GridRowParams,
  GridRowSelectionModel,
  GridRowsProp,
  GridSortModel,
  GridValidRowModel,
} from '@mui/x-data-grid';
import { Box, Checkbox } from '@mui/material';
import EeloyDataGridToolbar from './EeloyDataGridToolbar';
import { CustomJoinModel, GridParams } from './DataGridTypes';
import { getFilterType } from './EeloyDataGridUtils';
import NoRowsOverlay from './NoRowsOverlay';

const TABLE_ROW_LIMIT = 100;

type EeloyDataGridProps = {
  title: string;
  columnsDefintion: GridColDef[];
  rows: GridRowsProp;
  rowCount: number; // rowCount is required because we use paginationMode "server"
  onEdit?: (selected: GridRowId) => void;
  onAdd?: () => void;
  onDelete?: (selected: GridRowId) => void;
  onDuplicate?: (selected: GridRowId | GridRowSelectionModel) => void;
  onReset?: (selected: GridRowId) => void;
  onExport?: () => void;
  disableAdd?: boolean;
  disableEdit?: boolean;
  disableDuplicate?: boolean;
  disableReset?: boolean;
  disableExport?: boolean;
  disableDelete?: boolean;
  useCheckboxSelectionForDuplicate?: boolean;
  deleteTooltipText?: React.ReactNode;
  loading?: boolean;
  initialState?: GridInitialState;
  checkboxSelection?: boolean;
  disableRowSelectionOnClick?: boolean;
  onRowSelection?: (selected: GridRowId) => void;
  onCheckboxSelection?: (rowSelectionModel: GridRowSelectionModel) => void;
  processRowUpdate?: (
    newRow: GridValidRowModel,
    oldRow: GridValidRowModel,
  ) => GridValidRowModel | Promise<GridValidRowModel>;
  loadNext?: (gridParams: GridParams) => Promise<unknown>;
  customJoinModel?: CustomJoinModel;
};
function EeloyDataGrid(props: EeloyDataGridProps): JSX.Element {
  const {
    columnsDefintion,
    rows,
    title,
    onEdit,
    onAdd,
    onDelete,
    onDuplicate,
    onReset,
    onExport,
    disableDelete,
    disableDuplicate,
    disableAdd,
    disableEdit,
    disableExport,
    disableReset,
    useCheckboxSelectionForDuplicate,
    deleteTooltipText,
    initialState,
    rowCount,
    loadNext,
    checkboxSelection,
    onCheckboxSelection,
    onRowSelection,
    processRowUpdate,
    disableRowSelectionOnClick,
    loading,
    customJoinModel,
  } = props;

  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
    page: 0,
    pageSize: TABLE_ROW_LIMIT,
  });
  const [sortModel, setSortModel] = useState<GridSortModel | undefined>(initialState?.sorting?.sortModel);
  const [isLoading, setIsLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [filterModel, setFilterModel] = useState<GridFilterModel>();
  const [joinModel, setJoinModel] = useState<Record<string, string>>();
  const [rowSelectionModel, setRowSelectionModel] = React.useState<GridRowSelectionModel>([]);
  const [checkboxSelectionModel, setCheckboxSelectionModel] = useState<GridRowSelectionModel>();
  const [isCheckboxSelectionHeaderChecked, setIsCheckboxSelectionHeaderChecked] = useState(false);
  const [selected, setSelected] = useState<GridRowId>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await loadNext?.({ sortModel, paginationModel, filterModel, joinModel });

      setIsLoading(false);
    };

    if (initialized) {
      fetchData();
    }

    setInitialized(true);
  }, [paginationModel, sortModel, filterModel]);

  const onFilterChange = useCallback((filterModel: GridFilterModel) => {
    const filter = getFilterType(filterModel);
    if (filter?.length === 0) {
      setFilterModel(undefined);
      return;
    }
    const newFilter = filter.filter((element) => element.value !== undefined);
    if (newFilter.length > 0) {
      setFilterModel(filterModel);
    }
  }, []);

  const onRowSelectionChange = useCallback((newRowSelectionModel: GridRowSelectionModel) => {
    if (newRowSelectionModel.length > 1) {
      // console.warn('More than one row is selected. Using only first index.');
    }
    setRowSelectionModel(newRowSelectionModel);
    setSelected(newRowSelectionModel[0]);
    onRowSelection?.(newRowSelectionModel[0]);
  }, []);

  const onSortModelChange = useCallback((newSortModel: GridSortModel) => {
    if (!customJoinModel) {
      setSortModel(newSortModel);
    } else {
      let out: Record<string, string> = {};
      newSortModel.forEach((element) => {
        if (element.field.includes('.')) {
          const join = element.field.split('.')[0];
          if (join) {
            out = { ...out, ...customJoinModel[join] };
          }
        }
      });
      setJoinModel(out);
      setSortModel(newSortModel);
    }
  }, []);

  const onRowDoubleClick = useCallback(
    (params: GridRowParams) => {
      if (!processRowUpdate && onEdit) {
        onEdit(params.row.id);
      }
    },
    [onEdit],
  );

  const handleCheckboxChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, params: GridRenderCellParams) => {
      let out;
      if (event.target.checked && !checkboxSelectionModel?.includes(params.id)) {
        out = checkboxSelectionModel ? [...checkboxSelectionModel, params.id] : [params.id];
        setCheckboxSelectionModel(out);
        if (out.length === paginationModel.pageSize) {
          setIsCheckboxSelectionHeaderChecked(true);
        }
      } else {
        const index = checkboxSelectionModel?.indexOf(params.id) ?? -1;
        if (index !== -1) {
          out = checkboxSelectionModel?.toSpliced(index, 1) ?? [];
          setCheckboxSelectionModel(out);
          setIsCheckboxSelectionHeaderChecked(false);
        }
      }
      onCheckboxSelection?.(out as GridRowSelectionModel);
    },
    [checkboxSelectionModel],
  );

  const handleHeaderCheckboxChange = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const out = rows.map((row) => row.id);
      setIsCheckboxSelectionHeaderChecked(true);
      setCheckboxSelectionModel(out);
      onCheckboxSelection?.(out as GridRowSelectionModel);
    } else {
      setIsCheckboxSelectionHeaderChecked(false);
      setCheckboxSelectionModel([]);
      onCheckboxSelection?.([]);
    }
  }, []);

  const columnsWithCheckbox = useMemo(
    () => [
      {
        field: 'customCheckBox',
        sortable: false,
        maxWidth: 64,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        renderHeader: (_params) => (
          <Checkbox
            checked={isCheckboxSelectionHeaderChecked}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => handleHeaderCheckboxChange(e)}
          />
        ),
        renderCell: (params) => (
          <Checkbox
            checked={!!checkboxSelectionModel?.includes(params.id)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(e) => handleCheckboxChange(e, params)}
          />
        ),
      },
      ...columnsDefintion,
    ],
    [columnsDefintion, checkboxSelectionModel],
  );

  const pageSizeOptions = useMemo(() => [100], []);

  return (
    <>
      <EeloyDataGridToolbar
        title={title}
        onEdit={onEdit}
        onAdd={onAdd}
        onDelete={onDelete}
        onDuplicate={onDuplicate}
        onReset={onReset}
        onExport={onExport}
        disableAdd={disableAdd}
        disableDelete={disableDelete}
        disableDuplicate={disableDuplicate}
        disableEdit={disableEdit}
        disableExport={disableExport}
        disableReset={disableReset}
        deleteTooltipText={deleteTooltipText}
        selected={selected}
        checkboxSelectionModel={checkboxSelectionModel}
        useCheckboxSelectionForDuplicate={useCheckboxSelectionForDuplicate}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          height: rowCount === 0 ? '400px' : '100%',
        }}
      >
        <DataGrid
          sx={{
            border: 'none',
            '& .MuiDataGrid-window': {
              overflowY: 'hidden !important',
            },
            '& .MuiDataGrid-cell:focus, & .MuiDataGrid-columnHeader:focus, & .MuiDataGrid-cell:focus-within, .MuiDataGrid-columnHeader:focus-within':
              {
                outline: 'none !important',
              },
            '& .MuiDataGrid-columnsContainer': {
              borderTop: '1px solid rgba(224, 224, 224, 1)',
            },
          }}
          columns={checkboxSelection ? columnsWithCheckbox : columnsDefintion}
          rows={rows}
          rowCount={rowCount}
          loading={isLoading || loading}
          initialState={initialState}
          paginationMode="server"
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          sortingMode="server"
          sortModel={sortModel}
          onSortModelChange={onSortModelChange}
          filterMode="server"
          onFilterModelChange={onFilterChange}
          pageSizeOptions={pageSizeOptions}
          keepNonExistentRowsSelected
          rowSelectionModel={rowSelectionModel}
          onRowSelectionModelChange={onRowSelectionChange}
          disableRowSelectionOnClick={disableRowSelectionOnClick}
          processRowUpdate={processRowUpdate}
          onRowDoubleClick={onRowDoubleClick}
          slots={{
            noRowsOverlay: NoRowsOverlay,
          }}
        />
      </Box>
    </>
  );
}

export default memo(EeloyDataGrid);

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const SEASON_CAUSALITY_CREATE_REQUEST = 'SEASON_CAUSALITY_CREATE_REQUEST';
export const SEASON_CAUSALITY_CREATE_SUCCESS = 'SEASON_CAUSALITY_CREATE_SUCCESS';
export const SEASON_CAUSALITY_CREATE_FAILURE = 'SEASON_CAUSALITY_CREATE_FAILURE';

export function writeCausality(form, causalityId) {
  return (dispatch) => {
    return dispatch({
      [CALL_API]: {
        types: [SEASON_CAUSALITY_CREATE_REQUEST, SEASON_CAUSALITY_CREATE_SUCCESS, SEASON_CAUSALITY_CREATE_FAILURE],
        method: 'POST',
        data: form,
        endpoint: Routing.generate('seasoncausality_update', { id: causalityId }, true),
        schema: Schemas.SEASONCAUSALITY,
      },
    });
  };
}

export function updateCausality(form, causalityId = null) {
  return (dispatch) => {
    return dispatch(writeCausality(form, causalityId));
  };
}

export const SEASON_CAUSALITY_REQUEST = 'SEASON_CAUSALITY_REQUEST';
export const SEASON_CAUSALITY_SUCCESS = 'SEASON_CAUSALITY_SUCCESS';
export const SEASON_CAUSALITY_FAILURE = 'SEASON_CAUSALITY_FAILURE';

function fetchCausality() {
  return {
    [CALL_API]: {
      types: [SEASON_CAUSALITY_REQUEST, SEASON_CAUSALITY_SUCCESS, SEASON_CAUSALITY_FAILURE],
      endpoint: Routing.generate('seasoncausality_index', {}, true),
      schema: Schemas.SEASONCAUSALITIES,
    },
  };
}

export function loadCausalities(requiredFields = []) {
  return (dispatch, getState) => {
    const seasoncausality = getState().entities.seasoncausality;
    const outdated = getState().outdated.seasoncausality;

    if (!outdated && seasoncausality && requiredFields.every((key) => sample(seasoncausality).hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchCausality());
  };
}

export const CAUSALITY_FORM_REQUEST = 'CAUSALITY_FORM_REQUEST';
export const CAUSALITY_FORM_SUCCESS = 'CAUSALITY_FORM_SUCCESS';
export const CAUSALITY_FORM_FAILURE = 'CAUSALITY_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [CAUSALITY_FORM_REQUEST, CAUSALITY_FORM_SUCCESS, CAUSALITY_FORM_FAILURE],
      endpoint: Routing.generate('seasoncausality_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}
export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

export const CAUSALITY_DELETE_REQUEST = 'CAUSALITY_DELETE_REQUEST';
export const CAUSALITY_DELETE_SUCCESS = 'CAUSALITY_DELETE_SUCCESS';
export const CAUSALITY_DELETE_FAILURE = 'CAUSALITY_DELETE_FAILURE';

function deleteCausality(causalityId, deleteToken) {
  return {
    [CALL_API]: {
      types: [CAUSALITY_DELETE_REQUEST, CAUSALITY_DELETE_SUCCESS, CAUSALITY_DELETE_FAILURE],
      endpoint: Routing.generate('seasoncausality_delete', { id: causalityId, deleteToken: deleteToken }, true),
      method: 'DELETE',
    },
  };
}

export function removeCausality(causalityId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteCausality(causalityId, deleteToken));
  };
}

import React, { Component } from 'react';
import { withRouter } from '../../withRouter';
import { connect } from 'react-redux';

import PropTypes from 'prop-types';
import { loadModule, loadModules, loadNext, removeModule, updateModule } from './actions';
import { intersection, keys, pick, toNumber } from 'lodash';
import DeleteDialog from '../../components/DeleteDialog';
import Translate from '../../components/service/Translate';
import ErrorBoundary from '../../components/ErrorBoundary';
import EmailModuleList from './components/List';
import EmailModuleEdit from './components/Edit';
import { Paper } from '@mui/material';
import { resetEntity, outdateEntity } from '../../actions';
import { compose } from 'redux';

const INITIAL_GRID_STATE = {
  sorting: {
    sortModel: [{ field: 'name', sort: 'asc' }],
  },
};

class EmailModule extends Component {
  static propTypes = {
    outdateEntity: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    resultset: PropTypes.array,
    mode: PropTypes.string,
  };

  static defaultProps = {
    resultset: [],
    mode: 'show',
  };

  state = {
    current: null,
    currentIndex: 0,
    valid: false,
    sortColumns: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.field),
    sortOrders: INITIAL_GRID_STATE.sorting.sortModel.map((val) => val.sort),
    dataLoading: true,
  };

  componentDidMount = () => {
    const { sortColumns, sortOrders } = this.state;
    this.setState({ dataLoading: true });
    Promise.all([this.props.loadModules(sortColumns, sortOrders)]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
    this.setState({ valid: false });
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.defaultIndex && prevProps.defaultIndex !== this.props.defaultIndex) {
      this.setState({
        currentIndex: this.props.defaultIndex,
        current: this.props.modules[this.props.defaultIndex],
      });
    }

    if (prevProps.mode !== this.props.mode) {
      this.setState({ valid: false });
    }
  }

  handleFormMount = (form) => {
    this.form = form;
  };

  submit = () => {
    this.form.submit();
  };

  loadNext = (columns, sort, join = [], offset = null, limit = null, filter = []) => {
    return Promise.all([this.props.loadNext(columns, sort, offset, limit, join, filter)]);
  };

  handleSubmit = (form) => {
    const promises = [];
    const { updateModule, outdateEntity, loadModules, mode, moduleId } = this.props;
    const { sortColumns, sortOrders } = this.state;

    this.setState({ valid: false });
    promises.push(updateModule(form, mode === 'edit' ? moduleId : null));

    if (mode !== 'edit') {
      outdateEntity('emailModule');
    }

    return Promise.all(promises).then(
      (values) => {
        if (!(values && values[0] && values[0].type && values[0].type.match(/_FAILURE/))) {
          outdateEntity('emailModule');
          outdateEntity('moduleTag');
          loadModules(sortColumns, sortOrders);
          this.onTouchCancel();
        }
      },
      () => {
        this.setState({ valid: false });
      },
    );
  };

  handleValidityChange = (valid) => {
    this.setState({ valid: valid });
  };

  handleSortModelChange = (newSortModel) => {
    this.setState({
      sortColumns: newSortModel?.map((value) => value.field),
      sortOrders: newSortModel?.map((value) => value.sort),
    });
  };

  handleDelete = () => {
    const { modules, resultset, removeModule, resetEntity, loadModules } = this.props;
    const { sortColumns, sortOrders, current } = this.state;

    this.setState({ deleting: true });

    const currentIndex = resultset.indexOf(this.state.currentIndex);
    const nextIndex =
      currentIndex >= 0 && currentIndex < resultset.length - 1
        ? resultset[currentIndex + 1]
        : currentIndex > 0
          ? resultset[currentIndex - 1]
          : 0;
    // eslint-disable-next-line no-unused-vars
    const next = modules[nextIndex];

    Promise.all([removeModule(current, modules[current].deleteToken)]).then(
      () => {
        this.setState({ deleting: false, currentIndex: nextIndex });
        resetEntity('emailModule', current);
        loadModules(sortColumns, sortOrders);
        this.props.navigate(Routing.generate('emailmodule_index'));
      },
      () => {
        loadModules(sortColumns, sortOrders);
      },
    );
  };

  onTouchEdit = (current) => {
    this.setState({ valid: false });
    this.props.navigate('/email/module/edit/' + current);
  };

  onTouchDuplicate = (current) => {
    this.setState({ valid: false });
    this.props.navigate('/email/module/duplicate/' + current);
  };

  onTouchAdd = () => {
    this.setState({ valid: false });
    this.props.navigate('/email/module/new');
  };

  onTouchDelete = (current) => {
    this.setState({ deleting: true, current: current });
  };

  onTouchCancel = () => {
    this.setState({ valid: false, deleting: false });
    this.props.navigate('/email/module/');
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { modules } = this.props;

    if (deleting) {
      return <DeleteDialog current={modules[current]} onCancel={this.onTouchCancel} onSubmit={this.handleDelete} />;
    }

    return null;
  };

  renderContent() {
    const { modules, pagination, resultset, mode, moduleId } = this.props;
    const { sortOrders, sortColumns, dataLoading } = this.state;

    let child = null;

    if (dataLoading) {
      return (
        <Paper>
          <Translate>Data loading...</Translate>
        </Paper>
      );
    } else {
      switch (mode) {
        case 'new':
        case 'duplicate':
        case 'edit':
          child = (
            <ErrorBoundary>
              <EmailModuleEdit
                onMount={this.handleFormMount}
                onSubmit={this.handleSubmit}
                onValidityChange={this.handleValidityChange}
                onCancel={this.onTouchCancel}
                submit={this.submit}
              />
            </ErrorBoundary>
          );
          break;
        default:
          return (
            <ErrorBoundary>
              <EmailModuleList
                resultset={resultset}
                modules={modules}
                current={moduleId}
                pagination={pagination}
                onAdd={this.onTouchAdd}
                onEdit={this.onTouchEdit}
                onDelete={this.onTouchDelete}
                onCancel={this.onTouchCancel}
                onSortModelChange={this.handleSortModelChange}
                onLoadNext={this.loadNext}
                onDuplicate={this.onTouchDuplicate}
                initialGridState={INITIAL_GRID_STATE}
              />
            </ErrorBoundary>
          );
      }
    }

    return <Paper>{child}</Paper>;
  }

  render() {
    return (
      <div>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </div>
    );
  }
}

// eslint-disable-next-line no-unused-vars
function mapStateToProps(state, ownProps) {
  const mode = ownProps?.match?.params?.mode;
  const moduleId = ownProps?.match?.params?.moduleId;

  const {
    entities: { emailModule },
    resultsets: { emailModule: resultset },
    forms: { emailModule: forms },
    loading: loading,
    success: successActions,
    pagination: { emailModule: pagination },
  } = state;

  if (!emailModule) {
    return {
      modules: {},
      loading: loading,
      success: successActions,
    };
  }

  return {
    mode: mode,
    moduleId: moduleId ? moduleId : null,
    modules: pick(emailModule, resultset),
    defaultIndex: emailModule && resultset ? resultset[0] : null,
    deletable: !!(forms && forms.delete),
    resultset: intersection(resultset, keys(emailModule).map(toNumber)),
    loading: loading,
    success: successActions,
    pagination: pagination,
  };
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps, {
    outdateEntity,
    resetEntity,
    loadModules,
    loadNext,
    updateModule,
    loadModule,
    removeModule,
  }),
);

export default enhance(EmailModule);

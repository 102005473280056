import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Card, CardHeader, CardContent, IconButton, Collapse } from '@mui/material';
import { isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import Translate from './service/Translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Table, TableData } from './StyledElements/StyledElements';

class CustomerListCustomerCar extends Component {
  static propTypes = {
    carId: PropTypes.number.isRequired,
    car: PropTypes.object,
    initiallyExpanded: PropTypes.bool,
  };

  static defaultProps = {
    initiallyExpanded: false,
  };

  state = {
    expanded: false,
  };

  componentDidMount() {
    if (this.props.initiallyExpanded) {
      this.setState({ expanded: true });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(this.props.carId, nextProps.carId)
      || !isEqual(this.props.car, nextProps.car)
      || !isEqual(nextState.expanded, this.state.expanded)
    );
  };

  // eslint-disable-next-line no-unused-vars
  renderRow = (columns, index, rows) => {
    return (
      <tr key={`car-fieldset-${index}`}>
        {columns.map((column, index) => {
          return column.styleType === 'label' ? (
            <TableData width="16%" color="secondaryTextColor" fontSize="14px" key={`car-field-${index}`}>
              {column.data}
            </TableData>
          ) : (
            <TableData width="17%" fontSize="14px" key={`car-field-${index}`}>
              {column.data}
            </TableData>
          );
        })}
      </tr>
    );
  };

  handleExpandClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  renderClassifiedFields = () => {
    const { car } = this.props;
    let rows = [];

    if (!car) {
      return null;
    }

    if (car.classification !== 'passengercar') {
      rows.push([
        { data: 'HU', styleType: 'label' },
        { data: car.hu, styleType: 'data' },
        { data: 'TP', styleType: 'label' },
        { data: car.tp, styleType: 'data' },
        { data: 'SP', styleType: 'label' },
        { data: car.sp, styleType: 'data' },
      ]);
    } else {
      rows.push([
        { data: 'HU', styleType: 'label' },
        { data: car.hu, styleType: 'data' },
      ]);
    }

    return <tbody>{rows.map(this.renderRow)}</tbody>;
  };

  render() {
    const { car, location } = this.props;

    if (!car) {
      return null;
    }

    return (
      <Card
        sx={{
          mb: 2,
          width: '100%',
        }}
      >
        <CardHeader
          title="Fahrzeug"
          subheader={
            <Translate
              wildcards={{
                '%licenseNumber%': car.licenseNumber,
                '%registrationDate%': car.registrationDate,
                '%lastMaintenance%': car.lastMaintenance,
              }}
            >
              License number: %licenseNumber%, Registration date: %registrationDate%, Last maintenance:
              %lastMaintenance%
            </Translate>
          }
          action={
            <IconButton
              onClick={this.handleExpandClick}
              aria-expanded={this.state.expanded}
              aria-label="Show more"
              sx={this.state.expanded ? { transform: 'rotate(180deg)' } : {}}
              size="large"
            >
              <ExpandMoreIcon color="inherit" />
            </IconButton>
          }
        />

        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>
            <Table tableWidth="100%" tableLayout="fixed">
              <tbody>
                <tr>
                  <TableData width="20%" fontSize="14px" textColor="text.secondary">
                    <Translate>License number</Translate>
                  </TableData>
                  <TableData width="13%" fontSize="14px">
                    <Translate>{car.licenseNumber}</Translate>
                  </TableData>
                  <TableData width="16%" fontSize="14px" textColor="text.secondary">
                    <Translate>Location</Translate>
                  </TableData>
                  <TableData width="17%" fontSize="14px">
                    {location ? location.primaryText : ''}
                  </TableData>
                  <TableData width="16%" fontSize="14px" textColor="text.secondary">
                    FIN
                  </TableData>
                  <TableData width="17%" fontSize="14px">
                    {car.ident}
                  </TableData>
                </tr>
                <tr>
                  <TableData width="20%" fontSize="14px" textColor="text.secondary">
                    <Translate>Classification</Translate>
                  </TableData>
                  <TableData width="13%" fontSize="14px">
                    <Translate>{car.classification}</Translate>
                  </TableData>
                  <TableData width="16%" fontSize="14px" textColor="text.secondary">
                    Typ
                  </TableData>
                  <TableData width="17%" fontSize="14px">
                    {car.type}
                  </TableData>
                  <TableData width="16%" fontSize="14px" textColor="text.secondary">
                    Modell
                  </TableData>
                  <TableData width="17%" fontSize="14px">
                    {car.model}
                  </TableData>
                </tr>
                <tr>
                  <TableData width="20%" fontSize="14px" textColor="text.secondary">
                    <Translate>Registration date</Translate>
                  </TableData>
                  <TableData width="13%" fontSize="14px">
                    {car.registrationDate}
                  </TableData>
                  <TableData width="16%" fontSize="14px" textColor="text.secondary">
                    Garantieende
                  </TableData>
                  <TableData width="17%" fontSize="14px">
                    {car.warrantyEnd}
                  </TableData>
                  <TableData width="16%" fontSize="14px" textColor="text.secondary">
                    km
                  </TableData>
                  <TableData width="17%" fontSize="14px">
                    {car.mileage}
                  </TableData>
                </tr>
                <tr>
                  <TableData width="13%" fontSize="14px" textColor="text.secondary">
                    <Translate>Last maintenance</Translate>
                  </TableData>
                  <TableData width="13%" fontSize="14px">
                    {car.lastMaintenance}
                  </TableData>
                  <TableData width="16%" fontSize="14px" textColor="text.secondary">
                    Letzte Wartung
                  </TableData>
                  <TableData width="17%" fontSize="14px">
                    {car.lastService}
                  </TableData>
                  <TableData width="16%" fontSize="14px" textColor="text.secondary">
                    Nächste Wartung
                  </TableData>
                  <TableData width="17%" fontSize="14px">
                    {car.nextService}
                  </TableData>
                </tr>
              </tbody>
              {this.renderClassifiedFields()}
            </Table>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const carId = ownProps.carId;
  const {
    entities: { car: car, location: location },
  } = state;

  return {
    carId: carId,
    car: car[carId],
    location:
      carId && !isEmpty(car) && car[carId] && !isEmpty(location) && location[car[carId].location]
        ? location[car[carId].location]
        : null,
  };
}

export default connect(mapStateToProps, {})(CustomerListCustomerCar);

import React, { Component, Fragment } from 'react';
import {
  Drawer,
  CssBaseline,
  AppBar,
  Toolbar,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  IconButton,
  Box,
  ListItemButton,
  styled,
  Link,
  Typography,
} from '@mui/material';
import BuildIcon from '@mui/icons-material/Build';
import SettingsIcon from '@mui/icons-material/Settings';
import HelpIcon from '@mui/icons-material/Help';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';
import ChatIcon from '@mui/icons-material/Chat';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import SearchIcon from '@mui/icons-material/Search';
import HomeIcon from '@mui/icons-material/Home';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import ExpandMore from '@mui/icons-material/ExpandMore';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { filter } from 'lodash';
import { connect, ConnectedProps } from 'react-redux';
import { Outlet } from 'react-router-dom';
import SmsIcon from '@mui/icons-material/Sms';
import Scene from '../../../components/Scene';
import SearchBar from '../../../components/SearchBar';
import WhiteLabelClaim from './WhiteLabelClaim';
import WhiteLabelIcon from './WhiteLabelIcon';
import AutoSelect from '../../../theme/icons/AutoSelect';
import { Actions, Spacer } from '../../../components/StyledElements/StyledElements';
import { withRouter, WithRouterProps } from '../../../withRouter';
import { RootState } from '../../../reducers';
import { loadMenu, selectAllMenuItems } from '../../Menu/MenuSlice';
import UserMenu from './UserMenu';
import { MenuItem } from '../../Menu/MenuItemType';
import ErrorPage from '../../Error/ErrorPage';

const StyledToolbar = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: '0 8px',
  ...theme.mixins.toolbar,
}));

type StyledMainProps = {
  drawerOpen: boolean;
};

const StyledMain = styled('main', {
  shouldForwardProp: (prop) => prop !== 'drawerOpen',
})<StyledMainProps>(({ drawerOpen }) => ({
  flexGrow: 1,
  padding: '24px',
  maxWidth: drawerOpen ? `calc(100% - 256px)` : `calc(100% - 50px)`,
}));

type AppNavDrawerState = {
  openMenuItems: Record<string, boolean>;
  selectedUrl?: string;
  menuState: Record<string, boolean>;
};

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & WithRouterProps;

class AppNavDrawer extends Component<Props, AppNavDrawerState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      openMenuItems: {},
      menuState: {},
    };
  }

  componentDidMount() {
    const { loadMenu } = this.props;

    loadMenu();
  }

  handleChangeList = (route: string) => {
    const { navigate } = this.props;

    if (route) {
      navigate(route);
    }
  };

  handleDrawerOpen = () => {
    const { openMenuItems } = this.state;
    const obj: Record<string, boolean> = { drawerOpen: true };
    Object.keys(openMenuItems).forEach((key) => {
      if (openMenuItems[key]) {
        obj[key] = true;
      }
    });
    this.setState({ menuState: obj });
  };

  handleDrawerClose = () => {
    const { openMenuItems } = this.state;

    const obj: Record<string, boolean> = { drawerOpen: false };
    Object.keys(openMenuItems).forEach((key) => {
      if (openMenuItems[key]) {
        obj[key] = false;
      }
    });
    this.setState({ menuState: obj });
  };

  changeMenuItem = (key: string, value: boolean, drawerOpen: boolean) => {
    const { openMenuItems: items } = this.state;

    if (!drawerOpen) {
      Object.keys(items).forEach((iterkey) => {
        items[iterkey] = false;
      });
    }
    items[key] = value;
    this.setState({ openMenuItems: items });
  };

  handleListCollapse = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, index: MenuItem) => {
    const { menuState: menuStateState } = this.state;

    const drawerOpen = menuStateState?.drawerOpen;
    const menu = index.text;
    const menuState = menuStateState[`${menu}Open`];

    this.setState({
      menuState: { [`${menu}Open`]: !menuState, drawerOpen: true },
    });
    this.changeMenuItem(`${menu}Open`, !menuState, drawerOpen);

    if (index.children) {
      this.handleChange(e, index.children[0].url);
    } else {
      this.handleChange(e, index.url);
    }
  };

  handleChange = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, value: string) => {
    const { menuitems } = this.props;
    const branch = menuitems.find((item: MenuItem) => item.url === value); // Specify the type for 'item' here
    this.setState({ selectedUrl: value });
    if (branch?.mode === 'v2') {
      event.stopPropagation();
      window.open(branch.href);

      return;
    }
    if (!branch) {
      const leafes = filter(menuitems, 'children');
      const leaf = leafes.find((leaf) => {
        const child = leaf.children.find((child) => child.url === value);
        return child && child.mode === 'v2';
      });

      if (leaf) {
        event.stopPropagation();
        window.open(leaf.href);
      }
    }

    this.handleChangeList(value);
  };

  renderMenuIcon = (key: string) => {
    switch (key) {
      case 'CommunicationChatBubble':
        return <ChatIcon sx={{ color: 'body' }} />;
      case 'ActionBuild':
        return <BuildIcon sx={{ color: 'body' }} />;
      case 'AutoSelect':
        return <AutoSelect sx={{ color: 'body' }} />;
      case 'ActionCardGiftcard':
        return <CardGiftcardIcon sx={{ color: 'body' }} />;
      case 'ActionSearch':
        return <SearchIcon sx={{ color: 'body' }} />;
      case 'ContentMail':
        return <MailIcon sx={{ color: 'body' }} />;
      case 'CommunicationPhone':
        return <PhoneIcon sx={{ color: 'body' }} />;
      case 'ActionSettings':
        return <SettingsIcon sx={{ color: 'body' }} />;
      case 'ActionHome':
        return <HomeIcon sx={{ color: 'body' }} />;
      case 'ActionHelp':
        return <HelpIcon sx={{ color: 'body' }} />;
      case 'AlternateEmail':
        return <AlternateEmailIcon sx={{ color: 'body' }} />;
      case 'Sms':
        return <SmsIcon sx={{ color: 'body' }} />;
      default:
        return null;
    }
  };

  renderMenuBranch = (branch: MenuItem) => {
    const { selectedUrl, menuState } = this.state;

    const branchName = `${branch.text}Open`;

    if (branch.children && branch.children.length) {
      const nestedItems = [];

      for (let i = 0; i < branch.children.length; i += 1) {
        const child = branch.children[i];
        nestedItems.push(
          <ListItem disablePadding key={child.id}>
            <ListItemButton
              onClick={(e) => {
                this.handleChange(e, child.url);
              }}
            >
              <ListItemText
                sx={{
                  color: child.url === selectedUrl ? 'white' : 'neutral.400',
                }}
                primary={child.text}
              />
            </ListItemButton>
          </ListItem>,
        );
      }

      return (
        <Fragment key={branch.id}>
          <ListItem disablePadding key={branch.id}>
            <ListItemButton
              onClick={(e) => {
                this.handleListCollapse(e, branch);
              }}
            >
              <ListItemIcon sx={{ mr: 0, minWidth: '56px', color: 'neutral.400' }}>
                {this.renderMenuIcon(branch.muiIcon)}
              </ListItemIcon>
              <ListItemText primary={branch.text} sx={{ color: 'neutral.400' }} />
              {menuState[branchName] ? (
                <ExpandLess sx={{ color: 'neutral.400' }} />
              ) : (
                <ExpandMore sx={{ color: 'neutral.400' }} />
              )}
            </ListItemButton>
          </ListItem>
          <Collapse in={menuState[branchName]} sx={{ pl: 8 }} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {nestedItems}
            </List>
          </Collapse>
        </Fragment>
      );
    }

    return (
      <ListItem disablePadding key={branch.id}>
        <ListItemButton
          onClick={(e) => {
            this.handleListCollapse(e, branch);
          }}
        >
          <ListItemIcon sx={{ mr: 0, minWidth: '56px', color: 'neutral.400' }}>
            {this.renderMenuIcon(branch.muiIcon)}
          </ListItemIcon>
          <ListItemText primary={branch.text} sx={{ color: 'neutral.400' }} />
        </ListItemButton>
      </ListItem>
    );
  };

  renderMenu = () => {
    const { menuitems } = this.props;

    if (!menuitems) {
      return null;
    }

    return (
      <List component="nav" disablePadding key="menu">
        {menuitems.map(this.renderMenuBranch)}
      </List>
    );
  };

  renderHeart = () => {
    const {
      menuState: { drawerOpen },
    } = this.state;

    if (drawerOpen) {
      return (
        <Box
          key="heart"
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px 82px',
            marginTop: 'auto',
          }}
        >
          <Link href="https://www.eeloy.com/insights/" underline="none" target="_blank" sx={{ display: 'flex' }}>
            <Typography variant="body2" sx={{ m: 0.9, color: 'neutral.400' }}>
              made with
            </Typography>
            <WhiteLabelIcon />
          </Link>
        </Box>
      );
    }

    return null;
  };

  render() {
    const {
      menuState: { drawerOpen },
    } = this.state;
    const { error } = this.props;

    if (error) {
      return <ErrorPage status={error.status} showButton={false} />;
    }

    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={(theme) =>
            drawerOpen
              ? {
                  ml: '256px',
                  width: `calc(100% - 256px)`,
                  transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                }
              : {
                  zIndex: 1250,
                  transition: theme.transitions.create(['width', 'margin'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                }
          }
          color="default"
        >
          <Toolbar disableGutters={!drawerOpen} sx={{ display: 'flex' }}>
            {!drawerOpen && (
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={this.handleDrawerOpen}
                sx={{
                  ml: '12px',
                  mr: '36px',
                }}
                size="large"
              >
                <WhiteLabelIcon />
              </IconButton>
            )}
            <SearchBar docked />
            <Spacer />
            <Actions>
              <UserMenu />
            </Actions>
          </Toolbar>
        </AppBar>
        <Drawer
          variant="permanent"
          sx={(theme) =>
            drawerOpen
              ? {
                  flexShrink: 0,
                  whiteSpace: 'nowrap',
                  width: 256,
                  backgroundColor: theme.palette.neutral[800],
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.enteringScreen,
                  }),
                  '& .MuiDrawer-paper': {
                    width: 256,
                    backgroundColor: theme.palette.neutral[800],
                    transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.enteringScreen,
                    }),
                    color: theme.palette.text.primary,
                  },
                }
              : {
                  flexShrink: 0,
                  whiteSpace: 'nowrap',
                  backgroundColor: theme.palette.neutral[800],
                  transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                  }),
                  overflowX: 'hidden',
                  width: '57px',
                  [theme.breakpoints.up('sm')]: {
                    width: '73px',
                  },
                  '& .MuiDrawer-paper': {
                    backgroundColor: theme.palette.neutral[800],
                    transition: theme.transitions.create('width', {
                      easing: theme.transitions.easing.sharp,
                      duration: theme.transitions.duration.leavingScreen,
                    }),
                    overflowX: 'hidden',
                    width: '57px',
                    [theme.breakpoints.up('sm')]: {
                      width: '73px',
                    },
                    color: theme.palette.text.primary,
                  },
                }
          }
          open={drawerOpen}
        >
          <StyledToolbar>
            <Box sx={{ pr: '70px' }}>
              {' '}
              <WhiteLabelClaim /> <WhiteLabelIcon />{' '}
            </Box>
            <IconButton onClick={this.handleDrawerClose} sx={{ color: 'neutral.400' }} size="large">
              <ChevronLeftIcon />
            </IconButton>
          </StyledToolbar>
          {[this.renderMenu(), this.renderHeart()]}
        </Drawer>
        <StyledMain drawerOpen={drawerOpen}>
          <StyledToolbar />
          <Scene id="eeloyBody">
            <Outlet />
          </Scene>
        </StyledMain>
      </Box>
    );
  }
}

const mapState = (state: RootState) =>
  /*
    const {
        menu: {entities: menuitem}
    } = state;
*/

  ({
    menuitems: selectAllMenuItems(state),
    error: state.menu.error,
  });

const mapDispatch = {
  loadMenu,
};

const connector = connect(mapState, mapDispatch);

export default withRouter(connector(AppNavDrawer));

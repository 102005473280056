import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import DateTime from '../../../components/service/DateTime';
import { Table, TableData } from '../../../components/StyledElements/StyledElements';
import { Box } from '@mui/material';

class Log extends Component {
  static propTypes = {
    letterId: PropTypes.number.isRequired,
  };

  render() {
    const { letter } = this.props;

    if (!letter) {
      return <Translate>No entries found.</Translate>;
    }

    return (
      <Box sx={{ pt: 3 }}>
        <Table width="100%">
          <thead>
            <tr>
              <TableData width="15%" textColor="secondaryTextColor">
                <Translate>Date</Translate>
              </TableData>
              <TableData width="35%">
                <Translate>Activity</Translate>
              </TableData>
            </tr>
          </thead>
          <tbody>
            <tr key="letterlog_created">
              <TableData width="15%" textColor="secondaryTextColor">
                <DateTime format="dd.mm.yyyy HH:MM">{letter.created}</DateTime>
              </TableData>
              <TableData width="35%">
                <Translate>Created</Translate>
              </TableData>
            </tr>
            <tr key="letterlog_updated">
              <TableData width="15%" textColor="secondaryTextColor">
                <DateTime format="dd.mm.yyyy HH:MM">{letter.updated}</DateTime>
              </TableData>
              <TableData width="35%">
                <Translate>Updated</Translate>
              </TableData>
            </tr>
          </tbody>
        </Table>
      </Box>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const letterId = ownProps.letterId;
  const {
    entities: { letter },
  } = state;

  return { letter: letter ? letter[letterId] : null };
}

export default connect(mapStateToProps, {})(Log);

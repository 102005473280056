import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import ReportTable from './ReportTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { fetchReportTables } from '../../StatisticCampaignSlice';

type ReportTablesProps = {
  campaignId: number;
};

export default function ReportTables({ campaignId }: ReportTablesProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { inDepot, notInDepot } = useAppSelector((state) => state.statisticCampaign.reportTables);

  useEffect(() => {
    setLoading(true);
    dispatch(fetchReportTables({ id: campaignId })).then(() => setLoading(false));
  }, []);

  return (
    <>
      {loading && (
        <>
          <Skeleton variant="rounded" width="100%" height="415px" sx={{ mb: 3, mt: 3 }} />
          <Skeleton variant="rounded" width="100%" height="415px" sx={{ mb: 3, mt: 3 }} />
        </>
      )}
      {!loading && inDepot ? <ReportTable title="Einlagerer" table={inDepot} /> : null}
      {!loading && notInDepot ? <ReportTable title="Nichteinlagerer" table={notInDepot} /> : null}
    </>
  );
}

import React, { Component } from 'react';
import { Input, TextField } from '@mui/material';

import { connect } from 'react-redux';
import { initForm, loadLayout } from '../actions';
import Translate from '../../../components/service/Translate';
import PropTypes from 'prop-types';
import { isEqual, isEmpty } from 'lodash';
import Form from '../../../components/Form';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import Grid from '@mui/material/Grid2';
import { HiddenInput } from '../../../components/StyledElements/StyledElements';

const validationSchema = Yup.object().shape({
  name: Yup.string().required(ValidationErrors.required),
  version: Yup.string().required(ValidationErrors.required),
  layout: Yup.string().required(ValidationErrors.required),
  txtLayout: Yup.string().nullable(),
});

class EmailLayoutEdit extends Component {
  static propTypes = {
    loadLayout: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onMount: PropTypes.func.isRequired,
    onValidityChange: PropTypes.func.isRequired,
    initForm: PropTypes.func.isRequired,
    values: PropTypes.object,
    token: PropTypes.string,
  };

  static defaultProps = {
    layoutId: null,
    values: {},
  };

  state = {};

  componentDidMount = () => {
    if (this.props.layoutId) {
      this.props.loadLayout(this.props.layoutId);
    } else {
      this.props.initForm();
    }
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (this.props.layoutId !== prevProps.layoutId) {
      this.props.loadLayout(this.props.layoutId);
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.values, this.props.values)
      || !isEqual(nextProps.layoutId, this.props.layoutId)
      || !isEqual(nextProps.form, this.props.form)
      || !isEqual(nextProps.token, this.props.token)
    );
  };

  renderFieldset = (props) => {
    const { values, handleChange, handleBlur, touched, errors } = props;

    return (
      <>
        <HiddenInput type="hidden" name="updateToken" defaultValue={values.updateToken ? values.updateToken : ''} />
        <Grid container spacing={3}>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Name</Translate>}
              name="name"
              defaultValue={values.name ? values.name : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.name ? errors.name : ''}
              error={touched.name && Boolean(errors.name)}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label={<Translate>Version</Translate>}
              name="version"
              defaultValue={values.version ? values.version : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.version ? errors.version : ''}
              error={touched.version && Boolean(errors.version)}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Running text (HTML)</Translate>}
              name="layout"
              defaultValue={values.layout ? values.layout : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.layout ? errors.layout : ''}
              error={touched.layout && Boolean(errors.layout)}
              multiline
            />
          </Grid>
          <Grid size={12}>
            <TextField
              label={<Translate>Running text (Text)</Translate>}
              name="txtLayout"
              defaultValue={values.txtLayout ? values.txtLayout : ''}
              onChange={handleChange}
              onBlur={handleBlur}
              helperText={touched.txtLayout ? errors.txtLayout : ''}
              error={touched.txtLayout && Boolean(errors.txtLayout)}
              multiline
            />
          </Grid>
        </Grid>
      </>
    );
  };

  render() {
    const { layoutId, layout, onMount, onSubmit, onValidityChange, token, form, onCancel } = this.props;
    const { ...defaultValues } = this.props.values;

    if (layoutId && isEmpty(layout)) {
      // if layoutId is set layout should not be empty
      return null;
    }

    if (!layoutId && isEmpty(form)) {
      // if layoutId is missing form should not be empty
      return null;
    }

    if (!isEmpty(form)) {
      //if form is not empty read token and write token into defaultValues
      defaultValues.updateToken = token;
    }

    let headline = layout.primaryText ? layout.primaryText : <Translate>New layout</Translate>;

    return (
      <Form
        onMount={onMount}
        onCancel={onCancel}
        validationSchema={validationSchema}
        headline={headline}
        values={layout}
        initialValues={!isEmpty(layout) ? layout : defaultValues}
        onSubmit={onSubmit}
        onValidityChange={onValidityChange}
        name="email_layout"
        renderFieldset={this.renderFieldset}
      />
    );
  }
}

function mapStateToProps(state, ownProps) {
  const layoutId = ownProps.layoutId;

  const {
    entities: { layout: layouts },
    forms: { emailLayout: form },
  } = state;

  return {
    layout: layoutId ? layouts[layoutId] : {},
    token: !isEmpty(form) ? form.updateToken : '',
    form: form,
  };
}

export default connect(mapStateToProps, { loadLayout, initForm })(EmailLayoutEdit);

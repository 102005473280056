import { CALL_API } from './middleware';
import { Schemas, SearchSchemas } from './schemas';
import has from 'has';

export const RESET_DOWNLOAD = 'RESET_DOWNLOAD';
export function resetDownload() {
  return {
    type: RESET_DOWNLOAD,
  };
}

export const RESET_ERROR_MESSAGE = 'RESET_ERROR_MESSAGE';
export function resetErrorMessage() {
  return {
    type: RESET_ERROR_MESSAGE,
  };
}

export const RESET_INFO_MESSAGE = 'RESET_INFO_MESSAGE';
export function resetInfoMessage() {
  return {
    type: RESET_INFO_MESSAGE,
  };
}

export const RESET_WARNING_MESSAGE = 'RESET_WARNING_MESSAGE';
export function resetWarningMessage() {
  return {
    type: RESET_WARNING_MESSAGE,
  };
}

export const RESET_ENTITY = 'RESET_ENTITY';
export function resetEntity(entity, id) {
  return {
    type: RESET_ENTITY,
    entity: entity,
    id: id,
  };
}

export const OUTDATE_ENTITY = 'OUTDATE_ENTITY';
export function outdateEntity(entity) {
  return {
    type: OUTDATE_ENTITY,
    entity: entity,
  };
}

export const EMAILQUEUE_SEARCH_REQUEST = 'EMAILQUEUE_SEARCH_REQUEST';
export const EMAILQUEUE_SEARCH_SUCCESS = 'EMAILQUEUE_SEARCH_SUCCESS';
export const EMAILQUEUE_SEARCH_FAILURE = 'EMAILQUEUE_SEARCH_FAILURE';

function fetchResultsEmailQueue(searchText) {
  return {
    [CALL_API]: {
      types: [EMAILQUEUE_SEARCH_REQUEST, EMAILQUEUE_SEARCH_SUCCESS, EMAILQUEUE_SEARCH_FAILURE],
      endpoint: Routing.generate('emailqueue_search', { q: searchText }, true),
      schema: SearchSchemas.EMAILQUEUES,
      key: 'searchEmailQueues',
    },
  };
}

export function searchEmailQueue(searchText) {
  return (dispatch) => {
    return dispatch(fetchResultsEmailQueue(searchText));
  };
}

export const SMSQUEUE_SEARCH_REQUEST = 'SMSQUEUE_SEARCH_REQUEST';
export const SMSQUEUE_SEARCH_SUCCESS = 'SMSQUEUE_SEARCH_SUCCESS';
export const SMSQUEUE_SEARCH_FAILURE = 'SMSQUEUE_SEARCH_FAILURE';

function fetchResultsSmsQueue(searchText) {
  return {
    [CALL_API]: {
      types: [SMSQUEUE_SEARCH_REQUEST, SMSQUEUE_SEARCH_SUCCESS, SMSQUEUE_SEARCH_FAILURE],
      endpoint: Routing.generate('smsqueue_search', { q: searchText }, true),
      schema: SearchSchemas.SMSQUEUES,
      key: 'searchSmsQueues',
    },
  };
}

export function searchSmsQueue(searchText) {
  return (dispatch) => {
    return dispatch(fetchResultsSmsQueue(searchText));
  };
}

export const EMAILMODULE_SEARCH_REQUEST = 'EMAILMODULE_SEARCH_REQUEST';
export const EMAILMODULE_SEARCH_SUCCESS = 'EMAILMODULE_SEARCH_SUCCESS';
export const EMAILMODULE_SEARCH_FAILURE = 'EMAILMODULE_SEARCH_FAILURE';

function fetchResultsEmailModule(searchText) {
  return {
    [CALL_API]: {
      types: [EMAILMODULE_SEARCH_REQUEST, EMAILMODULE_SEARCH_SUCCESS, EMAILMODULE_SEARCH_FAILURE],
      endpoint: Routing.generate('emailmodule_search', { q: searchText }, true),
      schema: SearchSchemas.EMAILMODULES,
      key: 'searchEmailModules',
    },
  };
}

export function searchEmailModule(searchText) {
  // eslint-disable-next-line no-unused-vars
  return (dispatch, getState) => {
    return dispatch(fetchResultsEmailModule(searchText));
  };
}

export const PRODUCT_SEARCH_REQUEST = 'PRODUCT_SEARCH_REQUEST';
export const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS';
export const PRODUCT_SEARCH_FAILURE = 'PRODUCT_SEARCH_FAILURE';

function fetchResultsProduct(searchText) {
  return {
    [CALL_API]: {
      types: [PRODUCT_SEARCH_REQUEST, PRODUCT_SEARCH_SUCCESS, PRODUCT_SEARCH_FAILURE],
      endpoint: Routing.generate('product_search', { q: searchText }, true),
      schema: SearchSchemas.PRODUCTS,
      key: 'searchProducts',
    },
  };
}

export function searchProduct(searchText) {
  return (dispatch) => {
    return dispatch(fetchResultsProduct(searchText));
  };
}

export const CAMPAIGNCONFIGPARAM_SEARCH_REQUEST = 'CAMPAIGNCONFIGPARAM_SEARCH_REQUEST';
export const CAMPAIGNCONFIGPARAM_SEARCH_SUCCESS = 'CAMPAIGNCONFIGPARAM_SEARCH_SUCCESS';
export const CAMPAIGNCONFIGPARAM_SEARCH_FAILURE = 'CAMPAIGNCONFIGPARAM_SEARCH_FAILURE';

function fetchResultsCampaignConfigParam(searchText) {
  return {
    [CALL_API]: {
      types: [
        CAMPAIGNCONFIGPARAM_SEARCH_REQUEST,
        CAMPAIGNCONFIGPARAM_SEARCH_SUCCESS,
        CAMPAIGNCONFIGPARAM_SEARCH_FAILURE,
      ],
      endpoint: Routing.generate('campaignconfigparam_search', { q: searchText }, true),
      schema: SearchSchemas.CAMPAIGNCONFIGPARAMS,
      key: 'searchCampaignConfigParams',
    },
  };
}

export function searchCampaignConfigParam(searchText) {
  return (dispatch) => {
    return dispatch(fetchResultsCampaignConfigParam(searchText));
  };
}

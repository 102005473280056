import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import EmailQueue from './EmailQueue';
import { withRouter } from '../../withRouter';

class EmailQueueIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
    seasonJobId: PropTypes.string,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_STAFF'],
    resultset: [],
  };

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        <EmailQueue seasonType={'email'} />
      </ACL>
    );
  }
}

export default withRouter(EmailQueueIndex);

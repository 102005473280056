import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Interaction from './Interaction';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { fetchInteractions } from '../../StatisticCampaignSlice';

type InteractionsProps = {
  campaignId: number;
};

export default function Interactions({ campaignId }: InteractionsProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { openedEmail, openedWeb, bounced } = useAppSelector((state) => state.statisticCampaign.interactions);
  const {
    dispatched: { sentToInDepot, sentToNotInDepot },
  } = useAppSelector((state) => state.statisticCampaign.keyIndicator);
  const sent = { sentToInDepot, sentToNotInDepot };

  useEffect(() => {
    setLoading(true);
    dispatch(fetchInteractions({ id: campaignId })).then(() => setLoading(false));
  }, []);

  return (
    <Grid container spacing={3}>
      {loading ? (
        <>
          <Grid
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <Skeleton variant="rounded" width="100%" height="600px" sx={{ mb: 3, mt: 3 }} />
          </Grid>
          <Grid
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <Skeleton variant="rounded" width="100%" height="600px" sx={{ mb: 3, mt: 3 }} />
          </Grid>
          <Grid
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <Skeleton variant="rounded" width="100%" height="600px" sx={{ mb: 3, mt: 3 }} />
          </Grid>
        </>
      ) : (
        <>
          {openedEmail && sent && (
            <Grid
              size={{
                xs: 12,
                md: 4,
              }}
            >
              <Interaction title="Öffnungen" gaugeTitle="Öffnungsrate" dataset={openedEmail} sent={sent} />
            </Grid>
          )}
          {openedWeb && sent && (
            <Grid
              size={{
                xs: 12,
                md: 4,
              }}
            >
              <Interaction title="Klicks" gaugeTitle="Klickrate" dataset={openedWeb} sent={sent} />
            </Grid>
          )}
          {bounced && sent && (
            <Grid
              size={{
                xs: 12,
                md: 4,
              }}
            >
              <Interaction title="Bounces" gaugeTitle="Bounce-Rate" dataset={bounced} sent={sent} />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

import React, { JSX } from 'react';
import Scene from '../../components/Scene';
import ErrorBoundary from '../../components/ErrorBoundary';
import IFrame from '../../components/IFrame';

function Home(): JSX.Element {
  return (
    <ErrorBoundary>
      <Scene>
        <IFrame title="aktuell" url="https://seeds-static.eeloy.com/aktuell/" />
      </Scene>
    </ErrorBoundary>
  );
}

export default Home;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import CommunicationMailOutline from '@mui/icons-material/MailOutline';
import CommunicationEmail from '@mui/icons-material/Email';
import CommunicationCall from '@mui/icons-material/Call';
import NotificationSms from '@mui/icons-material/Sms';
import CustomerListCustomerMemo from './CustomerListCustomerMemo';
import CustomerListCustomerCar from './CustomerListCustomerCar';
import PhoneNumber from './service/PhoneNumber';
import Email from './service/Email';
import { Table, TableData } from './StyledElements/StyledElements';
import { Box } from '@mui/material';

class CustomerListCustomerCustomer extends Component {
  static propTypes = {
    customerId: PropTypes.number.isRequired,
    onFormMount: PropTypes.func.isRequired,
    onFormSubmit: PropTypes.func.isRequired,
    onFormValidityChange: PropTypes.func.isRequired,
    cars: PropTypes.array,
    editorMode: PropTypes.bool,
    customer: PropTypes.object,
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(this.props.customerId, nextProps.customerId)
      || !isEqual(this.props.customer, nextProps.customer)
      || !isEqual(this.props.cars, nextProps.cars)
      || !isEqual(this.props.editorMode, nextProps.editorMode)
    );
  };

  renderDseInfo = () => {
    return (
      <div>
        <CommunicationMailOutline
          sx={{
            mr: 3,
          }}
          color={'inherit'}
        />
        <CommunicationCall
          sx={{
            mr: 3,
          }}
          color={'inherit'}
        />
        <CommunicationEmail
          sx={{
            mr: 3,
          }}
          color={'inherit'}
        />
        <NotificationSms
          sx={{
            mr: 3,
          }}
          color={'inherit'}
        />
      </div>
    );
  };

  renderMemo = () => {
    const { customer, editorMode, onFormSubmit, onFormMount, onFormValidityChange, bindSubmitForm } = this.props;

    if (editorMode) {
      return (
        <CustomerListCustomerMemo
          bindSubmitForm={bindSubmitForm}
          onMount={onFormMount}
          onValidityChange={onFormValidityChange}
          customerId={customer.id}
          onSubmit={onFormSubmit}
        />
      );
    }

    return <span>{customer.memo}</span>;
  };

  renderCar = (car, index) => {
    if (!car) {
      return null;
    }

    return <CustomerListCustomerCar key={`car-${car}`} carId={car} initiallyExpanded={index === 0} />;
  };

  render() {
    const { customer, cars } = this.props;

    if (!customer) {
      return null;
    }

    return (
      <Box sx={{ pt: 3 }}>
        <Table width="100%">
          <tbody>
            <tr>
              <TableData textColor="text.secondary" width="15%">
                Wohnort
              </TableData>
              <TableData width="35%">{customer.city}</TableData>
              {customer.dsePhone ? (
                <TableData textColor="text.secondary" width="15%">
                  Telefon privat
                </TableData>
              ) : null}
              {customer.dsePhone ? (
                <TableData width="35%">
                  <PhoneNumber>{customer.phone}</PhoneNumber>
                </TableData>
              ) : null}
            </tr>
            <tr>
              <TableData textColor="text.secondary" width="15%">
                Geburtstag
              </TableData>
              <TableData width="35%">{customer.birthday}</TableData>
              {customer.dsePhone ? (
                <TableData textColor="text.secondary" width="15%">
                  Telefon mobil
                </TableData>
              ) : null}
              {customer.dsePhone ? (
                <TableData width="35%">
                  <PhoneNumber>{customer.mobile}</PhoneNumber>
                </TableData>
              ) : null}
            </tr>
            <tr>
              <TableData textColor="text.secondary" width="15%">
                E-Mail
              </TableData>
              <TableData width="35%">
                <Email>{customer.email}</Email>
              </TableData>
              {customer.dsePhone ? (
                <TableData textColor="text.secondary" width="15%">
                  Telefon alternativ
                </TableData>
              ) : null}
              {customer.dsePhone ? (
                <TableData width="35%">
                  <PhoneNumber>{customer.phone2}</PhoneNumber>
                </TableData>
              ) : null}
            </tr>
            <tr>
              <TableData textColor="text.secondary" width="15%">
                DSE Freigabe
              </TableData>
              <TableData width="35%">{this.renderDseInfo()}</TableData>
            </tr>
          </tbody>
        </Table>
        {cars.map(this.renderCar)}
      </Box>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const customerId = ownProps.customerId;
  const {
    entities: { customer },
  } = state;

  return {
    customer: customer ? customer[customerId] : null,
  };
}

export default connect(mapStateToProps, {})(CustomerListCustomerCustomer);

import React, { Component } from 'react';

import PropTypes from 'prop-types';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { isEqual, isEmpty } from 'lodash';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';
import { loadSignees } from '../actions';
import RenderCellExpand from '../../../components/DataGrid/RenderCellExpand';
import DataGridTable from '../../../components/DataGrid/DataGrid';
import { compose } from 'redux';

class SigneeList extends Component {
  static propTypes = {
    signees: PropTypes.object.isRequired,
    onSortModelChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    resultset: PropTypes.array.isRequired,
    defaultSortColumns: PropTypes.array.isRequired,
    defaultSortOrders: PropTypes.array.isRequired,
    initialGridState: PropTypes.object,
  };

  static defaultProps = {};

  state = {
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        type: 'number',
        width: 30,
      },
      {
        field: 'gender',
        headerName: 'Anrede',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => RenderCellExpand({ ...params, modifier: 'translate' }),
      },
      {
        field: 'firstname',
        headerName: 'Vorname',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'name',
        headerName: 'Name',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'position',
        headerName: 'Position',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
    ],
  };

  componentDidMount = () => {};

  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps.signees, this.props.signees) || !isEqual(nextProps.resultset, this.props.resultset);
  };

  render() {
    const { signees, onSortModelChange, resultset, pagination, onDelete, onAdd, onEdit, onLoadNext, initialGridState } =
      this.props;
    const { columns } = this.state;

    return (
      <>
        <ErrorBoundary>
          <DataGridTable
            title="Unterzeichner"
            rows={signees}
            iteratableRows={resultset}
            columns={columns}
            pagination={pagination}
            onEdit={onEdit}
            onAdd={onAdd}
            onDelete={onDelete}
            onSortModelChange={onSortModelChange}
            onLoadNext={onLoadNext}
            initialState={initialGridState}
          />
        </ErrorBoundary>
      </>
    );
  }
}

function mapStateToProps(state) {
  const {
    entities: { signee },
    resultsets: { signee: resultset },
  } = state;

  return {
    signees: !isEmpty(signee) ? signee : {},
    resulset: resultset,
  };
}

const enhance = compose(withRouter, connect(mapStateToProps, { loadSignees }));

export default enhance(SigneeList);

import React from 'react';
import { Paper, Typography, useTheme } from '@mui/material';
import { BarChart } from '@mui/x-charts';
import Translate, { translateValue } from '../../../../components/service/Translate';
import { useAppSelector } from '../../../../hooks';
import { TypeData } from '../../StatisticCampaignTypes';

type CustomerGroupChartProps = {
  title: string;
  data: TypeData[];
};
export default function CustomerGroupChart({ title, data }: CustomerGroupChartProps) {
  const language = useAppSelector((state) => state.app.authenticatedUser?.language);
  const theme = useTheme();
  const valueFormatter = (value: string) => translateValue(value, language, 'customerGroups');

  return (
    <Paper sx={{ p: 2 }}>
      <Typography variant="h5" gutterBottom>
        <Translate>{title}</Translate>
      </Typography>
      <BarChart
        dataset={data}
        yAxis={[{ scaleType: 'band', dataKey: 'label', valueFormatter }]}
        series={[
          {
            dataKey: 'count',
            label: 'Anzahl',
            color: theme.palette.primary.main,
          },
        ]}
        layout="horizontal"
        grid={{ vertical: true }}
        height={500}
        margin={{ left: 220 }}
        slotProps={{ legend: { hidden: true } }}
      />
    </Paper>
  );
}

import React from 'react';
import { Box } from '@mui/material';
import magnifierImage from '../../theme/images/error/magnifier.svg';
import Translate from '../service/Translate';

export default function NoRowsOverlay() {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}
    >
      <Box
        component="img"
        src={magnifierImage}
        alt="No entries"
        sx={{
          width: 96,
          height: 'auto',
        }}
      />
      <Box sx={{ mt: 2 }}>
        <Translate>No entries found.</Translate>
      </Box>
    </Box>
  );
}

import * as Yup from 'yup';
import { ErrorMessage, FormikProps, FormikValues } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import Translate from '../../components/service/Translate';
import ValidationErrors from '../../ValidationErrors';
import { useAppDispatch } from '../../hooks';
import { recoverPassword } from './SecuritySlice';
import Form from '../../components/Form';

type RecoverPasswordValues = {
  username: string;
};

const initialRecoverValues: RecoverPasswordValues = {
  username: '',
};

const validationRecoverSchema = Yup.object().shape({
  username: Yup.string().required(ValidationErrors.required),
});

function RecoverPassword() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const renderFieldset = ({
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    submitForm,
    isSubmitting,
  }: FormikProps<FormikValues>) => (
    <>
      <Stack spacing={3}>
        <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>
          <Translate>Request an email to reset your password.</Translate>
        </Typography>
        <TextField
          autoFocus
          error={!!(touched.username && errors.username)}
          fullWidth
          helperText={<ErrorMessage name="username" />}
          label={<Translate>Username</Translate>}
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={values.username}
        />
      </Stack>
      <Button
        fullWidth
        sx={{ mt: 3 }}
        size="large"
        type="submit"
        variant="contained"
        onClick={submitForm}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        <Translate>Request email</Translate>
      </Button>
    </>
  );

  const handleSubmit = async (data: FormData) => {
    const { statusCode } = await dispatch(recoverPassword(data)).unwrap();

    if (statusCode !== 200) {
      navigate('/server-error');
    }

    navigate('/login');
  };

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5">
          <Translate>Forgot your password?</Translate>
        </Typography>
      </Box>
      <Form
        disableToolbar
        disableMargin
        initialValues={initialRecoverValues}
        validationSchema={validationRecoverSchema}
        onSubmit={handleSubmit}
        renderFieldset={renderFieldset}
      />
    </>
  );
}

export default RecoverPassword;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';
import { Toolbar, List, Typography, Avatar, ListItemIcon, ListItemText, Box } from '@mui/material';
import { CustomerListDrawer, CustomerListAppBar } from './StyledElements/StyledElements';

import ListItemButton from '@mui/material/ListItemButton';

class ClippedDrawer extends Component {
  handleItemSelect = (event, index) => {
    this.props.onItemSelect(event, index);
    this.setState({ selectedIndex: index });
  };

  // eslint-disable-next-line no-unused-vars
  renderListItem = (itemId, index) => {
    const { defaultSelectedIndex } = this.props;
    const item = this.props.listItems[itemId];

    return (
      <ListItemButton
        className="dockedListItem"
        key={`list-${item.id}`}
        onClick={(event) => {
          this.handleItemSelect(event, item.id);
        }}
        selected={defaultSelectedIndex === item.id}
      >
        <ListItemIcon>
          <Avatar size={10} color="default">
            {item.primaryText.charAt(0)}
          </Avatar>
        </ListItemIcon>
        <ListItemText primary={item.primaryText} />
      </ListItemButton>
    );
  };

  render() {
    const { resultset } = this.props;

    return (
      <Box sx={{ display: 'flex' }}>
        <CustomerListAppBar position="fixed">
          <Toolbar>
            <Typography variant="h6" color="inherit" noWrap></Typography>
          </Toolbar>
        </CustomerListAppBar>
        <CustomerListDrawer variant="permanent" anchor="right">
          <Box sx={(theme) => theme.mixins.toolbar} />
          <List>{!isEmpty(resultset) ? resultset.map(this.renderListItem) : null}</List>
        </CustomerListDrawer>
      </Box>
    );
  }
}

ClippedDrawer.propTypes = {
  resultset: PropTypes.array.isRequired,
  onItemSelect: PropTypes.func,
  defaultSelectedIndex: PropTypes.number,
};

export default ClippedDrawer;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { pickBy, isEmpty } from 'lodash';
import {
  Toolbar,
  Divider,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Menu,
  Typography,
  Stack,
} from '@mui/material';
import ContentAdd from '@mui/icons-material/Add';
import ACL from '../../components/ACL';
import BreadcrumbsNav from '../../components/BreadcrumbNavigation';
import SeasonList from '../Season/components/SeasonList';
import { fetchCampaign } from './actions';
import { outdateEntity } from '../../actions';
import { disableSeason, enableSeason } from '../Season/actions';
import { getCampaignByIdSelector } from './selectors_deprecated';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter } from '../../withRouter';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import { ColorActions, Spacer, Title, AccentColorButton } from '../../components/StyledElements/StyledElements';
import { compose } from 'redux';

class CampaignShow extends Component {
  static propTypes = {
    fetchCampaign: PropTypes.func.isRequired,
    enableSeason: PropTypes.func.isRequired,
    disableSeason: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    requiredRoles: PropTypes.array,
    campaignId: PropTypes.number,
    campaign: PropTypes.object,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_PRO', 'ROLE_SUPERADMIN'],
  };

  state = {
    anchorEl: null,
  };

  componentDidMount = () => {
    const {
      match: {
        params: { campaignId },
      },
      fetchCampaign,
    } = this.props;
    fetchCampaign(campaignId, [{ seasons: ['mailing', 'email', 'sms'] }]);
  };

  handleMenuClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSeasonToggle = (seasonId, isInputChecked) => {
    const { disableSeason, enableSeason, fetchCampaign, campaignId, outdateEntity } = this.props;
    const promises = [];

    let form = new FormData();
    form.append('id', seasonId);

    promises.push(isInputChecked ? enableSeason(form) : disableSeason(form));

    Promise.all(promises).then(() => {
      outdateEntity('season');
      fetchCampaign(campaignId, [{ seasons: ['mailing', 'email', 'sms'] }]);
    });
  };

  createNewSeason = (mode) => {
    this.props.navigate('/campaign/' + this.props.campaignId + '/' + mode + '/new');
  };

  editSeason = (mode) => {
    this.props.navigate('/campaign/' + this.props.campaignId + '/' + mode + '/edit');
  };

  handleEditClick = () => {
    this.props.navigate('/campaign/' + this.props.campaignId + '/edit');
  };

  renderHeader = (campaign) => {
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    const callback = (event, value) => {
      this.props.navigate('/campaign/' + this.props.campaignId + '/' + value + '/new');
    };

    const EmailMenuItem = (
      <MenuItem value="email" onClick={(e) => callback(e, 'email')}>
        <ListItemIcon>
          <ContentAdd color="inherit" />
        </ListItemIcon>
        <ListItemText primary="neue E-Mail" />
      </MenuItem>
    );

    const SMSMenuItem = (
      <MenuItem value="sms" onClick={(e) => callback(e, 'sms')}>
        <ListItemIcon>
          <ContentAdd color="inherit" />
        </ListItemIcon>
        <ListItemText primary="neue SMS" />
      </MenuItem>
    );

    return (
      <>
        <Toolbar>
          <Title>
            <BreadcrumbsNav items={[{ link: '/#/campaign', text: 'Kampagnen' }, campaign.title]} />
          </Title>
          <Spacer />
          <ColorActions>
            <AccentColorButton
              sx={{ mr: 3 }}
              variant="contained"
              color="primary"
              aria-owns={open ? 'render-props-menu' : null}
              aria-haspopup="true"
              onClick={this.handleEditClick}
            >
              <EditIcon />
            </AccentColorButton>
            <AccentColorButton
              variant="contained"
              color="primary"
              aria-owns={open ? 'render-props-menu' : null}
              aria-haspopup="true"
              onClick={this.handleMenuClick}
            >
              <AddIcon />
            </AccentColorButton>
            <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={this.handleMenuClose}>
              {EmailMenuItem}
              {SMSMenuItem}
            </Menu>
          </ColorActions>
        </Toolbar>
      </>
    );
  };

  renderMailingSeasons = () => {
    return (
      <ErrorBoundary>
        <SeasonList seasonType="print" onToggle={this.handleSeasonToggle} />
      </ErrorBoundary>
    );
  };

  renderEmailSeasons = () => {
    return (
      <ErrorBoundary>
        <SeasonList seasonType="email" onToggle={this.handleSeasonToggle} />
      </ErrorBoundary>
    );
  };

  renderSmsSeasons = () => {
    return (
      <ErrorBoundary>
        <SeasonList seasonType="sms" onToggle={this.handleSeasonToggle} />
      </ErrorBoundary>
    );
  };

  render() {
    const { campaign, campaignId } = this.props;
    if (!campaign) {
      return null;
    }
    let campaignById = campaign[campaignId];
    return (
      <ACL authorized={this.props.requiredRoles}>
        {this.renderHeader(campaignById)}
        <Stack spacing={3}>
          {this.renderMailingSeasons()}
          {this.renderEmailSeasons()}
          {this.renderSmsSeasons()}
        </Stack>
      </ACL>
    );
  }
}

const makeMapStateToProps = () => {
  const getCampaignById = getCampaignByIdSelector();

  return (state, props) => {
    return {
      campaignId: parseInt(props?.match?.params?.campaignId),
      campaign: getCampaignById(state, props),
      season: state.entities.season,
    };
  };
};

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    fetchCampaign,
    disableSeason,
    enableSeason,
    outdateEntity,
  }),
);

export default enhance(CampaignShow);

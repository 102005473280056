import { schema } from 'normalizr';
import { MenuItem } from '../scenes/Menu/MenuItemType';

export const Season = new schema.Entity('season', {}, { idAttribute: (season) => season.id });
export const CampaignConfigFile = new schema.Entity(
  'campaignConfigFile',
  {},
  { idAttribute: (campaignConfigFile) => campaignConfigFile.id },
);
export const SeasonCausality = new schema.Entity(
  'seasonCausality',
  {},
  { idAttribute: (seasonCausality) => seasonCausality.id },
);
export const Campaign = new schema.Entity('campaign', {}, { idAttribute: (campaign) => campaign.id });
export const CampaignConfigParam = new schema.Entity(
  'campaignConfigParam',
  {},
  { idAttribute: (campaignConfigParam) => campaignConfigParam.id },
);
export const PrintableCopyGroup = new schema.Entity(
  'printableCopyGroup',
  {},
  { idAttribute: (printableCopyGroup) => printableCopyGroup.id },
); // todo remove when updated to copyGroup in all places
export const CopyGroup = new schema.Entity('copyGroup', {}, { idAttribute: (copyGroup) => copyGroup.id });
export const ModuleTag = new schema.Entity('moduleTag', {}, { idAttribute: (moduleTag) => moduleTag.id });
export const Product = new schema.Entity('product', {}, { idAttribute: (product) => product.id });
export const CampaignProduct = new schema.Entity(
  'campaignProduct',
  {},
  { idAttribute: (campaignProduct) => campaignProduct.id },
);

// Upload
export const Upload = new schema.Entity('upload', {}, { idAttribute: (upload) => upload.id });
export const Tag = new schema.Entity('tag', {}, { idAttribute: (tag) => tag.id });
export const CcsScan = new schema.Entity('ccsScan', {}, { idAttribute: (ccsScan) => ccsScan.id });

// Mailing
export const MailingJob = new schema.Entity('mailingJob', {}, { idAttribute: (mailingJob) => mailingJob.id });
export const Letter = new schema.Entity('letter', {}, { idAttribute: (letter) => letter.id });
export const Mailing = new schema.Entity('mailing', {}, { idAttribute: (mailing) => mailing.id });
export const MailingModule = new schema.Entity(
  'mailingModule',
  {},
  { idAttribute: (mailingModule) => mailingModule.id },
);
export const Template = new schema.Entity('template', {}, { idAttribute: (template) => template.id });
export const Signature = new schema.Entity('signature', {}, { idAttribute: (signature) => signature.id });

// Email
export const EmailModule = new schema.Entity('emailModule', {}, { idAttribute: (emailModule) => emailModule.id });
export const Layout = new schema.Entity('layout', {}, { idAttribute: (layout) => layout.id });
export const Email = new schema.Entity('email', {}, { idAttribute: (email) => email.id });
export const Account = new schema.Entity('account', {}, { idAttribute: (account) => account.id });
export const EmailAccount = new schema.Entity<MenuItem>(
  'emailAccount',
  {},
  { idAttribute: (emailAccount) => emailAccount.id },
);
export const EmailQueue = new schema.Entity('emailQueue', {}, { idAttribute: (emailQueue) => emailQueue.id });
export const EmailTemplate = new schema.Entity(
  'emailTemplate',
  {},
  { idAttribute: (emailTemplate) => emailTemplate.id },
);

export const EmailJob = new schema.Entity('emailJob', {}, { idAttribute: (emailJob) => emailJob.id });

// Sms
export const Sms = new schema.Entity('sms', {}, { idAttribute: (sms) => sms.id });
export const SmsJob = new schema.Entity('smsJob', {}, { idAttribute: (smsJob) => smsJob.id });
export const SmsQueue = new schema.Entity('smsQueue', {}, { idAttribute: (smsQueue) => smsQueue.id });

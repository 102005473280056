import { createSlice, isFulfilled, isPending, isRejected } from '@reduxjs/toolkit';

const loadingSlice = createSlice({
  name: 'loadingSlices',
  initialState: 0,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(isPending, (state) => state + 1)
      .addMatcher(isFulfilled, (state) => Math.max(0, state - 1))
      .addMatcher(isRejected, (state) => Math.max(0, state - 1));
  },
});

export default loadingSlice.reducer;

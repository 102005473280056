import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { Security } from './SecurityType';

const initialState: Security = {
  isAuthenticated: false,
  outdated: false,
};

export const loginUser = createAsyncThunk(
  'security/login',
  async (credentials: { username: string; password: string }) => {
    const response = await axios.post<
      Partial<{
        success?: boolean;
        message?: string;
        resetPath?: string;
      }>
    >(Routing.generate('v3_login', {}, true), { ...credentials }, { validateStatus: (status) => status < 500 });

    if (response.status === 302) {
      return {
        statusCode: response.status,
        resetPath: response.data.resetPath,
        outdated: true,
        info: undefined,
        error: undefined,
      };
    }

    if (response.status !== 200 || !response.data.success) {
      return {
        isAuthenticated: false,
        outdated: undefined,
        info: undefined,
        error: 'Invalid credentials',
      };
    }

    return {
      statusCode: response.status,
      outdated: undefined,
      info: undefined,
      error: undefined,
    };
  },
);

export const resetPassword = createAsyncThunk(
  'security/reset',
  async (credentials: { data: FormData; resetKey: string | undefined }) => {
    const { data, resetKey } = credentials;

    const response = await axios.post<{
      success: boolean;
      results: {
        id: string;
        usedBefore: boolean;
        passwordUpdated: boolean;
        expired: boolean;
      };
    }>(Routing.generate('password_reset_v3', { resetKey }, true), data);

    if (response.data.results.passwordUpdated) {
      return {
        passwordUpdated: true,
        expired: false,
        info: undefined,
        error: undefined,
        message: {
          type: 'success',
          text: 'The new password has been saved successfully',
        },
      };
    }

    if (response.data.results.expired) {
      return {
        passwordUpdated: false,
        expired: true,
        info: undefined,
        error: undefined,
        message: {
          type: 'error',
          text: 'This request has expired',
        },
      };
    }

    if (response.data.results.usedBefore) {
      return {
        passwordUpdated: false,
        expired: false,
        info: undefined,
        error: 'This password has been used before',
        outdated: true,
      };
    }

    return {
      passwordUpdated: false,
      expired: false,
      info: undefined,
      error: 'An error occurred',
      outdated: true,
    };
  },
);

export const fetchAuthentication = createAsyncThunk('security/authenticate', async () => {
  const response = await axios.get<{
    isAuthenticated: boolean;
  }>(Routing.generate('security_authentication', null, true), {
    validateStatus: (status) => status < 500,
  });

  if (response.status !== 200 || !response.data.isAuthenticated) {
    return {
      isAuthenticated: false,
      authenticatedUser: undefined,
      seedsMode: undefined,
      authenticatedUserRoles: undefined,
    };
  }

  return { isAuthenticated: true };
});

export const recoverPassword = createAsyncThunk('security/recovery', async (data: FormData) => {
  const response = await axios.post<{
    text: string;
    type: string;
  }>(Routing.generate('password_recovery', null, true), data);

  if (response.data.type === 'error') {
    return {
      info: undefined,
      error: response.data.text,
      statusCode: response.status,
    };
  }

  return {
    info: response.data.text,
    error: undefined,
    statusCode: response.status,
  };
});

const securitySlice = createSlice({
  name: 'security',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, action) => {
      const { outdated, info, error } = action.payload;

      state.outdated = !!outdated;
      state.info = info;
      state.error = error;
    });
    builder.addCase(resetPassword.fulfilled, (state, action) => {
      const { info, outdated, error } = action.payload;

      state.info = info;
      state.error = error;
      state.outdated = !!outdated;
    });
    builder.addCase(fetchAuthentication.fulfilled, (state, action) => {
      state.isAuthenticated = action.payload.isAuthenticated;
    });
    builder.addCase(fetchAuthentication.rejected, (state) => {
      state.isAuthenticated = false;
    });
    builder.addCase(recoverPassword.fulfilled, (state, action) => {
      const { info, error } = action.payload;

      state.error = error;
      state.info = info;
    });
  },
});

export default securitySlice.reducer;

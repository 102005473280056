import * as Yup from 'yup';
import { ErrorMessage, FormikProps, FormikValues } from 'formik';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { Alert } from '@mui/material';
import Translate from '../../components/service/Translate';
import ValidationErrors from '../../ValidationErrors';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { fetchAppConfig } from '../App/AppSlice';
import Form from '../../components/Form';
import { fetchAuthentication, loginUser } from './SecuritySlice';

type LoginValues = {
  username: string;
  password: string;
};

const initialLoginValues: LoginValues = {
  username: '',
  password: '',
};

const validationLoginSchema = Yup.object().shape({
  username: Yup.string().required(ValidationErrors.required),
  password: Yup.string().required(ValidationErrors.required),
});

function Login() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { info, error } = useAppSelector((state) => state.security);

  const renderFieldset = ({
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    submitForm,
    isSubmitting,
  }: FormikProps<FormikValues>) => (
    <>
      <Stack spacing={3}>
        <TextField
          autoFocus
          error={!!(touched.username && errors.username)}
          fullWidth
          helperText={<ErrorMessage name="username" />}
          label={<Translate>Username</Translate>}
          name="username"
          onBlur={handleBlur}
          onChange={handleChange}
          type="text"
          value={values.username}
        />
        <TextField
          error={!!(touched.password && errors.password)}
          fullWidth
          helperText={<ErrorMessage name="password" />}
          label={<Translate>Password</Translate>}
          name="password"
          onBlur={handleBlur}
          onChange={handleChange}
          type="password"
          value={values.password}
        />
        {info && !isSubmitting && (
          <Alert severity="success">
            <Translate>{info}</Translate>
          </Alert>
        )}
        {error && !isSubmitting && (
          <Alert severity="error">
            <Translate>{error}</Translate>
          </Alert>
        )}
      </Stack>
      <Button
        fullWidth
        sx={{ mt: 3 }}
        size="large"
        type="submit"
        variant="contained"
        onClick={submitForm}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        <Translate>Log in</Translate>
      </Button>
      <Box sx={{ mt: 3, mb: 3 }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Link component={RouterLink} to="/recovery" underline="always" variant="body1">
            <Translate>Forgot your password?</Translate>
          </Link>
          <Link
            href={Routing.generate('authentication_login_v3', null, true)}
            underline="always"
            variant="body1"
            color="text.secondary"
          >
            <Stack direction="row" alignItems="center">
              <Translate>EELOY Login</Translate>
            </Stack>
          </Link>
        </Stack>
      </Box>
    </>
  );

  const loadSession = async () => {
    const response = await dispatch(fetchAuthentication()).unwrap();

    if (response.isAuthenticated) {
      dispatch(fetchAppConfig());
    }

    return response.isAuthenticated;
  };

  const handleSubmit = async (_formData: FormData, values: FormikValues) => {
    const { username, password } = values;
    const { statusCode, resetPath } = await dispatch(loginUser({ username, password })).unwrap();

    if (statusCode === 302 && resetPath) {
      navigate(resetPath);

      return;
    }

    if (statusCode === 200 && (await loadSession())) {
      navigate('/');
    }
  };

  return (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5">Login</Typography>
      </Box>
      <Form
        disableToolbar
        disableMargin
        initialValues={initialLoginValues}
        validationSchema={validationLoginSchema}
        onSubmit={handleSubmit}
        renderFieldset={renderFieldset}
      />
    </>
  );
}

export default Login;

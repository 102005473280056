import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Input, MenuItem, TextField } from '@mui/material';
import { isEmpty, isEqual } from 'lodash';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import Form from '../../../components/Form';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { initForm } from '../actions';
import * as Yup from 'yup';
import ValidationErrors from '../../../ValidationErrors';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid2';
import { HiddenInput } from '../../../components/StyledElements/StyledElements';

const validationSchema = Yup.object().shape({
  season: Yup.string().required(ValidationErrors.required),
  cause: Yup.string().required(ValidationErrors.required),
  effect: Yup.string()
    .oneOf(['exclude', 'exclude_opened'], ValidationErrors.requiredSelect)
    .required(ValidationErrors.required),
});

class Edit extends Component {
  static propTypes = {
    seasons: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    token: PropTypes.string,
    onSubmit: PropTypes.func.isRequired,
  };

  static defaultProps = {};

  state = {};

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.token, this.props.token)
      || !isEqual(nextProps.form, this.props.form)
      || !isEqual(nextProps.currentSeason, this.props.currentSeason)
      || !isEqual(nextProps.seasons, this.props.seasons)
    );
  };

  componentDidMount = () => {
    if (!this.props.token) {
      this.props.initForm();
    }
  };

  handleDelete = () => {
    const { currentSeason } = this.props;
    this.props.onDelete(currentSeason);
  };

  renderSelect = (item, props) => {
    const { values } = props;
    if (values.season !== item.id) {
      return (
        <MenuItem key={'config-key-' + item.id} value={item.id}>
          {item.configKey}
        </MenuItem>
      );
    }
  };

  renderFieldset = (props) => {
    const { currentSeason, seasons } = this.props;
    const { values, handleChange, touched, errors } = props;

    return (
      <>
        <HiddenInput type="hidden" name="updateToken" defaultValue={values.updateToken ? values.updateToken : ''} />
        <HiddenInput type="hidden" name="id" defaultValue={values.id ? values.id : ''} />
        <Grid container>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              select
              name="season"
              label={<Translate>Kampagne</Translate>}
              onChange={handleChange}
              value={values.season ? values.season : ''}
              helperText={touched.season ? errors.season : ''}
              error={touched.season && Boolean(errors.season)}
              fullWidth
              disabled={true}
            >
              <MenuItem key={'season-' + values.season} value={values.season}>
                {currentSeason ? currentSeason.configKey : ''}
              </MenuItem>
            </TextField>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              select
              name="cause"
              label={<Translate>ist abhängig von</Translate>}
              onChange={handleChange('cause')}
              helperText={touched.cause ? errors.cause : ''}
              error={touched.cause && Boolean(errors.cause)}
              fullWidth
              value={values.cause ? values.cause : ''}
            >
              {Object.values(seasons).map((item) => this.renderSelect(item, props))}
            </TextField>
          </Grid>
          <Grid size={12}>
            <TextField
              select
              label={<Translate>effect</Translate>}
              name={'effect'}
              data-cy="seasoncausality-edit-effect"
              value={values.effect ? values.effect : ''}
              onChange={handleChange('effect')}
              helperText={touched.effect ? errors.effect : ''}
              error={touched.effect && Boolean(errors.effect)}
            >
              <MenuItem
                key="seasoncausality-edit-effect-exclude"
                data-cy="seasoncausality-edit-effect-exclude"
                value={'exclude'}
              >
                <Translate>exclude</Translate>
              </MenuItem>
              <MenuItem
                key="seasoncausality-edit-effect-exclude_opened"
                data-cy="seasoncausality-edit-effect-exclude_opened"
                value={'exclude_opened'}
              >
                <Translate>exclude_opened</Translate>
              </MenuItem>
            </TextField>
          </Grid>
        </Grid>
        <Button
          sx={{
            mt: 3,
            mb: 3,
          }}
          variant="contained"
          color="error"
          onClick={this.handleDelete}
        >
          <DeleteIcon />
          <Translate>remove</Translate>
        </Button>
      </>
    );
  };

  render() {
    const { token, onSubmit, form, onMount, onCancel, cause, currentSeason, seasons, causality } = this.props;

    if (!form || !currentSeason || !seasons) {
      return null;
    }

    let defaultValues = {
      season: currentSeason.id,
      cause: cause,
      updateToken: token ? token : '',
    };

    return (
      <ErrorBoundary>
        <Form
          onMount={onMount}
          onSubmit={onSubmit}
          initialValues={!isEmpty(causality) ? causality : defaultValues}
          validationSchema={validationSchema}
          name="seasonCausality"
          renderFieldset={this.renderFieldset}
          onCancel={onCancel}
          token={token}
          values={{}}
        />
      </ErrorBoundary>
    );
  }
}

const makeMapStateToProps = (state) => {
  const {
    forms: { dependency: form },
  } = state;

  return {
    token: !isEmpty(form) ? form.updateToken : '',
    form: form,
  };
};

export default connect(makeMapStateToProps, { initForm })(Edit);

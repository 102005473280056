import selectors from '../../selectors';
import { isEmpty, merge, pick } from 'lodash';

const getSignees = (state) => state.entities.signee;
const getSignatures = (state) => state.entities.signature;

const getSigneeFilter = (state, props) => {
  if (!props) {
    return null;
  }

  if (!isEmpty(props.signeeId)) {
    return parseInt(props.signeeId);
  }

  if (!isEmpty(props?.match?.params?.signeeId)) {
    return parseInt(props.match.params.signeeId);
  }

  return null;
};

export const makeSigneeById = () => {
  return selectors([getSigneeFilter, getSignees], (signeeId, signees) => {
    return signees[signeeId];
  });
};

const getSigneeById = makeSigneeById();
export const makeSigneeWithSignatures = () => {
  return selectors([getSigneeById, getSignatures], (signee, signatures) => {
    if (signee && signee.mailingSignatures && signatures) {
      let signeeWithSignatures = merge({}, signee);
      signeeWithSignatures.mailingSignatures = Object.values(pick(signatures, signee.mailingSignatures));
      return signeeWithSignatures;
    } else {
      return signee;
    }
  });
};

import {
  Campaign,
  CampaignConfigParam,
  Email,
  EmailTemplate,
  Season,
  CcsScan,
  Mailing,
  MailingJob,
  MailingModule,
  Layout,
  Letter,
  Tag,
  Upload,
  Account,
  EmailAccount,
  PrintableCopyGroup,
  EmailQueue,
  SmsQueue,
  EmailJob,
  SeasonCausality,
  Sms,
  EmailModule,
  ModuleTag,
  Product,
  CampaignProduct,
  Signature,
  CampaignConfigFile,
  Template,
  SmsJob,
  CopyGroup,
} from './schemas/Campaign';
import { Selection } from './schemas/Selection';
import { Customer, Brand } from './schemas/Customer';
import { CustomerList, CustomerListCustomer } from './schemas/CustomerList';
import { Form } from './schemas/Form';
import { Download } from './schemas/Download';
import {
  CarResult,
  EmailModuleResult,
  EmailQueueResult,
  SmsQueueResult,
  ProductResult,
  CampaignConfigParamResult,
} from './schemas/Search';
import {
  Owner,
  OwnerInterface,
  Location,
  Role,
  Affiliate,
  Representation,
  CurrentUser,
  Staff,
  Signee,
  AffliliateSetting,
  Setting,
  LocationSetting,
} from './schemas/Owner';
import { MenuItemSchema } from './schemas/Common';
import { Log } from './schemas/Log';
import { Login } from './schemas/Login';
import { Car } from './schemas/Car';
import { Invoice } from './schemas/Invoice';

CurrentUser.define({
  staff: Staff,
});

Staff.define({
  locations: [Location],
  owner: Owner,
  mailingSignatures: [Signature],
});

CampaignProduct.define({
  product: Product,
  campaign: Campaign,
});

Product.define({
  modifiedProduct: Product,
  master: Product,
  campaignProducts: [CampaignProduct],
});

Signee.define({
  locations: [Location],
  mailingSignatures: [Signature],
});

Signee.define({
  locations: [Location],
  mailingSignatures: [Signature],
});

Owner.define({
  affiliates: [Affiliate],
  locations: [Location],
  ownerInterface: OwnerInterface,
});

Affiliate.define({
  owner: Owner,
  headquarter: Location,
  locations: [Location],
  parent: Affiliate,
  children: [Affiliate],
  affiliateSettings: [AffliliateSetting],
  brandCompany: Brand,
});

Location.define({
  affiliate: Affiliate,
  owner: Owner,
  children: [Location],
  parent: Location,
});

AffliliateSetting.define({
  setting: Setting,
  affiliate: Affiliate,
});

LocationSetting.define({
  setting: Setting,
  location: Location,
});

Campaign.define({
  seasons: [Season],
  products: [Product],
  campaignConfigFiles: [CampaignConfigFile],
  campaignConfigParams: [CampaignConfigParam],
});

CampaignConfigParam.define({
  campaign: Campaign,
});

Season.define({
  campaign: Campaign,
  mailingJobs: [MailingJob],
  currentMailingJob: MailingJob,
  currentEmailJob: EmailJob,
  selection: Selection,
  parent: Season,
  children: [Season],
  mailing: Mailing,
  email: Email,
  sms: Sms,
});

SeasonCausality.define({
  season: Season,
  cause: Season,
});

Selection.define({
  season: Season,
});

Tag.define({
  upload: Upload,
});

Upload.define({
  season: Season,
  staff: Staff,
  affiliate: Affiliate,
});

Letter.define({
  mailings: [Mailing],
});

Email.define({
  layout: Layout,
  account: Account,
  nextJobs: [EmailJob],
  previousJobs: [EmailJob],
  season: Season,
  template: EmailTemplate,
});

EmailTemplate.define({
  email: Email,
});

Layout.define({
  emails: [Email],
});

EmailModule.define({
  emails: [Email],
});

Account.define({
  emails: [Email],
});

EmailAccount.define({
  emails: [Email],
});

Mailing.define({
  letter: Letter,
  season: Season,
  jobs: [MailingJob],
  template: Template,
});

MailingJob.define({
  customerList: CustomerList,
  mailing: Mailing,
});

MailingModule.define({
  season: Season,
  location: Location,
  affiliate: Affiliate,
});

Signature.define({
  mailing: Mailing,
  location: Location,
  affiliate: Affiliate,
});

Sms.define({
  season: Season,
  nextJobs: [SmsJob],
  previousJobs: [SmsJob],
});

CustomerList.define({
  customerListCustomers: [CustomerListCustomer],
});

EmailJob.define({
  customerList: CustomerList,
  season: Season,
});

SmsJob.define({
  customerList: CustomerList,
  season: Season,
});

CustomerListCustomer.define({
  customer: Customer,
  car: Car,
  invoice: Invoice,
  children: [CustomerListCustomer],
  emailQueue: EmailQueue,
  smsQueue: SmsQueue,
  customerList: CustomerList,
});

Car.define({
  location: Location,
});

Invoice.define({
  car: Car,
  staff: Staff,
});

Log.define({
  staff: Staff,
});

export const Schemas = {
  AFFILIATE: Affiliate,
  AFFILIATES: [Affiliate],
  AFFILIATESETTING: AffliliateSetting,
  AFFILIATESETTINGS: [AffliliateSetting],
  BRAND: Brand,
  BRANDS: [Brand],
  CAMPAIGN: Campaign,
  CAMPAIGNS: [Campaign],
  CAMPAIGNCONFIGPARAM: CampaignConfigParam,
  CAMPAIGNCONFIGPARAMS: [CampaignConfigParam],
  CCSSCAN: CcsScan,
  CCSSCANS: [CcsScan],
  CURRENTUSER: CurrentUser,
  CUSTOMER: Customer,
  CUSTOMERLIST: CustomerList,
  CUSTOMERLISTCUSTOMER: CustomerListCustomer,
  CUSTOMERLISTCUSTOMERS: [CustomerListCustomer],
  DOWNLOAD: Download,
  FORM: Form,
  EMAILS: [Email],
  EMAIL: Email,
  EMAILTEMPLATES: [EmailTemplate],
  EMAILTEMPLATE: EmailTemplate,
  LAYOUTS: [Layout],
  LAYOUT: Layout,
  EMAILMODULES: [EmailModule],
  EMAILMODULE: EmailModule,
  ACCOUNTS: [Account],
  ACCOUNT: Account,
  EMAILACCOUNTS: [EmailAccount],
  EMAILACCOUNT: EmailAccount,
  EMAILQUEUE: EmailQueue,
  EMAILQUEUES: [EmailQueue],
  SMSQUEUE: SmsQueue,
  SMSQUEUES: [SmsQueue],
  LETTERS: [Letter],
  LETTER: Letter,
  LOCATION: Location,
  LOCATIONS: [Location],
  LOCATIONSETTING: LocationSetting,
  LOCATIONSETTINGS: [LocationSetting],
  MAILINGS: [Mailing],
  MAILINGMODULES: [MailingModule],
  MAILINGMODULE: MailingModule,
  MAILINGJOB: MailingJob,
  MAILINGJOBS: [MailingJob],
  MODULETAG: ModuleTag,
  MODULETAGS: [ModuleTag],
  OWNER: Owner,
  OWNERINTERFACE: OwnerInterface,
  REPRESENTATION: [Representation],
  ROLE: [Role],
  STAFF: Staff,
  STAFFS: [Staff],
  SIGNEE: Signee,
  SIGNEES: [Signee],
  SEASON: Season,
  SEASONS: [Season],
  SEASONCAUSALITY: SeasonCausality,
  SEASONCAUSALITIES: [SeasonCausality],
  SELECTION: Selection,
  SELECTIONS: [Selection],
  SETTING: Setting,
  SETTINGS: [Setting],
  SMS: Sms,
  SMSS: [Sms],
  TAGS: [Tag],
  TAG: Tag,
  UPLOAD: Upload,
  UPLOADS: [Upload],
  MENU: [MenuItemSchema],
  LOG: Log,
  LOGS: [Log],
  PRINTABLECOPYGROUP: PrintableCopyGroup,
  PRINTABLECOPYGROUPS: [PrintableCopyGroup],
  COPYGROUP: CopyGroup,
  COPYGROUPS: [CopyGroup],
  EMAILJOB: EmailJob,
  EMAILJOBS: [EmailJob],
  LOGIN: Login,
  PRODUCT: Product,
  CAMPAIGNPRODUCT: CampaignProduct,
  PRODUCTS: [Product],
  CAMPAIGNPRODUCTS: [CampaignProduct],
};

export const SearchSchemas = {
  EMAILMODULES: [EmailModuleResult],
  EMAILQUEUES: [EmailQueueResult],
  SMSQUEUES: [SmsQueueResult],
  PRODUCTS: [ProductResult],
  CARS: [CarResult],
  CAMPAIGNCONFIGPARAMS: [CampaignConfigParamResult],
};

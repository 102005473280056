import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ACL from '../../components/ACL';
import Edit from './components/Edit';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter } from '../../withRouter';
import { useAppDispatch } from '../../hooks';
import { writeEmailAccount } from './EmailAccountSlice';

type EmailAccountIndexProps = {
  requiredRoles?: string[];
};

function EmailAccountEdit({ requiredRoles = ['ROLE_SUPERADMIN'] }: EmailAccountIndexProps) {
  const { accountId = null } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (form: FormData) => {
    await dispatch(
      writeEmailAccount({
        data: form,
        id: accountId,
      }),
    );

    navigate(Routing.generate('emailaccount_index'));
  };

  const onTouchCancel = () => {
    navigate(Routing.generate('emailaccount_index'));
  };

  return (
    <ACL authorized={requiredRoles}>
      <ErrorBoundary>
        <Edit onSubmit={handleSubmit} accountId={accountId} onCancel={onTouchCancel} />
      </ErrorBoundary>
    </ACL>
  );
}

export default withRouter(EmailAccountEdit);

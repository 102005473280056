import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Translate from './service/Translate';
import { isEqual } from 'lodash';

class Download extends Component {
  static propTypes = {
    file: PropTypes.string.isRequired,
    onMount: PropTypes.func.isRequired,
    label: PropTypes.any,
    target: PropTypes.string,
    style: PropTypes.object,
  };

  static defaultProps = {
    target: '_blank',
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.file, this.props.file)
      || !isEqual(nextProps.label, this.props.label)
      || !isEqual(nextProps.target, this.props.target)
      || !isEqual(nextProps.style, this.props.style)
    );
  };

  handleMount = (link) => {
    this.link = link;
    this.props.onMount(link);
  };

  render() {
    const { target, label, file, style } = this.props;

    if (!file) {
      return null;
    }

    const path = Routing.generate('download', { file: file }, true);

    return (
      <a href={path} style={Object.assign({}, style)} ref={this.handleMount} target={target}>
        <Translate>{label}</Translate>
      </a>
    );
  }
}

export default Download;

const contactConditions = [
  {
    type: 'Contact',
    label: 'Hauptstandort: Terminvereinbarung-Online',
    description: 'Es wird abgefragt, ob ein Link für die Terminvereinbarung vorhanden ist.',
    before: '{% if owner.headquarter.appointmentWww %}',
    after: '{% endif %}',
  },
  {
    type: 'Contact',
    label: 'Hauptstandort: Terminvereinbarung-E-Mail',
    description: 'Es wird abgefragt, ob eine E-Mail-Adresse für die Terminvereinbarung vorhanden ist.',
    before: '{% if owner.headquarter.appointmentEmail %}',
    after: '{% endif %}',
  },
  {
    type: 'Contact',
    label: 'Hauptstandort: Terminvereinbarung-Telefon',
    description: 'Es wird abgefragt, ob eine Telefonnummer für die Terminvereinbarung vorhanden ist.',
    before: '{% if owner.headquarter.appointmentPhone %}',
    after: '{% endif %}',
  },
  {
    type: 'Contact',
    label: 'Hauptstandort: Terminvereinbarung-Telefon oder E-Mail',
    description: 'Es wird abgefragt ob eine Telefonnummer oder E-Mail-Adresse zur Terminvereinbarung vorhanden ist.',
    before: '{% if owner.headquarter.appointmentPhone or owner.headquarter.appointmentEmail %}',
    after: '{% endif %}',
  },
  {
    type: 'Contact',
    label: 'Mehrere Standorte',
    description: 'Prüft ob, es mehr als einen Standort gibt.',
    before: '{% if owner.locations|length > 1 %} ',
    after: '{% endif %}',
  },

  {
    type: 'Contact',
    label: 'ausgewählter Standort: Terminvereinbarung-Online',
    description:
      'Es wird abgefragt ob, ein Link für die Terminvereinbarung vorhanden ist. (Hinweis: location muss vorab definiert werden.)',
    before: '{% if location.appointmentWww %}',
    after: '{% endif %}',
  },
  {
    type: 'Contact',
    label: 'ausgewählter Standort: Terminvereinbarung-E-Mail',
    description:
      'Es wird abgefragt, ob eine E-Mail-Adresse für die Terminvereinbarung vorhanden ist. (Hinweis: location muss vorab definiert werden.)',
    before: '{% if location.appointmentEmail %}',
    after: '{% endif %}',
  },
  {
    type: 'Contact',
    label: 'ausgewählter Standort: Terminvereinbarung-Telefon',
    description:
      'Es wird abgefragt ob, eine Telefonnummer für die Terminvereinbarung vorhanden ist. (Hinweis: location muss vorab definiert werden.)',
    before: '{% if location.appointmentPhone %}',
    after: '{% endif %}',
  },
  {
    type: 'Contact',
    label: 'ausgewählter Standort: Terminvereinbarung-Telefon oder E-Mail',
    description:
      'Es wird abgefragt ob eine Telefonnummer oder E-Mail-Adresse zur Terminvereinbarung vorhanden ist. (Hinweis: location muss vorab definiert werden.)',
    before: '{% if location.appointmentPhone or location.appointmentEmail  %}',
    after: '{% endif %}',
  },
];

export default contactConditions;

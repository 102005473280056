import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { App } from './AppType';
import { Staff } from '../Staff/StaffType';
import { SeedsModeEnum } from '../../utils/Enums';
import { fetchAuthentication } from '../Security/SecuritySlice';

const initialState: App = {};

export const fetchAppConfig = createAsyncThunk('app/config', async () => {
  const response = await axios.get<{
    user: Staff;
    roles: string[];
    seedsMode: SeedsModeEnum;
  }>(Routing.generate('app_config', null, true));

  return {
    statusCode: response.status,
    authenticatedUser: response.data.user,
    seedsMode: response.data.seedsMode,
    roles: response.data.roles,
  };
});

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAuthentication.fulfilled, (state, action) => {
      const { authenticatedUser, seedsMode, authenticatedUserRoles } = action.payload;

      state.authenticatedUser = authenticatedUser;
      state.seedsMode = seedsMode;
      state.authenticatedUserRoles = authenticatedUserRoles;
    });
    builder.addCase(fetchAuthentication.rejected, (state) => {
      state.authenticatedUser = undefined;
      state.seedsMode = undefined;
      state.authenticatedUserRoles = undefined;
    });
    builder.addCase(fetchAppConfig.fulfilled, (state, action) => {
      const { authenticatedUser, seedsMode, roles } = action.payload;

      state.authenticatedUser = authenticatedUser;
      state.seedsMode = seedsMode;
      state.authenticatedUserRoles = roles;
    });
  },
});

export default appSlice.reducer;

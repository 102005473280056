import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import List from './List';
import { SeedsModeEnum } from '../../utils/Enums';
import { connect } from 'react-redux';

class LocationIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_ADMIN'],
    requiredRolesMbvd: ['ROLE_SUPERADMIN'],
    resultset: [],
  };

  render() {
    const { inMbvdMode, requiredRolesMbvd, requiredRoles } = this.props;

    return (
      <ACL authorized={inMbvdMode ? requiredRolesMbvd : requiredRoles}>
        <List showLocationSetting={true} />
      </ACL>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    app: { seedsMode },
  } = state;

  return {
    inMbvdMode: seedsMode === SeedsModeEnum.MBVD,
  };
};

export default connect(mapStateToProps, {})(LocationIndex);

import React from 'react';
import { Box, TableCell } from '@mui/material';
import { calcRateToString, calcRate } from '../../utils';
import { BorderLinearProgress } from '../../../../components/StyledElements/StyledElements';

type ReportTableCellProps = {
  count: number;
  total: number;
};

export default function ReportTableCell({ count, total }: ReportTableCellProps) {
  return (
    <TableCell>
      <Box display="flex" flexDirection="row" alignItems="center" justifyContent="flex-end">
        <Box width={1 / 2} marginRight={1}>
          <BorderLinearProgress variant="determinate" value={calcRate(count, total)} />
        </Box>
        <Box minWidth={1 / 2} display="flex" alignItems="center" justifyContent="flex-end">
          {calcRateToString(count, total)} ({count.toLocaleString('de-DE')})
        </Box>
      </Box>
    </TableCell>
  );
}

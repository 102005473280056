import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';

export const AFFILIATE_SETTINGS_REQUEST = 'AFFILIATE_SETTINGS_REQUEST';
export const AFFILIATE_SETTINGS_SUCCESS = 'AFFILIATE_SETTINGS_SUCCESS';
export const AFFILIATE_SETTINGS_FAILURE = 'AFFILIATE_SETTINGS_FAILURE';

function fetchAffiliateSettings(sortColumns, sortOrders, offset, limit, join, filter, affiliateSettingId) {
  return {
    [CALL_API]: {
      types: [AFFILIATE_SETTINGS_REQUEST, AFFILIATE_SETTINGS_SUCCESS, AFFILIATE_SETTINGS_FAILURE],
      endpoint: Routing.generate(
        'affiliate_setting_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
          affiliateSettingId: affiliateSettingId,
        },
        true,
      ),
      schema: Schemas.AFFILIATESETTINGS,
    },
  };
}

// eslint-disable-next-line no-unused-vars
export function loadAffiliateSettings(
  sortColumns = [],
  sortOrders = [],
  join = [],
  filter = [],
  affiliateSettingId = null,
  requiredFields = [],
) {
  return (dispatch) => {
    let offset = null;
    let limit = null;

    return dispatch(fetchAffiliateSettings(sortColumns, sortOrders, offset, limit, join, filter, affiliateSettingId));
  };
}

export const AFFILIATESETTING_PAGED_REQUEST = 'AFFILIATESETTING_PAGED_REQUEST';
export const AFFILIATESETTING_PAGED_SUCCESS = 'AFFILIATESETTING_PAGED_SUCCESS';
export const AFFILIATESETTING_PAGED_FAILURE = 'AFFILIATESETTING_PAGED_FAILURE';

function fetchAffiliateSettingPaged(affiliateId, sortColumns, sortOrders, offset = null, limit = null, join, filter) {
  return {
    [CALL_API]: {
      types: [AFFILIATESETTING_PAGED_REQUEST, AFFILIATESETTING_PAGED_SUCCESS, AFFILIATESETTING_PAGED_FAILURE],
      endpoint: Routing.generate(
        'affiliate_setting_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
          affiliate: affiliateId,
        },
        true,
      ),
      schema: Schemas.AFFILIATESETTINGS,
    },
  };
}

export function loadNext(
  affiliateId,
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
) {
  return (dispatch, getState) => {
    const affiliatesetting = getState().entities.affiliatesetting;
    const pagination =
      affiliatesetting && getState().pagination.affiliatesetting ? getState().pagination.affiliatesetting : false;

    if (!affiliatesetting || !pagination) {
      return null;
    }

    return dispatch(
      fetchAffiliateSettingPaged(
        affiliateId,
        sortColumns,
        sortOrders,
        offset === 0 ? offset : pagination.next,
        limit ? limit : pagination.limit,
        join,
        filter,
      ),
    );
  };
}

export const SETTING_TYPE_REQUEST = 'SETTING_TYPE_REQUEST';
export const SETTING_TYPE_SUCCESS = 'SETTING_TYPE_SUCCESS';
export const SETTING_TYPE_FAILURE = 'SETTING_TYPE_FAILURE';

function fetchSettings() {
  return {
    [CALL_API]: {
      types: [SETTING_TYPE_REQUEST, SETTING_TYPE_SUCCESS, SETTING_TYPE_FAILURE],
      endpoint: Routing.generate('setting_index', {}, true),
      schema: Schemas.SETTINGS,
    },
  };
}

export function loadSettings() {
  return (dispatch) => {
    return dispatch(fetchSettings());
  };
}

export const AFFILIATESETTING_UPDATE_REQUEST = 'AFFILIATESETTING_UPDATE_REQUEST';
export const AFFILIATESETTING_UPDATE_SUCCESS = 'AFFILIATESETTING_UPDATE_SUCCESS';
export const AFFILIATESETTING_UPDATE_FAILURE = 'AFFILIATESETTING_UPDATE_FAILURE';

function writeAffiliateSetting(form, settingId) {
  return {
    [CALL_API]: {
      types: [AFFILIATESETTING_UPDATE_REQUEST, AFFILIATESETTING_UPDATE_SUCCESS, AFFILIATESETTING_UPDATE_FAILURE],
      endpoint: Routing.generate('affiliate_setting_update', { id: settingId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.AFFILIATESETTING,
    },
  };
}

export function updateAffiliateSetting(form, settingId = null) {
  return (dispatch) => {
    return dispatch(writeAffiliateSetting(form, settingId));
  };
}

export const AFFILIATESETTING_REMOVE_REQUEST = 'AFFILIATESETTING_REMOVE_REQUEST';
export const AFFILIATESETTING_REMOVE_SUCCESS = 'AFFILIATESETTING_REMOVE_SUCCESS';
export const AFFILIATESETTING_REMOVE_FAILURE = 'AFFILIATESETTING_REMOVE_FAILURE';

function deleteAffiliateSetting(id, deleteToken) {
  return {
    [CALL_API]: {
      types: [AFFILIATESETTING_REMOVE_REQUEST, AFFILIATESETTING_REMOVE_SUCCESS, AFFILIATESETTING_REMOVE_FAILURE],
      endpoint: Routing.generate('affiliate_setting_delete', { id: id, deleteToken: deleteToken }, true),
      //schema: Schemas.AFFILIATESETTINGS,
      method: 'DELETE',
    },
  };
}

export function removeAffiliateSetting(settingId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteAffiliateSetting(settingId, deleteToken));
  };
}

export const AFFILIATESETTING_FORM_REQUEST = 'AFFILIATESETTING_FORM_REQUEST';
export const AFFILIATESETTING_FORM_SUCCESS = 'AFFILIATESETTING_FORM_SUCCESS';
export const AFFILIATESETTING_FORM_FAILURE = 'AFFILIATESETTING_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [AFFILIATESETTING_FORM_REQUEST, AFFILIATESETTING_FORM_SUCCESS, AFFILIATESETTING_FORM_FAILURE],
      endpoint: Routing.generate('affiliate_setting_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

import React from 'react';
import { Navigate } from 'react-router-dom';
import SecurityLayout from './SecurityLayout';
import Login from './Login';
import { useAppSelector } from '../../hooks';
import owl from '../../theme/images/login/eeloy-owl-hello.svg';

export default function LoginIndex() {
  const { isAuthenticated } = useAppSelector((state) => state.security);

  return isAuthenticated ? (
    <Navigate to="/" />
  ) : (
    <SecurityLayout img={owl}>
      <Login />
    </SecurityLayout>
  );
}

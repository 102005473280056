const customerConditions = [
  {
    type: 'General',
    label: 'Firma',
    description: 'Fragt ab ob, es sich beim Kunden um einen Firmenkunden handelt.',
    before: '{% if customer.isCompany %}',
    after: '{% endif %}',
  },
  {
    type: 'General',
    label: 'Privatkunde',
    description: 'Fragt ab ob, es sich beim Kunden um eine Privatkundin oder einen Privatkunden handelt.',
    before: '{% if not customer.isCompany %}',
    after: '{% endif %}',
  },
  {
    type: 'Cover',
    label: 'PKW Frau',
    description: 'KA Reifen Cover Abfrage - Pkw Frau',
    before: '{% if car.coverTag == "pc_mb_female" %}',
    after: '{% endif %}',
  },
  {
    type: 'Cover',
    label: 'PKW Mann',
    description: 'KA Reifen Cover Abfrage - Pkw Mann',
    before: '{% if car.coverTag == "pc_mb_male" %}',
    after: '{% endif %}',
  },
  {
    type: 'Cover',
    label: 'PKW geschäftlich',
    description: 'KA Reifen Cover Abfrage - Pkw geschäftlich',
    before: '{% if car.coverTag == "pc_mb_business" %}',
    after: '{% endif %}',
  },
  {
    type: 'Cover',
    label: 'smart',
    description: 'KA Reifen Cover Abfrage - smart',
    before: '{% if car.coverTag == "smart" %}',
    after: '{% endif %}',
  },
  {
    type: 'Cover',
    label: 'Transpoter privat',
    description: 'KA Reifen Cover Abfrage - Transpoter privat (Mann, Frau)',
    before: '{% if car.coverTag == "trapo_private" %}',
    after: '{% endif %}',
  },
  {
    type: 'Cover',
    label: 'Transpoter geschäftlich',
    description: 'KA Reifen Cover Abfrage - Transpoter geschäftlich',
    before: '{% if car.coverTag == "trapo_business" %}',
    after: '{% endif %}',
  },
];

export default customerConditions;

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const LAYOUTS_REQUEST = 'LAYOUTS_REQUEST';
export const LAYOUTS_SUCCESS = 'LAYOUTS_SUCCESS';
export const LAYOUTS_FAILURE = 'LAYOUTS_FAILURE';

function fetchLayouts(sortColumns, sortOrders, offset = null, limit = null, join, filter) {
  return {
    [CALL_API]: {
      types: [LAYOUTS_REQUEST, LAYOUTS_SUCCESS, LAYOUTS_FAILURE],
      endpoint: Routing.generate(
        'emaillayout_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.LAYOUTS,
    },
  };
}

export function loadLayouts(sortColumns = [], sortOrders = [], requiredFields = [], join = [], filter = []) {
  return (dispatch, getState) => {
    const layout = getState().entities.layout;
    const resultset = getState().resultsets.layout;
    const outdated = getState().outdated.layout;

    let offset = null;
    let limit = null;

    if (
      !outdated
      && resultset
      && resultset.length
      && layout
      && requiredFields.every((key) => sample(layout).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchLayouts(sortColumns, sortOrders, offset, limit, join, filter));
  };
}

export const LAYOUT_REQUEST = 'LAYOUT_REQUEST';
export const LAYOUT_SUCCESS = 'LAYOUT_SUCCESS';
export const LAYOUT_FAILURE = 'LAYOUT_FAILURE';

function fetchLayout(id) {
  return {
    [CALL_API]: {
      types: [LAYOUT_REQUEST, LAYOUT_SUCCESS, LAYOUT_FAILURE],
      endpoint: Routing.generate('emaillayout_show', { id: id }, true),
      schema: Schemas.LAYOUT,
    },
  };
}

export function loadLayout(id, requiredFields = []) {
  return (dispatch, getState) => {
    const layouts = getState().entities.layout;

    if (layouts && layouts[id] && requiredFields.every((key) => layouts[id].hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchLayout(id));
  };
}

export const LAYOUTS_PAGED_REQUEST = 'LAYOUTS_PAGED_REQUEST';
export const LAYOUTS_PAGED_SUCCESS = 'LAYOUTS_PAGED_SUCCESS';
export const LAYOUTS_PAGED_FAILURE = 'LAYOUTS_PAGED_FAILURE';

function fetchLayoutsPaged(sortColumns, sortOrders, offset = null, limit = null, join, filter) {
  return {
    [CALL_API]: {
      types: [LAYOUTS_PAGED_REQUEST, LAYOUTS_PAGED_SUCCESS, LAYOUTS_PAGED_FAILURE],
      endpoint: Routing.generate(
        'emaillayout_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.LAYOUTS,
    },
  };
}

export function loadNext(sortColumns = [], sortOrders = [], offset = null, limit = null, join = [], filter = []) {
  return (dispatch, getState) => {
    const layout = getState().entities.layout;
    const pagination = layout && getState().pagination.layout ? getState().pagination.layout : false;

    if (!layout || !pagination) {
      return null;
    }

    return dispatch(fetchLayoutsPaged(sortColumns, sortOrders, offset, limit, join, filter));
  };
}

export const LAYOUT_UPDATE_REQUEST = 'LAYOUT_UPDATE_REQUEST';
export const LAYOUT_UPDATE_SUCCESS = 'LAYOUT_UPDATE_SUCCESS';
export const LAYOUT_UPDATE_FAILURE = 'LAYOUT_UPDATE_FAILURE';

function writeLayout(form, layoutId) {
  return {
    [CALL_API]: {
      types: [LAYOUT_UPDATE_REQUEST, LAYOUT_UPDATE_SUCCESS, LAYOUT_UPDATE_FAILURE],
      endpoint: Routing.generate('emaillayout_update', { id: layoutId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.LAYOUT,
    },
  };
}

export function updateLayout(form, layoutId = null) {
  return (dispatch) => {
    return dispatch(writeLayout(form, layoutId));
  };
}

export const LAYOUT_DELETE_REQUEST = 'LAYOUT_DELETE_REQUEST';
export const LAYOUT_DELETE_SUCCESS = 'LAYOUT_DELETE_SUCCESS';
export const LAYOUT_DELETE_FAILURE = 'LAYOUT_DELETE_FAILURE';

function deleteLayout(layoutId, deleteToken) {
  return {
    [CALL_API]: {
      types: [LAYOUT_DELETE_REQUEST, LAYOUT_DELETE_SUCCESS, LAYOUT_DELETE_FAILURE],
      endpoint: Routing.generate('emaillayout_delete', { id: layoutId, deleteToken: deleteToken }, true),
      method: 'DELETE',
    },
  };
}

export function removeLayout(layoutId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteLayout(layoutId, deleteToken));
  };
}

export const LAYOUT_FORM_REQUEST = 'LAYOUT_FORM_REQUEST';
export const LAYOUT_FORM_SUCCESS = 'LAYOUT_FORM_SUCCESS';
export const LAYOUT_FORM_FAILURE = 'LAYOUT_FORM_FAILURE';

function fetchForm() {
  return {
    [CALL_API]: {
      types: [LAYOUT_FORM_REQUEST, LAYOUT_FORM_SUCCESS, LAYOUT_FORM_FAILURE],
      endpoint: Routing.generate('emaillayout_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

import React from 'react';
import SecurityLayout from './SecurityLayout';
import owl from '../../theme/images/error/owl.svg';
import RecoverPassword from './RecoverPassword';

export default function RecoverIndex() {
  return (
    <SecurityLayout img={owl}>
      <RecoverPassword />
    </SecurityLayout>
  );
}

import React from 'react';
import { Alert, AlertColor, Snackbar } from '@mui/material';
import { Action } from 'redux';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { resetErrorMessage, resetInfoMessage, resetWarningMessage } from '../../actions';
import Translate from '../service/Translate';

type MessageSnackbarProps = {
  message: string;
  resetMessage: () => Action;
  severity: AlertColor;
};

function MessageSnackbar({ message, resetMessage, severity }: MessageSnackbarProps) {
  const dispatch = useAppDispatch();
  const onClose = () => dispatch(resetMessage());

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      open={!!message}
      onClose={onClose}
      autoHideDuration={6500}
      key="bottomcenter"
    >
      <Alert severity={severity} variant="filled">
        <Translate>{message}</Translate>
      </Alert>
    </Snackbar>
  );
}

function Message() {
  const infoMessage = useAppSelector((state) => state.infoMessage);
  const errorMessage = useAppSelector((state) => state.errorMessage);
  const warningMessage = useAppSelector((state) => state.warningMessage);

  return (
    <>
      {infoMessage && <MessageSnackbar message={infoMessage} resetMessage={resetInfoMessage} severity="success" />}
      {errorMessage && <MessageSnackbar message={errorMessage} resetMessage={resetErrorMessage} severity="error" />}
      {warningMessage && (
        <MessageSnackbar message={warningMessage} resetMessage={resetWarningMessage} severity="warning" />
      )}
    </>
  );
}

export default Message;

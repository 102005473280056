import React from 'react';
import { useTheme } from '@mui/material';

/** @deprecated Use `Redux hooks / Redux Toolkit` instead */
export type WithThemeProps = {
  theme: ReturnType<typeof useTheme>;
};

/** @deprecated Use `Redux hooks / Redux Toolkit` instead */
export const withTheme = <Props extends WithThemeProps>(Component: React.ComponentType<Props>) => {
  function withThemeOut(props: Omit<Props, keyof WithThemeProps>) {
    const theme = useTheme();

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Component {...(props as Props)} theme={theme} />;
  }
  return withThemeOut;
};

import { Checkbox, FormControlLabel } from '@mui/material';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import React, { useCallback } from 'react';
import { ErrorMessage } from 'formik';

export const FormCheckbox = (props) => {
  const { name, label, error, setFieldValue, checked, disabled } = props;

  const handleChange = useCallback(
    (e) => {
      setFieldValue(name, e.target.checked);
    },
    [setFieldValue, name],
  );

  return (
    <FormControl error={error}>
      <FormControlLabel
        control={<Checkbox name={name} onChange={handleChange} checked={checked} color="primary" disabled={disabled} />}
        label={label}
        labelPlacement="end"
      />
      <FormHelperText>
        <ErrorMessage name={name} />
      </FormHelperText>
    </FormControl>
  );
};

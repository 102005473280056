import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import Header from './Header';
import KeyIndicator from './KeyIndicator';
import { fetchKeyIndicators } from '../../StatisticCampaignSlice';
import { toPercentageString } from '../../utils';

type KeyIndicatorsProps = {
  campaignId: number;
};

export default function KeyIndicators({ campaignId }: KeyIndicatorsProps) {
  const dispatch = useAppDispatch();
  const { extract, dispatched, opened, title } = useAppSelector((state) => state.statisticCampaign.keyIndicator);
  const [loading, setLoading] = useState(false);
  const dispatchedAt =
    extract.dispatchedAt !== '-'
      ? new Date().toLocaleString('de-DE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : '-';
  const lastUpdated =
    extract.lastUpdated !== ''
      ? new Date().toLocaleString('de-DE', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        })
      : '-';

  useEffect(() => {
    setLoading(true);
    dispatch(fetchKeyIndicators({ id: campaignId })).then(() => setLoading(false));
  }, []);

  return (
    <>
      {loading ? <Skeleton variant="text" width="100%" height="64px" /> : <Header title={title} />}
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, md: 4 }}>
          {loading ? (
            <Skeleton variant="rounded" width="100%" height="120px" sx={{ mb: 3, mt: 3 }} />
          ) : (
            <KeyIndicator
              icon={<ContentPasteIcon />}
              title="Auftrag"
              value={extract.crmId}
              subheader1={`letzter Aussand: ${dispatchedAt}`}
              subheader2={`letzte Aktualisierung: ${lastUpdated}`}
            />
          )}
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          {loading ? (
            <Skeleton variant="rounded" width="100%" height="120px" sx={{ mb: 3, mt: 3 }} />
          ) : (
            <KeyIndicator
              icon={<EmailIcon />}
              title="Empfänger"
              value={dispatched.sent.toLocaleString('de-DE')}
              subheader1={`${dispatched.sentToInDepot.toLocaleString('de-DE')} Einlagerer`}
              subheader2={`${dispatched.sentToNotInDepot.toLocaleString('de-DE')} Nichteinlagerer`}
            />
          )}
        </Grid>
        <Grid size={{ xs: 12, md: 4 }}>
          {loading ? (
            <Skeleton variant="rounded" width="100%" height="120px" sx={{ mb: 3, mt: 3 }} />
          ) : (
            <KeyIndicator
              icon={<CheckCircleIcon />}
              title="E-Mail-Öffnungen"
              value={toPercentageString(opened.openedRate)}
              subheader1={`Landingpage-Öffnungen: ${toPercentageString(opened.clickRate)}`}
              subheader2={`Zustellungen: ${toPercentageString(opened.deliveredRate)}`}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

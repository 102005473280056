import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const MAILINGS_REQUEST = 'MAILINGS_REQUEST';
export const MAILINGS_SUCCESS = 'MAILINGS_SUCCESS';
export const MAILINGS_FAILURE = 'MAILINGS_FAILURE';

function fetchMailings(autoselect = null) {
  return {
    [CALL_API]: {
      types: [MAILINGS_REQUEST, MAILINGS_SUCCESS, MAILINGS_FAILURE],
      endpoint: Routing.generate('mailing_index', autoselect !== null ? { autoselect: autoselect } : null, true),
      schema: Schemas.MAILINGS,
    },
  };
}

export function loadMailings(requiredFields = []) {
  return (dispatch, getState) => {
    const mailings = getState().entities.mailing;
    const outdated = getState().outdated.mailing;

    if (!outdated && mailings && requiredFields.every((key) => sample(mailings).hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchMailings());
  };
}

export function loadAutoSelectMailings(requiredFields = []) {
  return (dispatch, getState) => {
    const mailings = getState().entities.mailing;
    const outdated = getState().outdated.mailing;

    if (!outdated && mailings && requiredFields.every((key) => sample(mailings).hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchMailings(1));
  };
}

export const SEASONJOBS_REQUEST = 'SEASONJOBS_REQUEST';
export const SEASONJOBS_SUCCESS = 'SEASONJOBS_SUCCESS';
export const SEASONJOBS_FAILURE = 'SEASONJOBS_FAILURE';

function fetchJobs(seasonId, min, max, showCompleted) {
  return {
    [CALL_API]: {
      types: [SEASONJOBS_REQUEST, SEASONJOBS_SUCCESS, SEASONJOBS_FAILURE],
      endpoint: Routing.generate(
        'season_jobmenu',
        { id: seasonId, min: min, max: max, showCompleted: showCompleted },
        true,
      ),
      schema: Schemas.SEASON,
    },
  };
}

export function loadJobs(seasonId, min, max, showCompleted, requiredFields = []) {
  return (dispatch, getState) => {
    const mailingJobs = getState().entities.mailingJob;
    const seasons = getState().entities.season;

    if (seasons[seasonId] && seasons[seasonId].mailingJobs) {
      const jobId = sample(seasons[seasonId].mailingJobs);
      if (jobId && mailingJobs[jobId] && requiredFields.every((key) => mailingJobs[jobId].hasOwnProperty(key))) {
        return null;
      }
    }

    return dispatch(fetchJobs(seasonId, min, max, showCompleted));
  };
}

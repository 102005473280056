import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import Translate from '../../components/service/Translate';
import { loadExtract } from './actions';

class Tag extends Component {
  static propTypes = {
    id: PropTypes.number.isRequired,
    loadExtract: PropTypes.func.isRequired,
    staff: PropTypes.object,
  };

  componentDidMount = () => {
    this.props.loadExtract(this.props.id, ['primaryText']);
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.id, this.props.id)) {
      this.props.loadExtract(this.props.id, ['primaryText']);
    }
  }

  shouldComponentUpdate = (nextProps) => {
    return !isEqual(nextProps, this.props.id) || !isEqual(nextProps.staff, this.props.staff);
  };

  render() {
    const { staff } = this.props;

    if (!staff) {
      return null;
    }

    return (
      <span>
        <Translate>{staff.primaryText}</Translate>
        {staff.agentCode ? ` (${staff.agentCode})` : ''}
      </span>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const staffId = ownProps.id;

  const {
    entities: { staff },
  } = state;

  if (staff && staffId && staff[staffId]) {
    return { staff: staff[staffId] };
  }

  return {
    staff: null,
  };
}

export default connect(mapStateToProps, { loadExtract })(Tag);

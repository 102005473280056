import * as Yup from 'yup';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ErrorMessage, FormikProps, FormikValues } from 'formik';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
import { Alert } from '@mui/material';
import Translate from '../../components/service/Translate';
import { useAppDispatch, useAppSelector } from '../../hooks';
import testPassword from '../../utils/Security';
import ValidationErrors from '../../ValidationErrors';
import { resetPassword } from './SecuritySlice';
import Form from '../../components/Form';

type ResetPasswordValues = {
  password: string;
  confirm_password: string;
};

const initialResetPasswordValues: ResetPasswordValues = {
  password: '',
  confirm_password: '',
};

const validationResetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, ValidationErrors.passwordRule)
    .test('pwValidation', ValidationErrors.passwordRule, testPassword),
  confirm_password: Yup.string().when('password', {
    is: (val: string | undefined): boolean => !!(val && val.length > 0),
    then: () =>
      Yup.string()
        .oneOf([Yup.ref('password')], ValidationErrors.requiredPasswordConfirm)
        .required(ValidationErrors.required),
  }),
});

function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { info, outdated } = useAppSelector((state) => state.security);
  const { resetKey } = useParams();

  const renderFieldset = ({
    values,
    handleChange,
    handleBlur,
    touched,
    errors,
    submitForm,
    isSubmitting,
  }: FormikProps<FormikValues>) => (
    <>
      <Stack spacing={3}>
        <Typography variant="body1" color="text.primary" sx={{ mb: 2 }}>
          <Translate>You have to set a new password.</Translate>
        </Typography>
        <TextField
          autoFocus
          error={!!(touched.password && errors.password)}
          fullWidth
          helperText={<ErrorMessage name="password" />}
          label={<Translate>Password</Translate>}
          name="password"
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          value={values.password}
        />
        <TextField
          error={!!(touched.confirm_password && errors.confirm_password)}
          fullWidth
          helperText={<ErrorMessage name="confirm_password" />}
          label={<Translate>Confirm password</Translate>}
          name="confirm_password"
          onChange={handleChange}
          onBlur={handleBlur}
          type="password"
          value={values.confirm_password}
        />
        {info && !isSubmitting && (
          <Alert severity="error">
            <Translate>{info}</Translate>
          </Alert>
        )}
        <Alert severity="info">
          <Translate>
            Please do not pass on your access data to third parties and keep them under lock and key. We recommend the
            use of so-called password safes. Do not use simple, easy-to-guess combinations for the password.
          </Translate>
        </Alert>
      </Stack>
      <Button
        fullWidth
        sx={{ mt: 3 }}
        size="large"
        type="submit"
        variant="contained"
        onClick={submitForm}
        disabled={isSubmitting}
        loading={isSubmitting}
      >
        <Translate>Change password</Translate>
      </Button>
    </>
  );

  const handleSubmit = async (data: FormData) => {
    const response = await dispatch(resetPassword({ data, resetKey })).unwrap();

    if (response.passwordUpdated || response.expired) {
      navigate('/login');
    }
  };

  return !resetKey ? (
    <Navigate to="/" />
  ) : (
    <>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5">
          {outdated && <Translate>Password expired</Translate>}
          {!outdated && <Translate>Renew password</Translate>}
        </Typography>
      </Box>
      <Form
        disableToolbar
        disableMargin
        initialValues={initialResetPasswordValues}
        validationSchema={validationResetPasswordSchema}
        onSubmit={handleSubmit}
        renderFieldset={renderFieldset}
      />
    </>
  );
}

export default ResetPassword;

const Variables = {
  webversion: {
    name: 'Webversion',
    sample: 'Falls die E-Mail nicht korrekt angezeigt wird, im Browser öffnen.',
    value: '<a href="{{ webversion }}">Falls die E-Mail nicht korrekt angezeigt wird, im Browser öffnen.</a>',
  },
  customer: {
    name: 'Kunde',
    mergeTags: {
      salutation: {
        name: 'Anrede',
        mergeTags: {
          salutation: {
            name: 'Anrede',
            sample: 'Frau',
            value: '{{customer.gender|trans}}',
          },
          letter_salutation: {
            name: 'Briefanrede',
            sample: 'Sehr geehrte Frau Mustermann',
            value: '{{customer.salutationLetter}}',
          },
          title: {
            name: 'Titel',
            sample: 'Dr.',
            value: '{{customer.title}}',
          },
          first_name: {
            name: 'Vorname',
            sample: 'Maria',
            value: '{{customer.firstname}}',
          },
          name_2: {
            name: 'Name 2',
            sample: 'Mara',
            value: '{{customer.name2}}',
          },
          last_name: {
            name: 'Nachname',
            sample: 'Mustermann',
            value: '{{customer.name}}',
          },
          dms_customernumber: {
            name: 'DMS Kundennummer',
            sample: '999999',
            value: '{{customer.customerNumber}}',
          },
        },
      },
      company: {
        name: 'Firma',
        mergeTags: {
          company: {
            name: 'Firma',
            sample: 'Erika Mustermann AG',
            value: '{{customer.company}}',
          },
          contact: {
            name: 'Ansprechpartner',
            sample: 'Max Mustermann',
            value: '{{customer.contact}}',
          },
          driver: {
            name: 'Fahrer',
            sample: 'Erik Flott',
            value: '{{car.driver}}',
          },
        },
      },
      address: {
        name: 'Adresse',
        mergeTags: {
          street: {
            name: 'Straße',
            sample: 'Musterstr. 9',
            value: '{{customer.address}}',
          },
          zip: {
            name: 'PLZ',
            sample: '99999',
            value: '{{customer.zip}}',
          },
          city: {
            name: 'Ort',
            sample: 'Musterstadt',
            value: '{{customer.city}}',
          },
          land: {
            name: 'Land',
            mergeTags: {
              written_out: {
                name: 'Ausgeschrieben',
                sample: 'Deutschland',
                value: '{{customer.country}}',
              },
              code: {
                name: 'Code',
                sample: 'DE',
                value: '{{customer.countryCode}}',
              },
            },
          },
        },
      },
      birthday: {
        name: 'Geburtstag',
        mergeTags: {
          tt_month: {
            name: 'tt. Monat',
            sample: '05. Januar',
            value: '{{customer.birthday|lcDate("dd. MMMM")}}',
          },
          tt_mm: {
            name: 'tt.mm',
            sample: '05.01.',
            value: '{{customer.birthday|lcDate("dd.MM.")}}',
          },
        },
      },
      id: {
        name: 'Kunden-ID',
        sample: '999999',
        value: '{{customer.id}}',
      },
    },
  },
  car: {
    name: 'Fahrzeug',
    mergeTags: {
      type: {
        name: 'Baureihe',
        sample: 'E-Klasse',
        value: '{{car.type}}',
      },
      model: {
        name: 'Modell',
        sample: 'E 320 T CDI',
        value: '{{car.model}}',
      },
      registration_date: {
        name: 'Erstzulassung',
        mergeTags: {
          dd_mm_jjjj: {
            name: 'dd.mm.jjjj',
            sample: '01.01.2019',
            value: '{{car.registration_date|lcDate("dd.MM.yyyy")}}',
          },
          dd_mm_jjjj_current_year: {
            name: 'dd.mm.jjjj (aktuelles Jahr)',
            sample: '01.01.2021',
            value: '{{car.registrationDate|date_modify("+1 year")|lcDate("dd.MM.yyyy")}}',
          },
        },
      },
      location: {
        name: 'Standort',
        mergeTags: {
          appointmentWWW: {
            name: 'Terminvereinbarung Online',
            sample: 'www.example.com',
            value: '{{car.location.appointmentWWW}}',
          },
          appointmentPhone: {
            name: 'Terminvereinbarung Telefon',
            sample: '9999 9999999',
            value: '{{car.location.appointmentPhone}}',
          },
          appointmentEmail: {
            name: 'Terminvereinbarung E-Mail',
            sample: 'termin@example.com',
            value: '{{car.location.appointmentEmail}}',
          },
        },
      },
      color: {
        name: 'Farbe',
        sample: 'Grau',
        value: '{{car.color}}',
      },
      id: {
        name: 'Fahrzeug-ID',
        sample: '1',
        value: '{{car.id}}',
      },
      ident: {
        name: 'Fahrzeugidentnummer',
        sample: 'WDB999999',
        value: '{{car.ident}}',
      },
      classification: {
        name: 'Fahrzeugtyp',
        sample: 'PKW',
        value: '{{car.classification}}',
      },
      au: {
        name: 'Fälligkeit AU',
        sample: 'Januar 2026',
        value: '{{car.au|lcDate("MMMM yyyy")}}',
      },
      hu: {
        name: 'Fälligkeit HU',
        sample: 'Januar 2026',
        value: '{{car.hu|lcDate("MMMM yyyy")}}',
      },
      nextService: {
        name: 'Fälligkeit Jahreswartung',
        sample: 'Januar 2026',
        value: '{{car.nextService|lcDate("MMMM yyyy")}}',
      },
      sp: {
        name: 'Fälligkeit SP',
        sample: 'Januar 2026',
        value: '{{car.sp|lcDate("MMMM yyyy")}}',
      },
      tu: {
        name: 'Fälligkeit TU',
        sample: 'Januar 2026',
        value: '{{car.tu|lcDate("MMMM yyyy")}}',
      },
      tp: {
        name: 'Fälligkeit FSP',
        sample: 'Januar 2026',
        value: '{{car.tp|lcDate("MMMM yyyy")}}',
      },
      warranty_end: {
        name: 'Garantieende',
        sample: '27.01.2026',
        value: '{{car.warranty_end|lcDate("dd.MM.yyyy")}}',
      },
      brand: {
        name: 'Hersteller',
        sample: 'Mercedes',
        value: '{{car.brand}}',
      },
      ccm: {
        name: 'Hubraum',
        sample: '1100',
        value: '{{car.ccm}}',
      },
      licenseNumber: {
        name: 'Kennzeichen',
        sample: 'XX-XX-9999',
        value: '{{car.licenseNumber|avoidbreak}}',
      },
      mileage: {
        name: 'Kilometerlaufleistung',
        sample: '12345',
        value: '{{car.mileage}}',
      },
      kw: {
        name: 'KW',
        sample: '125',
        value: '{{car.kw}}',
      },
      last_maintenance: {
        name: 'Letzter Werkstattbesuch',
        sample: '12. Januar 1999',
        value: '{{car.last_maintenance|lcDate("dd. MMMM yyyy")}}',
      },
      lastService: {
        name: 'Letzte Jahreswartung',
        sample: '12. Januar 1999',
        value: '{{car.lastService|lcDate("dd. MMMM yyyy")}}',
      },
      engine_number: {
        name: 'Motornummer',
        sample: '12345',
        value: '{{car.engine_number}}',
      },
      series: {
        name: 'Serie',
        sample: 'A 207',
        value: '{{car.series}}',
      },
      letterTag: {
        name: 'Template-Schlüssel',
        sample: 'sg',
        value: '{{car.letterTag}}',
      },
      webCode: {
        name: 'Link zur Landingpage',
        sample: 'https://www.mb-reifen.de/demo',
        value: '<a href="{{ webCode(car) }}" target="_blank">Zu allen Angeboten</a>',
      },
    },
  },
  owner: {
    name: 'Autohaus',
    mergeTags: {
      address: {
        name: 'Adresse',
        sample: 'Musterstr. 7',
        value: '{{owner.headquarter.address}}',
      },
      email: {
        name: 'E-Mail',
        sample: 'autohaus@example.com',
        value: '{{owner.headquarter.email}}',
      },
      fax: {
        name: 'Fax',
        sample: '9999 999999',
        value: '{{owner.headquarter.fax}}',
      },
      www: {
        name: 'Internet Adresse',
        sample: 'www.example.com',
        value: '{{owner.headquarter.www}}',
      },
      company: {
        name: 'Name',
        sample: 'Autohaus Mustermann',
        value: '{{owner.company}}',
      },
      city: {
        name: 'Ort',
        sample: 'Musterstadt',
        value: '{{owner.headquarter.city}}',
      },
      zip: {
        name: 'PLZ',
        sample: '99999',
        value: '{{owner.headquarter.zip}}',
      },
      signature: {
        name: 'Schlussformel',
        sample: 'Mit freundlichen Grüßen',
        value: '{{owner.headquarter.signature}}',
      },
      phone: {
        name: 'Telefon',
        sample: '9999 999999',
        value: '{{owner.headquarter.phone}}',
      },
      appointment: {
        name: 'Terminvereinbarung',
        mergeTags: {
          appointmentWWW: {
            name: 'Terminvereinbarung Online',
            sample: 'www.example.com',
            value: '{{owner.headquarter.appointmentWWW}}',
          },
          appointmentPhone: {
            name: 'Terminvereinbarung Telefon',
            sample: '9999 999999',
            value: '{{owner.headquarter.appointmentPhone}}',
          },
          appointmentEmail: {
            name: 'Terminvereinbarung E-Mail',
            sample: 'service@example.com',
            value: '{{owner.headquarter.appointmentEmail}}',
          },
        },
      },
    },
  },
  job: {
    name: 'Aktueller Aussand',
    mergeTags: {
      normalizedPrinted_ym: {
        name: 'Y-m',
        sample: '2021-01',
        value: '{{job.normalizedPrinted|date("Y-m")}}',
      },
      normalizedPrinted_my: {
        name: 'MMMM yyyy',
        sample: '2021-01',
        value: '{{job.normalizedPrinted|lcDate("MMMM yyyy")}}',
      },
    },
  },
  legals: {
    name: 'Rechtliche Links',
    mergeTags: {
      unsubscribe: {
        name: 'Abmeldelink einfügen',
        sample: 'abmelden',
        value:
          'Falls Sie diesen Newsletter in Zukunft nicht mehr erhalten möchten, können Sie ihn <a href="{{ unsubscribe }}">hier abbestellen</a>.',
      },
      trackingReject: {
        name: 'Trackingwiderspuch einfügen',
        sample: 'widerspruch',
        value:
          'Der Speicherung und Auswertung unpersonalisierter statistischer Daten können Sie <a href="{{ trackingReject }}" target="_blank"> hier widersprechen</a>.',
      },
      pkw: {
        name: 'Pkw',
        mergeTags: {
          email_link_anbieter_pkw: {
            name: 'Anbieter',
            sample: 'https://www.mercedes-benz.de/passengercars/content-pool/tool-pages/legal/copyright.html',
            value: '<a href="{{ textmodule(\'email_link_anbieter\', \'pc\') }}" target="_blank">Anbieter</a>',
          },
          email_link_cookies_pkw: {
            name: 'Cookies',
            sample: 'https://www.mercedes-benz.de/passengercars/content-pool/tool-pages/legal/cookies.html',
            value: '<a href="{{ textmodule(\'email_link_cookies\', \'pc\') }}" target="_blank">Cookies</a>',
          },
          email_link_datenschutz_pkw: {
            name: 'Datenschutz',
            sample: 'https://www.mercedes-benz.de/passengercars/content-pool/tool-pages/legal/privacy-statement.html',
            value: '<a href="{{ textmodule(\'email_link_datenschutz\', \'pc\') }}" target="_blank">Datenschutz</a>',
          },
          email_link_recht_pkw: {
            name: 'rechtliche Hinweise',
            sample: 'https://www.mercedes-benz.de/passengercars/content-pool/tool-pages/legal/legal-notice.html',
            value: '<a href="{{ textmodule(\'email_link_recht\', \'pc\') }}" target="_blank">Rechtliche Hinweise</a>',
          },
        },
      },
      smart: {
        name: 'smart',
        mergeTags: {
          email_link_anbieter_smart: {
            name: 'Anbieter',
            sample: 'https://www.smart.com/de/de/datenschutz#ftowdu',
            value: '<a href="{{ textmodule(\'email_link_anbieter\', \'sg\') }}" target="_blank">Anbieter</a>',
          },
          email_link_cookies_smart: {
            name: 'Cookies',
            sample: 'https://www.smart.com/de/de/datenschutz#1xkjbof',
            value: '<a href="{{ textmodule(\'email_link_cookies\', \'sg\') }}" target="_blank">Cookies</a>',
          },
          email_link_datenschutz_smart: {
            name: 'Datenschutz',
            sample: 'https://www.smart.com/de/de/datenschutz#yixbge',
            value: '<a href="{{ textmodule(\'email_link_datenschutz\', \'sg\') }}" target="_blank">Datenschutz</a>',
          },
          email_link_recht_smart: {
            name: 'rechtliche Hinweise',
            sample: 'https://www.smart.com/de/de/datenschutz#f3x179',
            value: '<a href="{{ textmodule(\'email_link_recht\', \'sg\') }}" target="_blank">Rechtliche Hinweise</a>',
          },
        },
      },
      nfz: {
        name: 'Nutzfahrzeuge',
        mergeTags: {
          email_link_anbieter_nfz: {
            name: 'Anbieter',
            sample: 'https://www.mercedes-benz.de/passengercars/content-pool/tool-pages/legal/copyright.html',
            value: '<a href="{{ textmodule(\'email_link_anbieter\', \'cv\') }}" target="_blank">Anbieter</a>',
          },
          email_link_cookies_nfz: {
            name: 'Cookies',
            sample: 'https://www.mercedes-benz.de/passengercars/content-pool/tool-pages/legal/cookies.html',
            value: '<a href="{{ textmodule(\'email_link_cookies\', \'cv\') }}" target="_blank">Cookies</a>',
          },
          email_link_datenschutz_nfz: {
            name: 'Datenschutz',
            sample: 'https://www.mercedes-benz.de/passengercars/content-pool/tool-pages/legal/privacy-statement.html',
            value: '<a href="{{ textmodule(\'email_link_datenschutz\', \'cv\') }}" target="_blank">Datenschutz</a>',
          },
          email_link_recht_nfz: {
            name: 'rechtliche Hinweise',
            sample: 'https://www.mercedes-benz.de/passengercars/content-pool/tool-pages/legal/legal-notice.html',
            value: '<a href="{{ textmodule(\'email_link_recht\', \'cv\') }}" target="_blank">Rechtliche Hinweise</a>',
          },
        },
      },
      lkw: {
        name: 'Lkw',
        mergeTags: {
          email_link_anbieter_lkw: {
            name: 'Anbieter',
            sample: 'https://www.mercedes-benz-trucks.com/de_DE/copyright.html',
            value: '<a href="{{ textmodule(\'email_link_anbieter\', \'truck\') }}" target="_blank">Anbieter</a>',
          },
          email_link_cookies_lkw: {
            name: 'Cookies',
            sample: 'https://www.mercedes-benz-trucks.com/de_DE/cookies.html',
            value: '<a href="{{ textmodule(\'email_link_cookies\', \'truck\') }}" target="_blank">Cookies</a>',
          },
          email_link_datenschutz_lkw: {
            name: 'Datenschutz',
            sample: 'https://www.mercedes-benz-trucks.com/de_DE/privacy-statement.html',
            value: '<a href="{{ textmodule(\'email_link_datenschutz\', \'truck\') }}" target="_blank">Datenschutz</a>',
          },
          email_link_recht_lkw: {
            name: 'rechtliche Hinweise',
            sample: 'https://www.mercedes-benz-trucks.com/de_DE/legal-notice.html',
            value:
              '<a href="{{ textmodule(\'email_link_recht\', \'truck\') }}" target="_blank">Rechtliche Hinweise</a>',
          },
        },
      },
    },
  },
  configParams: {
    name: 'Konfigurationsparameter',
    mergeTags: {
      contact: {
        name: 'Kontakt',
        mergeTags: {
          wechsel_telefon: {
            name: 'Telefon Räderwechsel',
            sample: '9999 999999',
            value:
              '<a href="{{ config.phone(car, \'Wechsel\')|phoneLink }}" target="_blank">{{ config.phone(car, \'Wechsel\') }}</a>',
          },
          verkauf_telefon: {
            name: 'Telefon Reifenverkauf',
            sample: '9999 999999',
            value:
              '<a href="{{ config.phone(car, \'Verkauf\')|phoneLink }}" target="_blank">{{ config.phone(car, \'Verkauf\') }}</a>',
          },
          wechsel_mail: {
            name: 'E-Mail',
            sample: 'mustermann@example.com',
            value:
              '<a href="mailto:{{ config.email(car, \'Email\') }}" target="_blank">{{ config.email(car, \'Email\') }}</a>',
          },
        },
      },
      otb: {
        name: 'Online-Terminvereinbarung',
        mergeTags: {
          wechsel_termin_url_pkw: {
            name: 'Online-Terminvereinbarung',
            sample: 'www.example.de',
            value:
              '<a href="{{ configparam(\'kPkwWechselTerminUrl\', job.season.campaign)|visitUrl(\'kPkwWechselTerminUrl\', owner.organisationId, queue) }}" target="_blank">Online-Terminvereinbarung</a>',
          },
          wechsel_termin_url_trapo: {
            name: 'Online-Terminvereinbarung',
            sample: 'www.example.de',
            value:
              '<a href="{{ configparam(\'kTrapoWechselTerminUrl\', job.season.campaign)|visitUrl(\'kTrapoWechselTerminUrl\', owner.organisationId, queue) }}" target="_blank">Online-Terminvereinbarung</a>',
          },
          wechsel_termin_url_smart: {
            name: 'Online-Terminvereinbarung',
            sample: 'www.example.de',
            value:
              '<a href="{{ configparam(\'kSmartWechselTerminUrl\', job.season.campaign)|visitUrl(\'kSmartWechselTerminUrl\', owner.organisationId, queue) }}" target="_blank">Online-Terminvereinbarung</a>',
          },
        },
      },
    },
  },
  wheelpass: {
    name: 'Reifenpass',
    mergeTags: {
      saison: {
        name: 'Saison',
        sample: 'Winter',
        value: '{{ campaign.seasonOfYearByReferenceDate }}',
      },
      tyrecount: {
        name: 'Reifen erneuern',
        sample: '2',
        value: '{{ wheelPass(car, job.season.campaign, "tyrecount") }}',
      },
      guidance: {
        name: 'Handlungsempfehlung',
        sample: 'Zwei Ihrer Reifen sind nicht mehr verkehrssicher. Sie sollten diese austauschen.',
        value: '{{ wheelPass(car, job.season.campaign, "guidance") }}',
      },
    },
  },
};

export default Variables;

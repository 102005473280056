import React, { Component } from 'react';
import { isEqual } from 'lodash';
import { GridColDef, GridRowId } from '@mui/x-data-grid';
import { connect, ConnectedProps } from 'react-redux';
import { Paper } from '@mui/material';
import ErrorBoundary from '../../../components/ErrorBoundary';
import RenderCellExpand from '../../../components/DataGrid/RenderCellExpand';
import Translate from '../../../components/service/Translate';
import { GridDataLoader } from '../../../components/DataGrid/DataGridTypes';
import EeloyDataGrid from '../../../components/DataGrid/EeloyDataGrid';
import { RootState } from '../../../reducers';
import { fetchAllEmailAccounts, selectAllEmailAccounts } from '../EmailAccountSlice';

type EmailAccountListProps = {
  onDelete: (current: GridRowId) => void;
  onAdd: () => void;
  onEdit: (current: GridRowId) => void;
};

const columns: GridColDef[] = [
  { field: 'id', headerName: 'ID', type: 'number', width: 30 },
  {
    field: 'addresserEmail',
    type: 'string',
    minWidth: 150,
    flex: 1,
    renderCell: RenderCellExpand,
    renderHeader: () => <Translate>Sender</Translate>,
  },
  {
    field: 'host',
    headerName: 'Host',
    type: 'string',
    minWidth: 150,
    flex: 1,
    renderCell: RenderCellExpand,
  },
  {
    field: 'transmitter',
    type: 'string',
    minWidth: 150,
    flex: 1,
    renderCell: RenderCellExpand,
    renderHeader: () => <Translate>Transmitter</Translate>,
  },
  {
    field: 'updated',
    minWidth: 150,
    flex: 1,
    renderHeader: () => <Translate>Updated</Translate>,
    renderCell: (params) => RenderCellExpand({ ...params, modifier: 'datetime' }),
  },
];

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & EmailAccountListProps;

class EmailAccountList extends Component<Props> {
  shouldComponentUpdate(nextProps: Props) {
    const { accounts, pagination } = this.props;

    return !isEqual(nextProps.accounts, accounts) || !isEqual(nextProps.pagination, pagination);
  }

  loadNext: GridDataLoader = (gridParams) => {
    const { fetchAllEmailAccounts } = this.props;

    return Promise.all([fetchAllEmailAccounts({ gridParams })]);
  };

  render() {
    const { accounts, pagination, onDelete, onAdd, onEdit, sortModel, paginationModel } = this.props;

    return (
      <ErrorBoundary>
        <Paper>
          <EeloyDataGrid
            title="E-Mail Account"
            columnsDefintion={columns}
            rows={accounts}
            onEdit={onEdit}
            onAdd={onAdd}
            onDelete={onDelete}
            rowCount={pagination?.total ?? 0}
            loadNext={this.loadNext}
            initialState={{
              sorting: { sortModel },
              pagination: { paginationModel },
            }}
          />
        </Paper>
      </ErrorBoundary>
    );
  }
}

function mapStateToProps(state: RootState) {
  const {
    emailAccount: { pagination, paginationModel, sortModel },
  } = state;

  return {
    accounts: selectAllEmailAccounts(state),
    pagination,
    paginationModel,
    sortModel,
  };
}

const connector = connect(mapStateToProps, {
  fetchAllEmailAccounts,
});

export default connector(EmailAccountList);

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const LOCATIONS_REQUEST = 'LOCATIONS_REQUEST';
export const LOCATIONS_SUCCESS = 'LOCATIONS_SUCCESS';
export const LOCATIONS_FAILURE = 'LOCATIONS_FAILURE';

function fetchLocations(sortColumns, sortOrders, offset = null, limit = null, join, filter, affiliateId) {
  return {
    [CALL_API]: {
      types: [LOCATIONS_REQUEST, LOCATIONS_SUCCESS, LOCATIONS_FAILURE],
      endpoint: Routing.generate(
        'location_index',
        {
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
          affiliateId: affiliateId,
        },
        true,
      ),
      schema: Schemas.LOCATIONS,
    },
  };
}

export function loadLocations(
  sortColumns = [],
  sortOrders = [],
  requiredFields = [],
  join = [],
  filter = [],
  affiliateId = null,
) {
  return (dispatch, getState) => {
    const locations = getState().entities.location;
    const outdated = getState().outdated.location;

    if (!outdated && locations && requiredFields.every((key) => sample(locations).hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchLocations(sortColumns, sortOrders, null, null, join, filter, affiliateId));
  };
}

export const LOCATIONS_PAGED_REQUEST = 'LOCATIONS_PAGED_REQUEST';
export const LOCATIONS_PAGED_SUCCESS = 'LOCATIONS_PAGED_SUCCESS';
export const LOCATIONS_PAGED_FAILURE = 'LOCATIONS_PAGED_FAILURE';

function fetchLocationsPaged(
  sortColumns,
  sortOrders,
  offset = null,
  limit = null,
  join = [],
  filter = [],
  affiliateId = null,
) {
  return {
    [CALL_API]: {
      types: [LOCATIONS_PAGED_REQUEST, LOCATIONS_PAGED_SUCCESS, LOCATIONS_PAGED_FAILURE],
      endpoint: Routing.generate(
        'location_index',
        {
          affiliateId: affiliateId,
          sortColumns: sortColumns,
          sortOrders: sortOrders,
          offset: offset,
          limit: limit,
          join: join,
          filter: filter,
        },
        true,
      ),
      schema: Schemas.LOCATIONS,
    },
  };
}

export function loadNext(
  sortColumns = [],
  sortOrders = [],
  offset = null,
  limit = null,
  join = [],
  filter = [],
  affiliateId = null,
) {
  return (dispatch, getState) => {
    const location = getState().entities.location;
    const pagination = location && getState().pagination.location ? getState().pagination.location : false;

    if (!location || !pagination) {
      return null;
    }

    return dispatch(fetchLocationsPaged(sortColumns, sortOrders, offset, limit, join, filter, affiliateId));
  };
}

export const LOCATION_UPDATE_REQUEST = 'LOCATION_UPDATE_REQUEST';
export const LOCATION_UPDATE_SUCCESS = 'LOCATION_UPDATE_SUCCESS';
export const LOCATION_UPDATE_FAILURE = 'LOCATION_UPDATE_FAILURE';

function writeLocation(form, locationId) {
  return {
    [CALL_API]: {
      types: [LOCATION_UPDATE_REQUEST, LOCATION_UPDATE_SUCCESS, LOCATION_UPDATE_FAILURE],
      endpoint: Routing.generate('location_update', { id: locationId }, true),
      method: 'POST',
      data: form,
      schema: Schemas.LOCATION,
    },
  };
}

export function updateLocation(form, locationId = null) {
  return (dispatch) => {
    return dispatch(writeLocation(form, locationId));
  };
}

export const LOCATIONHEADQUARTER_UPDATE_REQUEST = 'LOCATIONHEADQUARTER_UPDATE_REQUEST';
export const LOCATIONHEADQUARTER_UPDATE_SUCCESS = 'LOCATIONHEADQUARTER_UPDATE_SUCCESS';
export const LOCATIONHEADQUARTER_UPDATE_FAILURE = 'LOCATIONHEADQUARTER_UPDATE_FAILURE';

function writeLocationHeadquarter(form, locationId) {
  return {
    [CALL_API]: {
      types: [
        LOCATIONHEADQUARTER_UPDATE_REQUEST,
        LOCATIONHEADQUARTER_UPDATE_SUCCESS,
        LOCATIONHEADQUARTER_UPDATE_FAILURE,
      ],
      endpoint: Routing.generate('location_updateHeadquarter', { id: locationId }, true),
      method: 'POST',
      data: form,
    },
  };
}

export function updateLocationHeadquarter(form, locationId = null) {
  return (dispatch) => {
    return dispatch(writeLocationHeadquarter(form, locationId));
  };
}

export const LOCATION_DELETE_REQUEST = 'LOCATION_DELETE_REQUEST';
export const LOCATION_DELETE_SUCCESS = 'LOCATION_DELETE_SUCCESS';
export const LOCATION_DELETE_FAILURE = 'LOCATION_DELETE_FAILURE';

function deleteLocation(locationId, deleteToken) {
  return {
    [CALL_API]: {
      types: [LOCATION_DELETE_REQUEST, LOCATION_DELETE_SUCCESS, LOCATION_DELETE_FAILURE],
      endpoint: Routing.generate('location_delete', { id: locationId, deleteToken: deleteToken }, true),
      method: 'DELETE',
    },
  };
}

export function removeLocation(locationId, deleteToken) {
  return (dispatch) => {
    return dispatch(deleteLocation(locationId, deleteToken));
  };
}

export const LOCATION_FORM_REQUEST = 'LOCATION_FORM_REQUEST';
export const LOCATION_FORM_SUCCESS = 'LOCATION_FORM_SUCCESS';
export const LOCATION_FORM_FAILURE = 'LOCATION_FORM_FAILURE';

export const TAGS_REQUEST = 'TAGS_REQUEST';
export const TAGS_SUCCESS = 'TAGS_SUCCESS';
export const TAGS_FAILURE = 'TAGS_FAILURE';

function fetchTags() {
  return {
    [CALL_API]: {
      types: [TAGS_REQUEST, TAGS_SUCCESS, TAGS_FAILURE],
      endpoint: Routing.generate('location_tags', null, true),
      schema: Schemas.TAGS,
    },
  };
}

export function loadTags(requiredFields = []) {
  return (dispatch, getState) => {
    const tags = getState().entities.tag;

    if (tags && requiredFields.every((key) => sample(tags).hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchTags());
  };
}

function fetchForm() {
  return {
    [CALL_API]: {
      types: [LOCATION_FORM_REQUEST, LOCATION_FORM_SUCCESS, LOCATION_FORM_FAILURE],
      endpoint: Routing.generate('location_token', {}, true),
      schema: Schemas.FORM,
    },
  };
}

export function initForm() {
  return (dispatch, getState) => {
    const updateToken = getState().forms.updateToken;

    if (updateToken) {
      return null;
    }

    return dispatch(fetchForm());
  };
}

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { camelizeKeys } from 'humps';
import {
  InteractionsSliceState,
  KeyIndicatorsSliceState,
  RecipientsSliceState,
  ReportTablesSliceState,
} from './StatisticCampaignTypes';

export const fetchKeyIndicators = createAsyncThunk('keyIndicators/fetchById', async (data: { id: number }) => {
  const { id } = data;

  const response = await axios.get(Routing.generate('statistic_campaign_indicators', { id }, true), {
    withCredentials: true,
  });

  return {
    data: camelizeKeys<KeyIndicatorsSliceState>(response.data.data),
  };
});

export const fetchRecipients = createAsyncThunk('recipients/fetchById', async (data: { id: number }) => {
  const { id } = data;

  const response = await axios.get(Routing.generate('statistic_campaign_recipients', { id }, true), {
    withCredentials: true,
  });

  return { data: camelizeKeys<RecipientsSliceState>(response.data.data) };
});

export const fetchInteractions = createAsyncThunk('interactions/fetchById', async (data: { id: number }) => {
  const { id } = data;

  const response = await axios.get(Routing.generate('statistic_campaign_interactions', { id }, true), {
    withCredentials: true,
  });

  return { data: camelizeKeys<InteractionsSliceState>(response.data.data) };
});

export const fetchReportTables = createAsyncThunk('reportTables/fetchById', async (data: { id: number }) => {
  const { id } = data;

  const response = await axios.get(Routing.generate('statistic_campaign_reports', { id }, true), {
    withCredentials: true,
  });

  return { data: camelizeKeys<ReportTablesSliceState>(response.data.data) };
});

interface SliceState {
  keyIndicator: KeyIndicatorsSliceState;
  recipients: RecipientsSliceState;
  interactions: InteractionsSliceState;
  reportTables: ReportTablesSliceState;
}

const initialState: SliceState = {
  keyIndicator: {
    dispatched: {
      sent: 0,
      sentToInDepot: 0,
      sentToNotInDepot: 0,
    },
    extract: {
      crmId: '',
      dispatchedAt: '',
      lastUpdated: '',
    },
    opened: {
      clickRate: null,
      deliveredRate: null,
      openedRate: null,
    },
    title: '',
  },
  recipients: {
    customerType: null,
    campaignVersion: null,
    customerGroup: null,
  },
  interactions: {
    openedEmail: null,
    openedWeb: null,
    bounced: null,
  },
  reportTables: {
    inDepot: null,
    notInDepot: null,
  },
};

const statisticCampaignSlice = createSlice({
  name: 'statisticCampaign',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchKeyIndicators.fulfilled, (state, action) => {
      state.keyIndicator = action.payload.data;
    });
    builder.addCase(fetchRecipients.fulfilled, (state, action) => {
      state.recipients = action.payload.data;
    });
    builder.addCase(fetchInteractions.fulfilled, (state, action) => {
      state.interactions = action.payload.data;
    });
    builder.addCase(fetchReportTables.fulfilled, (state, action) => {
      state.reportTables = action.payload.data;
    });
  },
});

export default statisticCampaignSlice.reducer;

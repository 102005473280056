import React, { Component } from 'react';
import { connect } from 'react-redux';
import { outdateEntity, resetEntity } from '../../actions';
import PropTypes from 'prop-types';
import Translate from '../../components/service/Translate';
import { Paper } from '@mui/material';
import {
  loadAffiliateSettings,
  updateAffiliateSetting,
  removeAffiliateSetting,
  loadSettings,
  loadNext,
} from './actions';
import Edit from './components/Edit';
import { makeAffiliateById } from './selectors';
import DeleteDialog from '../../components/DeleteDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import { isEqual } from 'lodash';
import RenderCellExpand from '../../components/DataGrid/RenderCellExpand';
import DataGridTable from '../../components/DataGrid/DataGrid';

class AffiliateSetting extends Component {
  static propTypes = {
    affiliateId: PropTypes.number,
    setting: PropTypes.object,
    fitted: PropTypes.bool,
    loadAffiliateSettings: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    loadSettings: PropTypes.func.isRequired,
    updateAffiliateSetting: PropTypes.func,
    resetEntity: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
  };

  state = {
    current: null,
    currentIndex: null,
    adding: false,
    editing: false,
    deleting: false,
    dataLoading: false,
    sortColumns: ['id'],
    sortOrders: ['asc'],
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        type: 'number',
        width: 30,
      },
      {
        field: 'affiliate',
        headerName: 'Niederlassung',
        relation: 'affiliate',
        id: 'primaryText',
        type: 'string',
        sort: 'affiliate.name',
        joinBy: { affiliate: 'affiliatesetting.affiliate' },
        minWidth: 100,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'setting',
        headerName: 'Schlüssel',
        relation: 'setting',
        id: 'primaryText',
        type: 'string',
        sort: 'setting.setting',
        joinBy: { setting: 'affiliatesetting.setting' },
        minWidth: 100,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'value',
        headerName: 'Einstellung',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
    ],
  };

  componentDidMount = () => {
    this.loadAllAffiliateSettings();
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.affiliateSetting, this.props.affiliateSetting)
      || !isEqual(nextProps.setting, this.props.setting)
      || !isEqual(nextProps.loading, this.props.loading)
      || !isEqual(nextProps.resultset, this.props.resultset)
      || !isEqual(nextProps.affiliate, this.props.affiliate)
      || !isEqual(nextProps.pagination, this.props.pagination)
      || !isEqual(nextState, this.state)
    );
  };

  loadAllAffiliateSettings = (showLoading = true) => {
    const { sortColumns, sortOrders } = this.state;
    showLoading ? this.setState({ dataLoading: true }) : null;
    Promise.all([
      this.props.loadAffiliateSettings(sortColumns, sortOrders, [], [], this.props.affiliateId),
      this.props.loadSettings(),
    ]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  };

  onTouchEdit = (current) => {
    this.setState({
      adding: false,
      editing: true,
      valid: false,
      current: current,
    });
  };

  onTouchAdd = () => {
    this.setState({ adding: true, editing: false, valid: false });
  };

  onTouchDelete = (current) => {
    this.setState({ deleting: true, current: current });
  };

  onTouchCancel = () => {
    this.setState({
      adding: false,
      editing: false,
      deleting: false,
      valid: false,
      new: false,
    });
  };

  handleSortModelChange = (newSortModel) => {
    this.setState({
      sortColumns: newSortModel?.map((value) => value.field),
      sortOrders: newSortModel?.map((value) => value.sort),
    });
  };

  loadNext = (columns, sort, join = [], offset = null, limit = null, filter = []) => {
    return Promise.all([this.props.loadNext(this.props.affiliateId, columns, sort, offset, limit, join, filter)]);
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  handleValidityChange = (valid) => {
    this.setState({ valid: valid });
  };

  submit = () => {
    this.form.submit();
  };

  handleSubmit = (form) => {
    const { loadAffiliateSettings, updateAffiliateSetting, loadSettings } = this.props;
    const { editing, current, sortColumns, sortOrders } = this.state;

    this.setState({ valid: false });

    return Promise.all([updateAffiliateSetting(form, editing ? current : null)]).then(
      (values) => {
        if (!(values && values[0] && values[0].error)) {
          loadSettings();
          loadAffiliateSettings(sortColumns, sortOrders, [], [], this.props.affiliateId);
          this.setState({ adding: false, deleting: false, editing: false });
        }
      },
      () => {
        this.setState({ valid: false });
      },
    );
  };

  handleDelete = () => {
    const { affiliateSetting, affiliateId, resetEntity, loadAffiliateSettings, removeAffiliateSetting } = this.props;
    const { sortColumns, sortOrders, current } = this.state;
    let affiliateSettingToken = affiliateSetting[current].deleteToken;

    this.setState({ deleting: true, current: current });

    Promise.all([removeAffiliateSetting(current, affiliateSettingToken)]).then((values) => {
      if (!(values && values[0] && values[0].error)) {
        this.setState({ deleting: false, mode: 'show' });
        resetEntity('affiliatesetting', current);
        loadAffiliateSettings(sortColumns, sortOrders, [], [], affiliateId);
      }
    });
  };

  renderDeleteDialog = () => {
    const { deleting, current } = this.state;
    const { affiliateSetting, setting } = this.props;

    if (deleting && current) {
      let currentSetting = affiliateSetting[current].setting;

      return (
        <DeleteDialog current={setting[currentSetting]} onCancel={this.onTouchCancel} onSubmit={this.handleDelete} />
      );
    }

    return null;
  };

  renderContent = () => {
    const { fitted, affiliateId, pagination, resultset, affiliateSetting, setting, affiliate, embedded } = this.props;
    const { columns, adding, editing, current, dataLoading } = this.state;

    let child = null;

    if (dataLoading) {
      child = <Translate>Data loading...</Translate>;

      if (!fitted) {
        return <Paper>{child}</Paper>;
      }

      return child;
    }

    if (adding) {
      child = (
        <ErrorBoundary>
          <Edit
            onMount={this.handleFormMount}
            onSubmit={this.handleSubmit}
            affiliate={affiliate}
            setting={setting}
            onValidityChange={this.handleValidityChange}
            affiliateSettingId={null}
            affiliateId={affiliateId}
            type={'adding'}
            onCancel={this.onTouchCancel}
            submit={this.submit}
          />
        </ErrorBoundary>
      );

      if (!fitted) {
        return <Paper>{child}</Paper>;
      }

      return child;
    }

    if (editing) {
      child = (
        <ErrorBoundary>
          <Edit
            onMount={this.handleFormMount}
            onSubmit={this.handleSubmit}
            affiliate={affiliate}
            setting={setting}
            onValidityChange={this.handleValidityChange}
            affiliateSettingId={current.id ? current.id : current}
            affiliateId={affiliateId}
            type={'editing'}
            affiliateSetting={affiliateSetting}
            onCancel={this.onTouchCancel}
            submit={this.submit}
          />
        </ErrorBoundary>
      );

      if (!fitted) {
        return <Paper>{child}</Paper>;
      }

      return child;
    }

    return (
      <>
        <ErrorBoundary>
          <DataGridTable
            noPaper={fitted}
            embedded={embedded}
            title="Niederlassung Einstellungen"
            rows={affiliateSetting}
            iteratableRows={resultset}
            columns={columns}
            pagination={pagination}
            onEdit={this.onTouchEdit}
            onAdd={this.onTouchAdd}
            onDelete={this.onTouchDelete}
            onSortModelChange={this.handleSortModelChange}
            onLoadNext={this.loadNext}
          />
        </ErrorBoundary>
      </>
    );
  };

  render() {
    const { affiliateId } = this.props;

    if (!affiliateId) {
      return null;
    }

    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

// eslint-disable-next-line no-unused-vars
const makeMapStateToProps = (props) => {
  const getAffiliateById = makeAffiliateById();

  return (state, props) => {
    const {
      entities: { affiliatesetting, setting },
      resultsets: { affiliatesetting: resultset },
      pagination: { affiliatesetting: pagination },
    } = state;

    return {
      affiliate: getAffiliateById(state, props),
      resultset: resultset,
      affiliateSetting: affiliatesetting,
      setting: setting,
      pagination: pagination,
    };
  };
};

export default connect(makeMapStateToProps, {
  loadAffiliateSettings,
  loadSettings,
  loadNext,
  resetEntity,
  outdateEntity,
  removeAffiliateSetting,
  updateAffiliateSetting,
})(AffiliateSetting);

import PropTypes from 'prop-types';
import { Alert, AlertTitle } from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import { loadUploadList, loadNext } from '../actions';
import { outdateEntity } from '../../../actions';
import { isEqual } from 'lodash';
import RenderCellExpand from '../../../components/DataGrid/RenderCellExpand';
import ErrorBoundary from '../../../components/ErrorBoundary';
import DataGridTable from '../../../components/DataGrid/DataGrid';
import ACL from '../../../components/ACL';

class UploadList extends Component {
  static propTypes = {
    loadUploadList: PropTypes.func.isRequired,
    loadNext: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    resultset: PropTypes.array,
    uploads: PropTypes.object,
    mbvdMode: PropTypes.bool,
    ownerInterface: PropTypes.object,
  };

  state = {
    current: null,
    currentIndex: null,
    adding: false,
    editing: false,
    deleting: false,
    valid: false,
    locationsLoaded: false,
    sortColumns: [],
    sortOrders: ['asc'],
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        type: 'number',
        width: 30,
      },
      {
        field: 'season',
        headerName: 'Mailing',
        relation: 'season',
        id: 'primaryText',
        sort: 'campaign.title',
        joinBy: { season: 'upload.season', campaign: 'season.campaign' },
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'campaignDate',
        headerName: 'Aktionsmonat',
        relation: null,
        id: 'campaignDate',
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => RenderCellExpand({ ...params, modifier: 'translate' }),
      },
      {
        field: 'affiliate',
        headerName: 'Niederlassung',
        relation: 'affiliate',
        id: 'primaryText',
        sort: 'affiliate.name',
        joinBy: { affiliate: 'upload.affiliate' },
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'original',
        headerName: 'Dateiname',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'status',
        headerName: 'Status',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => RenderCellExpand({ ...params, modifier: 'translate' }),
      },
      {
        field: 'imported',
        headerName: 'Datensätze eingelesen',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'updated',
        headerName: 'letzte Aktualisierung',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => RenderCellExpand({ ...params, modifier: 'datetime' }),
      },
      {
        field: 'staff',
        headerName: 'durch',
        relation: 'upload.staff',
        id: 'primaryText',
        sort: 'staff.firstName',
        joinBy: { affiliate: 'upload.staff' },
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
    ],
  };

  constructor(props) {
    super(props);
    if (!this.props.mbvdMode) {
      this.state.columns = [
        {
          field: 'id',
          headerName: 'ID',
          relation: null,
          type: 'number',
          width: 30,
        },
        {
          field: 'original',
          headerName: 'Dateiname',
          relation: null,
          type: 'string',
          minWidth: 150,
          flex: 1,
          renderCell: RenderCellExpand,
        },
        {
          field: 'uploaded',
          headerName: 'Hochgeladen am',
          minWidth: 150,
          flex: 1,
          renderCell: (params) => RenderCellExpand({ ...params, modifier: 'datetime' }),
        },
        {
          field: 'status',
          headerName: 'Status',
          relation: null,
          type: 'string',
          minWidth: 150,
          flex: 1,
          renderCell: (params) => RenderCellExpand({ ...params, modifier: 'translate' }),
        },
        {
          field: 'imported',
          headerName: 'Datensätze eingelesen',
          relation: null,
          type: 'string',
          minWidth: 150,
          flex: 1,
          renderCell: RenderCellExpand,
        },
        {
          field: 'updated',
          headerName: 'letzte Aktualisierung',
          minWidth: 150,
          flex: 1,
          renderCell: (params) => RenderCellExpand({ ...params, modifier: 'datetime' }),
        },
      ];
    }
  }

  componentDidMount = () => {
    this.props.loadUploadList(['id', 'original', 'status'], this.props.mbvdMode ? 'latest' : null);
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !isEqual(nextProps.resultset, this.props.resultset)
      || !isEqual(nextProps.pagination, this.props.pagination)
      || !isEqual(nextProps.uploads, this.props.uploads)
    );
  }

  handleSortModelChange = (newSortModel) => {
    this.setState({
      sortColumns: newSortModel?.map((value) => value.field),
      sortOrders: newSortModel?.map((value) => value.sort),
    });
  };

  loadNext = (columns, sort, join = [], offset = null, limit = null, filter = []) => {
    return Promise.all([this.props.loadNext(columns, sort, offset, limit, join, filter)]);
  };

  renderInterfaceSummary() {
    const { ownerInterface } = this.props;

    if (ownerInterface && ownerInterface.duplicateSafe !== true) {
      if (ownerInterface.duplicateSafe === false) {
        return (
          <Alert severity="error">
            <AlertTitle>Datensicherheit</AlertTitle>
            Die Daten enthalten <strong>keine</strong> eindeutigen Kundennummern. Beim Import werden daher neue
            Kundennummern vergeben.
          </Alert>
        );
      }

      return (
        <Alert severity="warning">
          <AlertTitle>Datensicherheit</AlertTitle>
          Es ist <strong>unklar</strong> ob die Daten eindeutige Kundennummern enthalten. Bitte prüfe die Daten und lege
          den Status fest.
        </Alert>
      );
    }
  }

  render() {
    const { uploads, resultset, pagination } = this.props;
    const { columns } = this.state;

    if (!uploads) {
      return <Translate>No entries found.</Translate>;
    }

    return (
      <>
        <ErrorBoundary>
          <ACL authorized={['ROLE_SUPERADMIN']} silent={true}>
            {this.renderInterfaceSummary()}
          </ACL>
          <DataGridTable
            title="Owner Interface"
            rows={uploads}
            iteratableRows={resultset}
            columns={columns}
            disableToolbar
            pagination={pagination}
            onSortModelChange={this.handleSortModelChange}
            onLoadNext={this.loadNext}
            disableSelectionOnClick={true}
          />
        </ErrorBoundary>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    entities: { ownerinterface, upload },
    resultsets: { upload: resultset },
    pagination: { upload: pagination },
  } = state;

  const ownerInterface = typeof ownerinterface !== 'undefined' ? ownerinterface[1] : null;

  return {
    uploads: upload,
    resultset: resultset,
    pagination: pagination,
    ownerInterface: ownerInterface,
  };
};

export default connect(mapStateToProps, {
  loadUploadList,
  loadNext,
  outdateEntity,
})(UploadList);

import React, { useEffect, useState } from 'react';
import { Outlet } from 'react-router-dom';
import { fetchAppConfig } from './AppSlice';
import { useAppDispatch, useAppSelector } from '../../hooks';
import Loading from '../../components/Loading';
import { fetchAuthentication } from '../Security/SecuritySlice';

function Root() {
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector((state) => state.security.isAuthenticated);
  const [loading, setLoading] = useState(true);

  const loadSession = async () => {
    const response = await dispatch(fetchAuthentication()).unwrap();

    if (response.isAuthenticated) {
      await dispatch(fetchAppConfig());
    }

    setLoading(false);
  };

  const checkSession = async () => {
    if (isAuthenticated) {
      await loadSession();
    }

    setTimeout(() => {
      checkSession();
    }, 600000);
  };

  useEffect(() => {
    loadSession();
    checkSession();
  }, []);

  return loading ? <Loading /> : <Outlet />;
}

export default Root;

import React from 'react';
import Chevron from '@mui/icons-material/ChevronRight';
import { Box, Link, Typography } from '@mui/material';

const BreadcrumNavigation = (props) => {
  const { items } = props;

  let renderables = [];
  let numArrows = items.length - 1;

  for (let [index, item] of items.entries()) {
    if (typeof item === 'string') {
      renderables.push(<span key={index}>{item}</span>);
    } else if (typeof item === 'object') {
      renderables.push(
        <Box component="span" sx={{ color: 'accent1Color' }} key={index}>
          <Link href={item.link}>{item.text}</Link>
        </Box>,
      );
    } else {
      renderables.push(item); //TOTO: keey
    }
    if (numArrows) {
      renderables.push(<Chevron key={'arr' + index} sx={{ position: 'relative', top: 6 }} />);
      numArrows--;
    }
  }

  return <Typography variant="h5">{renderables}</Typography>;
};

export default BreadcrumNavigation;

import React from 'react';
import SecurityLayout from './SecurityLayout';
import ResetPassword from './ResetPassword';
import owl from '../../theme/images/login/eeloy-owl-security.svg';

export default function ResetIndex() {
  return (
    <SecurityLayout img={owl}>
      <ResetPassword />
    </SecurityLayout>
  );
}

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const CURRENTUSER_REQUEST = 'CURRENTUSER_REQUEST';
export const CURRENTUSER_SUCCESS = 'CURRENTUSER_SUCCESS';
export const CURRENTUSER_FAILURE = 'CURRENTUSER_FAILURE';

function fetchCurrentUser() {
  return {
    [CALL_API]: {
      types: [CURRENTUSER_REQUEST, CURRENTUSER_SUCCESS, CURRENTUSER_FAILURE],
      endpoint: Routing.generate('staff_current', { react: true }, true),
      schema: Schemas.CURRENTUSER,
    },
  };
}

export function loadCurrentUser(requiredFields = []) {
  return (dispatch, getState) => {
    const currentUser = getState().entities.currentuser;

    if (currentUser && requiredFields.every((key) => sample(currentUser).hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchCurrentUser());
  };
}

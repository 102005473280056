import React from 'react';
import PropTypes from 'prop-types';
import { Toolbar, Typography, IconButton, Tooltip } from '@mui/material';

import ContentSave from '@mui/icons-material/Save';
import NavigationCancel from '@mui/icons-material/Cancel';
import ActionDelete from '@mui/icons-material/Delete';
import EditorModeEdit from '@mui/icons-material/Edit';
import ContentAdd from '@mui/icons-material/Add';
import { styled } from '@mui/system';
import { ColorActions, Spacer, Title } from './StyledElements/StyledElements';

const DockedToolbar = styled(Toolbar)(({ theme }) => ({
  width: `calc(100% - 256px - 24px)`,
}));

class ToolBarGroup extends React.Component {
  render() {
    const {
      deletable,
      adding,
      editing,
      current,
      onEdit,
      onAdd,
      onCancel,
      onDelete,
      onHandleSubmit,
      title,
      isSubmitting,
    } = this.props;
    return (
      <DockedToolbar>
        <Title>
          <Typography variant="h5" id="tableTitle">
            {title}
          </Typography>
        </Title>
        <Spacer />
        <ColorActions>
          <div>
            {adding || editing ? (
              <div>
                <Tooltip title="Save">
                  <span>
                    <IconButton
                      color="primary"
                      aria-label="Save"
                      onClick={onHandleSubmit}
                      disabled={isSubmitting}
                      size="large"
                    >
                      <ContentSave />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Cancel">
                  <IconButton color="inherit" aria-label="Cancel" onClick={onCancel} size="large">
                    <NavigationCancel />
                  </IconButton>
                </Tooltip>
                <Tooltip title="Delete">
                  <span>
                    <IconButton
                      color="inherit"
                      aria-label="Delete"
                      disabled={!deletable}
                      onClick={onDelete}
                      size="large"
                    >
                      <ActionDelete />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            ) : (
              <div>
                <Tooltip title="Edit">
                  <span>
                    <IconButton color="inherit" aria-label="Edit" disabled={!current} onClick={onEdit} size="large">
                      <EditorModeEdit />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Add">
                  <span>
                    <IconButton color="inherit" aria-label="Add" onClick={onAdd} size="large">
                      <ContentAdd />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title="Delete">
                  <span>
                    <IconButton
                      color="inherit"
                      aria-label="Delete"
                      disabled={!(current && deletable)}
                      onClick={onDelete}
                      size="large"
                    >
                      <ActionDelete />
                    </IconButton>
                  </span>
                </Tooltip>
              </div>
            )}
          </div>
        </ColorActions>
      </DockedToolbar>
    );
  }
}

ToolBarGroup.propTypes = {
  title: PropTypes.string,
  view: PropTypes.string,
  current: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  actions: PropTypes.string,
  adding: PropTypes.bool,
  editing: PropTypes.bool,
  deleting: PropTypes.bool,
  deletable: PropTypes.bool,
  onEdit: PropTypes.func,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  onList: PropTypes.func,
  onGrid: PropTypes.func,
  onHandleSubmit: PropTypes.func,
  isSubmitting: PropTypes.bool,
};

export default ToolBarGroup;

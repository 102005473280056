import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { isEqual } from 'lodash';
import DataGridTable from '../../../components/DataGrid/DataGrid';
import RenderCellExpand from '../../../components/DataGrid/RenderCellExpand';

class EmailModuleList extends Component {
  static propTypes = {
    modules: PropTypes.object.isRequired,
    pagination: PropTypes.object.isRequired,
    resultset: PropTypes.array.isRequired,
    onSortModelChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onLoadNext: PropTypes.func.isRequired,
    initialGridState: PropTypes.object,
  };

  static defaultProps = {};

  state = {
    columns: [
      {
        field: 'id',
        headerName: 'ID',
        relation: null,
        type: 'number',
        width: 30,
      },
      {
        field: 'teaser',
        headerName: 'Teaser',
        relation: null,
        width: 358,
        height: 280,
        filterable: false,
        sortable: false,
        renderCell: (params) => RenderCellExpand({ ...params, modifier: 'image' }),
      },
      {
        field: 'name',
        headerName: 'Name',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'configKey',
        headerName: 'ConfigKey',
        relation: null,
        type: 'string',
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'category',
        headerName: 'Kategorie',
        relation: null,
        type: 'singleSelect',
        valueOptions: ['image', 'layout', 'Product', 'service', 'template', 'vehicles', 'other'],
        minWidth: 150,
        flex: 1,
        renderCell: RenderCellExpand,
      },
      {
        field: 'created',
        headerName: 'Hinzugefügt',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => RenderCellExpand({ ...params, modifier: 'datetime' }),
      },
      {
        field: 'updated',
        headerName: 'Letzte Änderung',
        minWidth: 150,
        flex: 1,
        renderCell: (params) => RenderCellExpand({ ...params, modifier: 'datetime' }),
      },
    ],
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.modules, this.props.modules)
      || !isEqual(nextProps.resultset, this.props.resultset)
      || !isEqual(nextProps.pagination, this.props.pagination)
    );
  };

  render() {
    const {
      modules,
      resultset,
      pagination,
      onDelete,
      onAdd,
      onEdit,
      onSortModelChange,
      onLoadNext,
      onDuplicate,
      initialGridState,
    } = this.props;
    const { columns } = this.state;

    let newModules = {};
    Object.keys(modules).forEach((key) => {
      newModules[key] = {
        ...modules[key],
        teaser: Routing.generate('emailmodule_teaser', { id: modules[key].id }, true),
      };
    });

    return (
      <div>
        <ErrorBoundary>
          <DataGridTable
            title="E-Mail Bausteine"
            rows={newModules}
            iteratableRows={resultset}
            columns={columns}
            rowHeight={280}
            pagination={pagination}
            onEdit={onEdit}
            onAdd={onAdd}
            onDelete={onDelete}
            onSortModelChange={onSortModelChange}
            onLoadNext={onLoadNext}
            onDuplicate={onDuplicate}
            initialState={initialGridState}
          />
        </ErrorBoundary>
      </div>
    );
  }
}

export default EmailModuleList;

import React, { Component } from 'react';
import { withRouter } from '../../withRouter';
import { connect } from 'react-redux';
import { isEmpty, isEqual, pickBy, union } from 'lodash';
import PropTypes from 'prop-types';
import { loadSmsQueueCustomersPaged, loadSmsQueues, loadNext } from './actions';
import {
  AppBar,
  Box,
  CssBaseline,
  Drawer,
  IconButton,
  ListItemText,
  Paper,
  Popover,
  Tooltip,
  Typography,
} from '@mui/material';
import Translate from '../../components/service/Translate';
import ToolBar from '../../components/ToolBar';
import ErrorBoundary from '../../components/ErrorBoundary';
import ListView from '@mui/icons-material/List';
import List from '@mui/material/List';
import { makeCustomerListCustomerByCl } from './selectors';
import SmsIcon from '@mui/icons-material/Sms';
import DockedList from './components/DockedList';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';
import NavigationCheck from '@mui/icons-material/Check';
import CustomerListCustomer from '../../components/CustomerListCustomer';
import SmsPreview from './components/SmsPreview';
import SendSmsDialog from './components/SendSmsDialog';
import { compose } from 'redux';
import ListItemButton from '@mui/material/ListItemButton';

const drawerWidth = 256;

class SmsQueue extends Component {
  static propTypes = {
    loadSmsQueues: PropTypes.func.isRequired,
    loadSmsQueueCustomersPaged: PropTypes.func.isRequired,
    seasonJobId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    loadNext: PropTypes.func.isRequired,
    sms: PropTypes.object,
    smsJobs: PropTypes.object,
    seasons: PropTypes.object,
    resultset: PropTypes.array,
    pagination: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    onBottomReached: PropTypes.func,
    iterableCustomerListCustomer: PropTypes.array,
    customerListCustomerId: PropTypes.number,
  };

  static defaultProps = {
    //Fill in default props values
  };

  state = {
    smsQueueLoading: false,
    seasonAnchorEl: null,
    openSendForm: false,
    paging: false,
    customerListLoading: false,
  };

  componentDidMount = () => {
    const { customerListCustomerId, seasonJobId, sms, resultset } = this.props;

    this.setState({ smsQueueLoading: true });
    Promise.all([this.props.loadSmsQueues()]).then(() => {
      this.setState({ smsQueueLoading: false });

      if (seasonJobId) {
        this.setState({ customerListLoading: true });
        Promise.all([
          this.props.loadSmsQueueCustomersPaged(seasonJobId, this.state.filter, [], [], customerListCustomerId),
        ]).then(
          () => {
            this.setState({ customerListLoading: false });
          },
          () => {
            this.setState({ customerListLoading: false });
          },
        );
      } else {
        if (!isEmpty(sms) && !isEmpty(resultset) && sms[resultset[0]] && !isEmpty(sms[resultset[0]].nextJobs)) {
          this.handleMenuChange(sms[resultset[0]].nextJobs[0]);
        }
      }
    });
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { seasonJobId, customerListCustomerId, sms, iterableCustomerListCustomer } = this.props;

    if (!isEqual(this.props.resultset, prevProps.resultset) || !isEqual(seasonJobId, prevProps.seasonJobId)) {
      const resultSms = pickBy(sms, (currentSms) => {
        return (
          (!isEmpty(currentSms.previousJobs) && currentSms.previousJobs.includes(seasonJobId))
          || (!isEmpty(currentSms.nextJobs) && currentSms.nextJobs.includes(seasonJobId))
        );
      });
      this.setState({
        currentSeasonId: !isEmpty(resultSms) ? resultSms[Object.keys(resultSms)[0]].season : null,
      });
    }

    if (!isEqual(customerListCustomerId, prevProps.customerListCustomerId)) {
      if (customerListCustomerId && !iterableCustomerListCustomer.includes(customerListCustomerId)) {
        this.setState({ customerListLoading: true });
        Promise.all([
          this.props.loadSmsQueueCustomersPaged(seasonJobId, this.state.filter, [], [], customerListCustomerId),
        ]).then(
          () => {
            this.setState({ customerListLoading: false });
          },
          () => {
            this.setState({ customerListLoading: false });
          },
        );
      }
      if (!customerListCustomerId && !isEmpty(iterableCustomerListCustomer)) {
        this.props.navigate('/sms/queue/customer/' + seasonJobId + '/' + iterableCustomerListCustomer[0]);
      }
    }

    if (!isEqual(seasonJobId, prevProps.seasonJobId)) {
      this.setState({ customerListLoading: true });
      Promise.all([
        this.props.loadSmsQueueCustomersPaged(seasonJobId, this.state.filter, [], [], customerListCustomerId),
      ]).then(
        () => {
          this.setState({ customerListLoading: false });
        },
        () => {
          this.setState({ customerListLoading: false });
        },
      );
    }

    if (
      prevProps.pagination
      && isEqual(this.props.seasonJobId, prevProps.seasonJobId)
      && this.props.iterableCustomerListCustomer
      && prevProps.iterableCustomerListCustomer
      && this.props.iterableCustomerListCustomer.length < prevProps.iterableCustomerListCustomer.length
    ) {
      this.setState({ isPaging: false });
    }
  }

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    return (
      !isEqual(this.state, nextState)
      || !isEqual(this.props.seasons, nextProps.seasons)
      || !isEqual(this.props.sms, nextProps.sms)
      || !isEqual(this.props.smsJobs, nextProps.smsJobs)
      || !isEqual(this.props.pagination, nextProps.pagination)
      || !isEqual(this.props.iterableCustomerListCustomer, nextProps.iterableCustomerListCustomer)
      || !isEqual(this.props.seasonJobId, nextProps.seasonJobId)
      || !isEqual(this.props.customerListCustomerId, nextProps.customerListCustomerId)
      || !isEqual(this.props.resultset, nextProps.resultset)
    );
  }

  openSendDialog = () => {
    this.setState({ openSendForm: true });
  };

  closeSendDialog = () => {
    this.setState({ openSendForm: false });
  };

  handleVisibleState = (state) => {
    this.setState({ openSendForm: state });
  };

  handleSeasonMenuClick = (event) => {
    this.setState({ seasonAnchorEl: event.currentTarget });
  };

  handleSeasonMenuClose = () => {
    this.setState({ seasonAnchorEl: null });
  };

  handleItemSelect = (event, index) => {
    this.props.navigate('/sms/queue/customer/' + this.props.seasonJobId + '/' + index);
  };

  handleForward = () => {
    const { iterableCustomerListCustomer, customerListCustomerId, seasonJobId } = this.props;

    let index = iterableCustomerListCustomer.indexOf(customerListCustomerId) + 1;

    if (index < iterableCustomerListCustomer.length) {
      this.props.navigate('/sms/queue/customer/' + seasonJobId + '/' + iterableCustomerListCustomer[index]);
    }
  };

  handleBack = () => {
    const { iterableCustomerListCustomer, customerListCustomerId, seasonJobId } = this.props;

    let index = iterableCustomerListCustomer.indexOf(customerListCustomerId) - 1;
    if (index >= 0) {
      this.props.navigate('/sms/queue/customer/' + seasonJobId + '/' + iterableCustomerListCustomer[index]);
    }
  };

  handleBottomReached = () => {
    const { pagination, seasonJobId, customerListCustomerId } = this.props;

    if (pagination && pagination.more) {
      this.setState({ isPaging: true });

      Promise.all([
        this.props.loadNext(seasonJobId ? seasonJobId : null, this.state.filter, [], [], customerListCustomerId),
      ]).then(
        () => {
          this.setState({ isPaging: false });
        },
        () => {
          this.setState({ isPaging: false });
        },
      );
    }
  };

  handleSeasonClick = (e, index) => {
    this.setState({ [index]: !this.state[index] });
  };

  handleMenuChange = (jobId) => {
    const { sms } = this.props;

    const resultSms = pickBy(sms, (currentSms) => {
      return (
        (!isEmpty(currentSms.previousJobs) && currentSms.previousJobs.includes(jobId))
        || (!isEmpty(currentSms.nextJobs) && currentSms.nextJobs.includes(jobId))
      );
    });

    this.handleSeasonMenuClose();
    this.setState({
      currentSeasonId: resultSms[Object.keys(resultSms)[0]].season,
      currentSelectedValues: [],
      filter: {},
    });
    this.props.navigate('/sms/queue/customer/' + jobId);
  };

  getSeasonSubMenu = (jobId) => {
    const { smsJobs, seasonJobId } = this.props;
    const job = smsJobs[jobId] ? smsJobs[jobId] : null;
    let activeJob = seasonJobId === job.id;

    return (
      <ListItemButton
        key={`menu-smsqueue-job-${jobId}`}
        selected={activeJob}
        value={job.id}
        onClick={() => this.handleMenuChange(job.id)}
      >
        <ListItemText inset primary={job.primaryText} />
        {activeJob ? <NavigationCheck /> : ''}
      </ListItemButton>
    );
  };

  renderSeasonMenu = (smsId) => {
    const { sms, seasons } = this.props;
    const { currentSeasonId } = this.state;

    const currentSms = sms[smsId] ? sms[smsId] : null;
    const season = currentSms.season && seasons[currentSms.season] ? seasons[currentSms.season] : null;
    const activeSeason = currentSeasonId === season.id;
    const seasonJob = season.id;

    if (currentSms && season && (currentSms.nextJobs || currentSms.previousJobs)) {
      const nextJobs = currentSms.nextJobs;
      const previousJobs = currentSms.previousJobs ?? [];
      const jobs =
        season.cycle === 'consecutively'
          ? nextJobs
            ? union(previousJobs, nextJobs)
            : previousJobs
          : union(previousJobs, nextJobs);

      return (
        <div key={`menu-smsqueue-main-${season.id}`}>
          <ListItemButton
            onClick={(e) => {
              this.handleSeasonClick(e, seasonJob);
            }}
            key={`menu-smsqueue-${season.id}`}
            value={season.id}
            selected={activeSeason}
          >
            <ListItemText inset primary={season.primaryText} />
            {this.state[seasonJob] ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={this.state[seasonJob]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {jobs.map(this.getSeasonSubMenu)}
            </List>
          </Collapse>
        </div>
      );
    }

    return null;
  };

  renderToolbarTitle = () => {
    const { currentSeasonId } = this.state;
    const { seasons, smsJobs, seasonJobId } = this.props;

    if (currentSeasonId && seasons[currentSeasonId] && seasonJobId && smsJobs[seasonJobId]) {
      return `${seasons[currentSeasonId].primaryText} ${smsJobs[seasonJobId].primaryText}`;
    }

    return 'SMS Vorschau';
  };

  renderToolbarMenu = () => {
    const { resultset } = this.props;

    const season = resultset ? Object.values(resultset) : [];
    const { seasonAnchorEl } = this.state;

    return (
      <div>
        <Tooltip title="Aussand wählen">
          <IconButton
            aria-label="More"
            aria-owns={seasonAnchorEl ? 'long-menu' : null}
            aria-haspopup="true"
            onClick={this.handleSeasonMenuClick}
            size="large"
          >
            <ListView />
          </IconButton>
        </Tooltip>

        <Popover
          id="simple-menu"
          open={Boolean(seasonAnchorEl)}
          anchorEl={seasonAnchorEl}
          onClose={this.handleSeasonMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <List
            component="nav"
            sx={{
              width: '100%',
              maxWidth: 360,
              backgroundColor: 'background.paper',
            }}
          >
            {season.map(this.renderSeasonMenu)}
          </List>
        </Popover>

        <Tooltip title={'Vorschau verschicken'}>
          <IconButton onClick={this.openSendDialog} size="large">
            <SmsIcon />
          </IconButton>
        </Tooltip>
      </div>
    );
  };

  renderToolbar = () => {
    return <ToolBar renderTitle={this.renderToolbarTitle} renderMenu={this.renderToolbarMenu} view="grid" />;
  };

  renderDockedList = () => {
    const { pagination, seasonJobId, customerListCustomerId } = this.props;
    const { isPaging } = this.state;

    return (
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          sx={{
            width: drawerWidth,
            top: 64,
            backgroundColor: 'neutral.800',
            zIndex: 1300,
          }}
        >
          <Typography variant="h6" noWrap>
            <div style={{ minHeight: '48px' }} />
          </Typography>
        </AppBar>
        <Drawer
          sx={{
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              top: 64,
            },
          }}
          variant="permanent"
          anchor="right"
        >
          <Box
            sx={{
              backgroundColor: 'transparent',
              width: (theme) => `calc(95% - 256px - 24px)`,
            }}
          />
          <ErrorBoundary>
            <DockedList
              jobId={seasonJobId}
              selectedIndex={customerListCustomerId}
              onItemSelect={this.handleItemSelect}
              pageable={pagination !== false}
              onBottomReached={this.handleBottomReached}
              isPaging={isPaging}
            />
          </ErrorBoundary>
        </Drawer>
      </Box>
    );
  };

  renderCustomerListCustomer = () => {
    const { customerListCustomerId } = this.props;
    const { customerListLoading } = this.state;

    return (
      <Paper
        sx={{
          minHeight: 410,
          mb: 3,
        }}
      >
        <ErrorBoundary>
          <CustomerListCustomer
            customerListCustomerId={customerListCustomerId ? customerListCustomerId : '1'}
            callable={false}
            editable={false}
            onForward={this.handleForward}
            onBack={this.handleBack}
            dataLoading={customerListLoading}
          ></CustomerListCustomer>
        </ErrorBoundary>
      </Paper>
    );
  };

  renderSmsPreview = () => {
    const { customerListCustomerId } = this.props;

    return <SmsPreview customerListCustomerId={customerListCustomerId} />;
  };

  renderSendSmsDialog = () => {
    const { customerListCustomerId } = this.props;
    const { openSendForm } = this.state;

    return (
      <SendSmsDialog
        customerListCustomerId={customerListCustomerId}
        setVisibleState={this.handleVisibleState}
        onCancel={this.closeSendDialog}
        visible={openSendForm}
      />
    );
  };

  renderContent = () => {
    return (
      <Box
        sx={{
          width: (theme) => `calc(100% - 256px)`,
        }}
      >
        {this.renderToolbar()}
        {this.renderDockedList()}
        {this.renderCustomerListCustomer()}
        {this.renderSmsPreview()}
        {this.renderSendSmsDialog()}
      </Box>
    );
  };

  render() {
    const { smsQueueLoading } = this.state;
    if (smsQueueLoading) {
      return (
        <Paper>
          <Translate>Data loading...</Translate>
        </Paper>
      );
    }
    return this.renderContent();
  }
}

const makeMapStateToProps = () => {
  const getCustomerListCustomerByCl = makeCustomerListCustomerByCl();
  return (state, props) => {
    const { seasonJobId, customerListCustomerId } = props.match.params;
    const {
      entities: { sms, season, smsJob },
      resultsets: { sms: resultset },
      pagination: { customerList: pagination },
    } = state;

    const customerListId =
      seasonJobId && smsJob
        ? smsJob[seasonJobId].customerList
        : sms && smsJob && resultset && sms[resultset[0]].nextJobs
          ? smsJob[sms[resultset[0]].nextJobs[0]].customerList
          : null;

    return {
      customerListCustomerId: customerListCustomerId ? parseInt(customerListCustomerId) : null,
      seasonJobId: seasonJobId ? parseInt(seasonJobId) : null,
      customerListId: customerListId,
      smsJobs: smsJob,
      resultset: resultset,
      pagination: pagination && pagination[customerListId] ? pagination[customerListId] : false,
      iterableCustomerListCustomer: getCustomerListCustomerByCl(state, props),
      sms: !isEmpty(sms) ? sms : {},
      seasons: !isEmpty(season) ? season : {},
    };
  };
};

const enhance = compose(
  withRouter,
  connect(makeMapStateToProps, {
    loadSmsQueues,
    loadSmsQueueCustomersPaged,
    loadNext,
  }),
);

export default enhance(SmsQueue);

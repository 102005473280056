import React from 'react';
import { useAppSelector } from '../../hooks';
import IFrame from '../../components/IFrame';
import { SeedsModeEnum } from '../../utils/Enums';

function Help() {
  const seedsMode = useAppSelector((state) => state.app.seedsMode);
  const helpUrl =
    seedsMode === SeedsModeEnum.MBVD
      ? 'https://seeds-static.eeloy.com/hilfebereich-niederlassungen/'
      : 'https://seeds-static.eeloy.com/hilfebereich-servicepartner/';

  return <IFrame title="help" url={helpUrl} />;
}

export default Help;

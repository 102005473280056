import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';

export const MAILINGMODULEBYTYPE_REQUEST = 'MAILINGMODULEBYTYPE_REQUEST';
export const MAILINGMODULEBYTYPE_SUCCESS = 'MAILINGMODULEBYTYPE_SUCCESS';
export const MAILINGMODULEBYTYPE_FAILURE = 'MAILINGMODULEBYTYPE_FAILURE';

function fetchMailingModulesByMode(mode) {
  return {
    [CALL_API]: {
      types: [MAILINGMODULEBYTYPE_REQUEST, MAILINGMODULEBYTYPE_SUCCESS, MAILINGMODULEBYTYPE_FAILURE],
      endpoint: Routing.generate('mailingmodule_showByMode', { mode: mode }, true),
      schema: Schemas.MAILINGMODULES,
    },
  };
}

export function loadMailingModulesByMode(mode) {
  return (dispatch) => {
    return dispatch(fetchMailingModulesByMode(mode));
  };
}

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Collapse,
  IconButton,
  Button,
  TextField,
  Input,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { isEqual } from 'lodash';
import DeleteIcon from '@mui/icons-material/Delete';
import Upload from '../../../components/form/Upload';
import Translate from '../../../components/service/Translate';
import BrandCompany from '../../Car/components/BrandCompany';
import Tags from './Tags';
import { FormCheckbox } from '../../../components/StyledElements/StyledFormElements';
import Grid from '@mui/material/Grid2';
import { HiddenInput } from '../../../components/StyledElements/StyledElements';

class Child extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    onAdd: PropTypes.func.isRequired,
    onRemove: PropTypes.func.isRequired,
    values: PropTypes.object,
  };

  static defaultProps = {
    values: {},
    style: {},
  };

  state = {
    expanded: false,
  };

  componentDidMount = () => {
    this.props.formikProps.setFieldValue(`children[${this.props.index}]`, this.props.values);
  };

  // eslint-disable-next-line no-unused-vars
  componentDidUpdate(prevProps, prevState) {
    if (!isEqual(this.props.submitCount, prevProps.submitCount) && this.props.errors) {
      this.setState({ expanded: true });
    }
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(this.props.values, nextProps.values)
      || !isEqual(nextState.expanded, this.state.expanded)
      || !isEqual(this.props.touched, nextProps.touched)
      || !isEqual(this.props.errors, nextProps.errors)
    );
  };

  handleRemove = () => {
    this.props.onRemove(this.props.index);
    let children = this.props.formikProps.values.children;
    children.splice(this.props.index, 1);
    this.props.formikProps.setFieldValue('children', children);
  };

  handleExpandClick = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded });
  };

  renderFieldset = (values) => {
    const { index } = this.props;
    const { touched, errors, handleChange, handleBlur, setFieldValue } = this.props.formikProps;
    const errorCheck = touched.children && errors.children && touched.children[index] && errors.children[index];

    return (
      <Grid container spacing={3}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            label={<Translate>Name</Translate>}
            name={`children[${index}][name]`}
            defaultValue={values.name ? values.name : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errorCheck && errors.children[index].name ? errors.children[index].name : ''}
            error={errorCheck && errors.children[index].name && Boolean(errors.children[index].name)}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            label={<Translate>City</Translate>}
            name={`children[${index}][city]`}
            defaultValue={values.city ? values.city : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errorCheck && errors.children[index].city ? errors.children[index].city : ''}
            error={errorCheck && errors.children[index].city && Boolean(errors.children[index].city)}
          />
        </Grid>
        <Grid size={12}>
          <Typography variant="h6">
            <Translate>Sender</Translate>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            label={<Translate>Reply to</Translate>}
            name={`children[${index}][replyTo]`}
            defaultValue={values.replyTo ? values.replyTo : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errorCheck && errors.children[index].replyTo ? errors.children[index].replyTo : ''}
            error={errorCheck && errors.children[index].replyTo && Boolean(errors.children[index].replyTo)}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            label={<Translate>Postbox</Translate>}
            name={`children[${index}][poBox]`}
            defaultValue={values.poBox ? values.poBox : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errorCheck && errors.children[index].poBox ? errors.children[index].poBox : ''}
            error={errorCheck && errors.children[index].poBox && Boolean(errors.children[index].poBox)}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            label={<Translate>Postbox ZIP</Translate>}
            name={`children[${index}][poZip]`}
            defaultValue={values.poZip ? values.poZip : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errorCheck && errors.children[index].poZip ? errors.children[index].poZip : ''}
            error={errorCheck && errors.children[index].poZip && Boolean(errors.children[index].poZip)}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            label={<Translate>Postbox City</Translate>}
            name={`children[${index}][poCity]`}
            defaultValue={values.poCity ? values.poCity : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={errorCheck && errors.children[index].poZip ? errors.children[index].poZip : ''}
            error={errorCheck && errors.children[index].poZip && Boolean(errors.children[index].poZip)}
          />
        </Grid>
        <Grid size={12}>
          <Typography variant="h6">
            <Translate>Appointment contact</Translate>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            label={<Translate>Phone</Translate>}
            name={`children[${index}][appointmentPhone]`}
            defaultValue={values.appointmentPhone ? values.appointmentPhone : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errorCheck && errors.children[index].appointmentPhone ? errors.children[index].appointmentPhone : ''
            }
            error={
              errorCheck && errors.children[index].appointmentPhone && Boolean(errors.children[index].appointmentPhone)
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            label={<Translate>Web</Translate>}
            name={`children[${index}][appointmentWww]`}
            defaultValue={values.appointmentWww ? values.appointmentWww : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errorCheck && errors.children[index].appointmentWww ? errors.children[index].appointmentWww : ''
            }
            error={
              errorCheck && errors.children[index].appointmentWww && Boolean(errors.children[index].appointmentWww)
            }
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <TextField
            label={<Translate>Email</Translate>}
            name={`children[${index}][appointmentEmail]`}
            defaultValue={values.appointmentEmail ? values.appointmentEmail : ''}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={
              errorCheck && errors.children[index].appointmentEmail ? errors.children[index].appointmentEmail : ''
            }
            error={
              errorCheck && errors.children[index].appointmentEmail && Boolean(errors.children[index].appointmentEmail)
            }
          />
        </Grid>
        <Grid size={12}>
          <Typography variant="h6">
            <Translate>Letter paper</Translate>
          </Typography>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormCheckbox
            name={`children[${index}][showingLocations]`}
            label={<Translate>Showing locations below title?</Translate>}
            checked={values?.children?.[index]?.showingLocations ?? false}
            setFieldValue={setFieldValue}
            error={touched?.children?.[index]?.showingLocations && errors?.children?.[index]?.showingLocations}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormCheckbox
            name={`children[${index}][showingCity]`}
            label={<Translate>Showing city ahead of date?</Translate>}
            checked={values?.children?.[index]?.showingCity ?? false}
            setFieldValue={setFieldValue}
            error={touched?.children?.[index]?.showingCity && errors?.children?.[index]?.showingCity}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Tags
            name={`children[${index}]`}
            touched={touched.children && touched.children[index] ? touched.children[index] : ''}
            errors={errors.children && errors.children[index] ? errors.children[index] : ''}
            values={values}
            handleChange={handleChange}
          />
        </Grid>
        <Grid size={12}>
          <BrandCompany
            name={`children[${index}]`}
            values={values}
            handleChange={handleChange}
            touched={touched.children && touched.children[index] ? touched.children[index] : ''}
            errors={errors.children && errors.children[index] ? errors.children[index] : ''}
            enabledOnly={true}
          />
          <HiddenInput
            type="hidden"
            name={`children[${index}][premium]`}
            value={values.premium ? values.premium : ''}
          />
          <HiddenInput type="hidden" name={`children[${index}][id]`} value={values.id ? values.id : ''} />
        </Grid>
        <Grid size={12}>
          <Typography variant="h6">
            <Translate>Premium Upload</Translate>
          </Typography>
        </Grid>
        <Grid size={12}>
          <Upload
            label={<Translate wildcards={{ '%type%': 'Premium Label PDF-X3' }}>Upload file %type%</Translate>}
            name={`children[${index}][filePremium]`}
            setFieldValue={setFieldValue}
          />
          <HiddenInput
            type="hidden"
            name={`children[${index}][premium]`}
            value={values.premium ? values.premium : ''}
          />
        </Grid>
        <Grid size={12}>
          <Typography variant="h6">
            <Translate>Logo Upload</Translate>
          </Typography>
        </Grid>
        <Grid size={12}>
          <Upload
            label={<Translate wildcards={{ '%type%': 'Vorlage png Max. Höhe: 80px' }}>Upload file %type%</Translate>}
            name={`children[${index}][fileLogo]`}
            setFieldValue={setFieldValue}
          />
          <HiddenInput type="hidden" name={`children[${index}][logo]`} value={values.logo ? values.logo : ''} />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { values } = this.props;
    const { expanded } = this.state;

    if (!values) {
      return null;
    }

    return (
      <Card sx={{ p: 0 }}>
        <CardHeader
          title={values.name}
          subheader={<Translate>{values.tag}</Translate>}
          action={
            <IconButton
              onClick={this.handleExpandClick}
              aria-expanded={expanded}
              aria-label="Show more"
              sx={expanded ? { transform: 'rotate(180deg)' } : {}}
              size="large"
            >
              <ExpandMoreIcon />
            </IconButton>
          }
        />
        <Collapse in={this.state.expanded} timeout="auto" unmountOnExit>
          <CardContent>{this.renderFieldset(values)}</CardContent>
        </Collapse>
        <CardActions>
          <Button variant="outlined" color="error" onClick={this.handleRemove}>
            <DeleteIcon />
            <Translate>remove</Translate>
          </Button>
        </CardActions>
      </Card>
    );
  }
}

export default Child;

import React, { Component } from 'react';
import { Paper } from '@mui/material';
import { connect, ConnectedProps } from 'react-redux';
import { isEqual } from 'lodash';
import { GridRowId, GridSortDirection } from '@mui/x-data-grid';
import Translate from '../../components/service/Translate';
import { deleteEmailAccount, fetchAllEmailAccounts, fetchToken, writeEmailAccount } from './EmailAccountSlice';
import EmailAccountList from './components/List';
import DeleteDialog from '../../components/DeleteDialog';
import ErrorBoundary from '../../components/ErrorBoundary';
import { withRouter, WithRouterProps } from '../../withRouter';
import { RootState } from '../../reducers';
import { GridParams } from '../../components/DataGrid/DataGridTypes';
import paths from '../../paths';

type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux & WithRouterProps;

type EmailAccountState = {
  current: GridRowId | null;
  deleting: boolean;
  dataLoading: boolean;
  sortColumns: string[];
  sortOrders: GridSortDirection[];
};

class EmailAccount extends Component<Props, EmailAccountState> {
  constructor(props: Props) {
    super(props);
    this.state = {
      current: null,
      deleting: false,
      sortColumns: ['host', 'id'],
      sortOrders: ['asc', 'asc'],
      dataLoading: true,
    };
  }

  componentDidMount() {
    const { fetchToken, fetchAllEmailAccounts } = this.props;

    fetchToken();
    this.setState({ dataLoading: true });
    const gridParams: GridParams = {
      paginationModel: {
        page: 0,
        pageSize: 100,
      },
    };
    Promise.all([fetchAllEmailAccounts({ gridParams })]).then(
      () => {
        this.setState({ dataLoading: false });
      },
      () => {
        this.setState({ dataLoading: false });
      },
    );
  }

  shouldComponentUpdate(nextProps: Props, nextState: EmailAccountState) {
    const { accounts, defaultIndex } = this.props;

    return (
      !isEqual(nextProps.accounts, accounts)
      || !isEqual(nextState, this.state)
      || !isEqual(nextProps.defaultIndex, defaultIndex)
    );
  }

  componentDidUpdate(prevProps: Props) {
    const { defaultIndex } = this.props;

    if (defaultIndex && prevProps.defaultIndex !== defaultIndex) {
      this.setState({ current: defaultIndex });
    }
  }

  handleDelete = (accountId: number) => {
    const { accounts, deleteEmailAccount, fetchAllEmailAccounts, navigate, sortModel, paginationModel } = this.props;

    this.setState({ deleting: false, current: null });

    Promise.all([
      deleteEmailAccount({
        current: accountId,
        deleteToken: accounts[accountId]!.deleteToken,
      }),
    ]).then(
      () => {
        fetchAllEmailAccounts({ gridParams: { sortModel, paginationModel } });
        navigate(Routing.generate('emailaccount_index'));
      },
      () => {
        fetchAllEmailAccounts({ gridParams: { sortModel, paginationModel } });
      },
    );
  };

  onTouchEdit = (current: GridRowId) => {
    const { navigate } = this.props;
    navigate(paths.emailAccount.edit.replace(':accountId', current.toString()));
  };

  onTouchAdd = () => {
    const { navigate } = this.props;
    navigate(paths.emailAccount.create);
  };

  onTouchDelete = (current: GridRowId) => {
    this.setState({ current, deleting: true });
  };

  onTouchCancel = () => {
    this.setState({
      deleting: false,
    });
  };

  renderDeleteDialog = () => {
    const { accounts } = this.props;
    const { deleting, current } = this.state;

    if (deleting && current) {
      return <DeleteDialog current={accounts[+current]} onCancel={this.onTouchCancel} onSubmit={this.handleDelete} />;
    }

    return null;
  };

  renderContent() {
    const { dataLoading } = this.state;

    if (dataLoading) {
      return (
        <Paper>
          <Translate>Data loading...</Translate>
        </Paper>
      );
    }
    return (
      <ErrorBoundary>
        <EmailAccountList onAdd={this.onTouchAdd} onEdit={this.onTouchEdit} onDelete={this.onTouchDelete} />
      </ErrorBoundary>
    );
  }

  render() {
    return (
      <>
        {this.renderContent()}
        {this.renderDeleteDialog()}
      </>
    );
  }
}

function mapStateToProps(state: RootState) {
  const {
    emailAccount: { resultset, pagination, entities, paginationModel, sortModel },
  } = state;

  const accounts = entities;

  return {
    accounts,
    defaultIndex: accounts && resultset ? resultset[0] : null,
    pagination,
    sortModel,
    paginationModel,
  };
}

const connector = connect(mapStateToProps, {
  fetchToken,
  deleteEmailAccount,
  fetchAllEmailAccounts,
  writeEmailAccount,
});

export default withRouter(connector(EmailAccount));

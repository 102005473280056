import PropTypes from 'prop-types';
import React, { Component } from 'react';

class Currency extends Component {
  static propTypes = {
    decimals: PropTypes.number,
    currency: PropTypes.string,
    decimalsPoint: PropTypes.string,
    seperator: PropTypes.string,
  };

  static defaultProps = {
    decimals: 2,
    currency: '€',
    decimalsPoint: ',',
    seperator: '.',
  };

  fixedLength = (number, decimals) => {
    let multiplier = Math.pow(10, decimals);

    return '' + Math.round(number * multiplier) / multiplier;
  };

  parse = (number) => {
    const { seperator } = this.props;

    number = ('' + number).replace(/[^0-9+-Ee.]/g, '');
    number = !isFinite(+number) ? 0 : +number;

    let decimals = !isFinite(+this.props.decimals) ? 2 : Math.abs(this.props.decimals);

    let parsed = (decimals ? this.fixedLength(number, decimals) : '' + Math.round(number)).split('.');
    if (parsed[0].length > 3) {
      parsed[0] = parsed[0].replace(/\d{1,3}(?=(\d{3})+(?!\d))/g, '$&' + seperator);
    }
    if ((parsed[1] || '').length < decimals) {
      parsed[1] = parsed[1] || '';
      parsed[1] += new Array(decimals - parsed[1].length + 1).join('0');
    }

    return parsed;
  };

  render() {
    const { currency, decimalsPoint } = this.props;

    let formated = this.parse(this.props.children).join(decimalsPoint);

    return <span>{`${formated} ${currency}`}</span>;
  }
}

export default Currency;

import { pickBy } from 'lodash';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../reducers';

const getLocations = (state: RootState) => state.location.entities;
const getAffiliates = (state: RootState) => state.affiliate.entities;

export const makeHeadLocations = () =>
  createSelector([getLocations], (locations) => pickBy(locations, { isChild: false }));

export const makeSelectableAffiliates = () =>
  createSelector([getAffiliates], (affiliates) => pickBy(affiliates, { parent: null }));

import React, { Component } from 'react';
import EeloyClaim from '../../../theme/icons/EeloyClaim';

type WhiteLabelClaimProps = {
  width?: number;
  height?: number;
  fill?: string;
};

type WhiteLabelClaimState = NonNullable<unknown>;

class WhiteLabelClaim extends Component<WhiteLabelClaimProps, WhiteLabelClaimState> {
  static defaultProps = {
    width: 75,
    height: 30,
    fill: '#fff',
  };

  shouldComponentUpdate() {
    return false;
  }

  render() {
    const { width, height, fill } = this.props;

    return <EeloyClaim style={{ width, height, fill }} />;
  }
}

export default WhiteLabelClaim;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Translate from '../../../components/service/Translate';
import DateTime from '../../../components/service/DateTime';
import { Table, TableData } from '../../../components/StyledElements/StyledElements';
import { Box } from '@mui/material';

class Log extends Component {
  static propTypes = {
    affiliateId: PropTypes.number.isRequired,
  };

  render() {
    const { affiliate } = this.props;

    if (!affiliate) {
      return null;
    }

    return (
      <Box sx={{ pt: 3 }}>
        <Table width="100%">
          <thead>
            <tr>
              <TableData width="15%">
                <Translate>Date</Translate>
              </TableData>
              <TableData width="15%">
                <Translate>Activity</Translate>
              </TableData>
            </tr>
          </thead>
          <tbody>
            <tr key="affiliatelog_created">
              <TableData width="15%">
                <DateTime format="dd.mm.yyyy HH:MM">{affiliate.created}</DateTime>
              </TableData>
              <TableData width="35%">
                <Translate>Created</Translate>
              </TableData>
            </tr>
            <tr key="affiliatelog_updated">
              <TableData width="15%">
                <DateTime format="dd.mm.yyyy HH:MM">{affiliate.updated}</DateTime>
              </TableData>
              <TableData width="35%">
                <Translate>Updated</Translate>
              </TableData>
            </tr>
          </tbody>
        </Table>
      </Box>
    );
  }
}

function mapStateToProps(state, ownProps) {
  const affiliateId = ownProps.affiliateId;
  const {
    entities: { affiliate },
  } = state;

  return { affiliate: affiliate[affiliateId] };
}

export default connect(mapStateToProps, {})(Log);

import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { Navigate } from 'react-router-dom';
import { resetDownload } from '../../actions';
import Download from '../../components/Download';
import AppNavDrawer from '../Default/components/AppNavDrawer';
import { useAppSelector } from '../../hooks';
import Loading from '../../components/Loading';

function AppLayout() {
  const isAuthenticated = useAppSelector((state) => state.security.isAuthenticated);
  const download = useAppSelector((state) => state.downloads);
  const loading = useAppSelector((state) => state.loading.length > 0 || state.loadingSlices > 0);

  const handleDownloadMount = (link: React.RefObject<HTMLAnchorElement>) => {
    if (link.current) {
      link.current.click();
    }
  };

  const renderDownload = () => {
    if (!download) {
      return null;
    }

    return (
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        open={!!download}
        onClose={resetDownload}
        autoHideDuration={6500}
        key="bottomcenter"
      >
        <Alert severity="info" variant="filled">
          <Download file={download.path} onMount={handleDownloadMount} label="Your download is starting!" />
        </Alert>
      </Snackbar>
    );
  };

  return !isAuthenticated ? (
    <Navigate to="/login" />
  ) : (
    <>
      <AppNavDrawer />
      {loading && <Loading />}
      {renderDownload()}
    </>
  );
}

export default AppLayout;

import React from 'react';
import { TableRow, TableCell, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { ReportTableRow as ReportTableRowType } from '../../StatisticCampaignTypes';
import ReportTableCell from './ReportTableCell';
import Translate from '../../../../components/service/Translate';

type ReportTableRowProps = {
  row: ReportTableRowType;
};

export default function ReportTableRow({ row }: ReportTableRowProps): React.JSX.Element {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Translate context="campaignVersion">{row.label}</Translate>
        </TableCell>
        <TableCell align="right">{row.sent.toLocaleString('de-DE')}</TableCell>
        <ReportTableCell count={row.delivered} total={row.sent} />
        <ReportTableCell count={row.openedEmail} total={row.delivered} />
        <ReportTableCell count={row.openedWeb} total={row.openedEmail} />
      </TableRow>
      {row.children?.map((collapsableRow: ReportTableRowType, index) => (
        <TableRow key={collapsableRow.label} sx={{ visibility: open ? 'visible' : 'collapse' }}>
          {row.children && index !== row.children.length - 1 ? (
            <TableCell sx={{ borderBottom: 'unset' }} />
          ) : (
            <TableCell />
          )}
          <TableCell component="th" scope="row">
            <Translate context="customerGroups">{collapsableRow.label}</Translate>
          </TableCell>
          <TableCell align="right">{collapsableRow.sent.toLocaleString('de-DE')}</TableCell>
          <ReportTableCell count={collapsableRow.delivered} total={collapsableRow.sent} />
          <ReportTableCell count={collapsableRow.openedEmail} total={collapsableRow.delivered} />
          <ReportTableCell count={collapsableRow.openedWeb} total={collapsableRow.openedEmail} />
        </TableRow>
      ))}
    </>
  );
}

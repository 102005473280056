import React, { ReactNode } from 'react';
import Translate from './components/service/Translate';

const jsxMessage = (message: ReactNode) => () => message;

const ValidationErrors = {
  required: jsxMessage(<Translate>This field should not be empty.</Translate>),
  requiredSelect: jsxMessage(<Translate>Please choose an option.</Translate>),
  requiredPasswordConfirm: jsxMessage(<Translate>Passwords do not match.</Translate>),
  typeErrorNumber: jsxMessage(<Translate>Please use only numbers and ..</Translate>),
  typeErrorNumberWithComma: jsxMessage(<Translate>Please use only numbers and . or ,.</Translate>),
  cmykRegexMessage: jsxMessage(<Translate>Please use only CMYK farb definitions.</Translate>),
  email: jsxMessage(<Translate>Enter a valid email.</Translate>),
  positiveIntNumber: jsxMessage(<Translate>Enter only positive integral numbers</Translate>),
  url: jsxMessage(<Translate>Enter valid url.</Translate>),
  phone: jsxMessage(<Translate>Enter valid phone number.</Translate>),
  configKeyRegexMessage: jsxMessage(
    <Translate>Enter a valid config key. Allowed: all alphanumeric characters and underscore.</Translate>,
  ),
  incorrectRole: jsxMessage(<Translate>This role is not a valid choice.</Translate>),
  passwordRule: jsxMessage(
    <Translate>
      The password must contain at least 8 characters from 3 of the following 4 categories: capitals, lower case,
      numbers, special characters.
    </Translate>,
  ),
  delayIntervalFormat: jsxMessage(<Translate>season.delayInterval wrong format</Translate>),
  requiredBlockDesign: jsxMessage(<Translate>The Editor should not be empty.</Translate>),
  requiredSignatureFile: jsxMessage(<Translate>Choose a signature to upload.</Translate>),
  requiredSignature: jsxMessage(<Translate>Add signature to at least one mailing.</Translate>),
  invalidDateFormat: jsxMessage(<Translate>Use a valid date format.</Translate>),
  noDuplicates: jsxMessage(<Translate>No Duplicates.</Translate>),
};

export default ValidationErrors;

import { CALL_API } from '../../middleware';
import { Schemas } from '../../schemas';
import { sample } from 'lodash';

export const EMAILS_REQUEST = 'EMAILS_REQUEST';
export const EMAILS_SUCCESS = 'EMAILS_SUCCESS';
export const EMAILS_FAILURE = 'EMAILS_FAILURE';

function fetchEmails(sortColumns, sortOrders) {
  return {
    [CALL_API]: {
      types: [EMAILS_REQUEST, EMAILS_SUCCESS, EMAILS_FAILURE],
      endpoint: Routing.generate('email_index', { sortColumns: sortColumns, sortOrders: sortOrders }, true),
      schema: Schemas.EMAILS,
    },
  };
}

// eslint-disable-next-line no-unused-vars
export function loadEmails(sortColumns = [], sortOrders = [], requiredFields = []) {
  return (dispatch, getState) => {
    const email = getState().entities.email;
    const resultset = getState().resultsets.email;
    const outdated = getState().outdated.email;

    if (
      !outdated
      && resultset
      && resultset.length
      && email
      && requiredFields.every((key) => sample(email).hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchEmails());
  };
}

export const EMAIL_REQUEST = 'EMAIL_REQUEST';
export const EMAIL_SUCCESS = 'EMAIL_SUCCESS';
export const EMAIL_FAILURE = 'EMAIL_FAILURE';

function fetchEmail(id) {
  return {
    [CALL_API]: {
      types: [EMAIL_REQUEST, EMAIL_SUCCESS, EMAIL_FAILURE],
      endpoint: Routing.generate('email_show', { id: id }, true),
      schema: Schemas.EMAIL,
    },
  };
}

export function loadEmail(id, requiredFields = []) {
  return (dispatch, getState) => {
    const emails = getState().entities.email;
    const outdated = getState().outdated.email;

    if (!outdated && emails && emails[id] && requiredFields.every((key) => emails[id].hasOwnProperty(key))) {
      return null;
    }

    return dispatch(fetchEmail(id));
  };
}

export const EMAIL_SEASON_REQUEST = 'EMAIL_SEASON_REQUEST';
export const EMAIL_SEASON_SUCCESS = 'EMAIL_SEASON_SUCCESS';
export const EMAIL_SEASON_FAILURE = 'EMAIL_SEASON_FAILURE';

function fetchEmailBySeason(seasonId) {
  return {
    [CALL_API]: {
      types: [EMAIL_SEASON_REQUEST, EMAIL_SEASON_SUCCESS, EMAIL_SEASON_FAILURE],
      endpoint: Routing.generate('email_bySeason_show', { id: seasonId }, true),
      schema: Schemas.EMAIL,
    },
  };
}

export function loadEmailBySeason(seasonId, requiredFields = []) {
  return (dispatch, getState) => {
    const emails = getState().entities.email;
    const seasons = getState().entities.season;
    const outdated = getState().outdated.email;

    if (
      !outdated
      && seasons
      && seasons[seasonId]
      && seasons[seasonId].email
      && emails
      && emails[seasons[seasonId].email]
      && requiredFields.every((key) => emails[seasons[seasonId].email].hasOwnProperty(key))
    ) {
      return null;
    }

    return dispatch(fetchEmailBySeason(seasonId));
  };
}

export default {
  app: {
    en: 'Englisch',
    de: 'Deutsch',
    Einstellungen: 'Properties',
    female: 'Frau',
    male: 'Herr',
    company: 'Firma',
    false: 'nein',
    true: 'ja',
    passengercar: 'PKW',
    truck: 'LKW',
    transporter: 'Transporter',
    trailer: 'Anhänger',
    bus: 'Bus',
    other: 'Sonstige',
    completed: 'abgeschlossen',
    ineffective: 'nicht erfolgreich',
    ongoing: 'in Bearbeitung',
    unsolicited: 'nicht erfolgreich',
    ROLE_PRO_SUPERADMIN: 'SuperAdministrator',
    ROLE_FREE_SUPERADMIN: 'SuperAdministrator',
    ROLE_PRO_CALL: 'Call Agent',
    ROLE_PRO_CALLMANAGER: 'Call Manager',
    ROLE_PRO_CHECKOUT: 'Kasse',
    ROLE_PRO_STAFFLIMITED: 'Mitarbeiter (eingeschränkt)',
    ROLE_PRO_STAFF: 'Mitarbeiter',
    ROLE_PRO_ADMIN: 'Adminstrator',
    ROLE_FREE_CALL: 'Call Agent',
    ROLE_FREE_CALLMANAGER: 'Call Manager',
    ROLE_FREE_CHECKOUT: 'Kasse',
    ROLE_FREE_STAFFLIMITED: 'Mitarbeiter (eingeschränkt)',
    ROLE_FREE_STAFF: 'Mitarbeiter',
    ROLE_FREE_ADMIN: 'Adminstrator',
    personal: 'Entschuldigung vor Ort',
    voucher: 'Gutschrift oder Gutschein (Wert) ausstellen',
    followup: 'Nacharbeit erforderlich / neuer Termin vereinbaren',
    call: 'Entschuldigung durch Rückruf',
    letter: 'Entschuldigung durch Brief',
    adherence: 'Termintreue',
    garagequality: 'Werkstattqualität',
    staff: 'Freundlichkeit/Personal',
    productquality: 'Produktqualität',
    price: 'Preis / Leistung',
    created: 'eröffnet',
    'header.help': 'Hilfe',
    revision: 'revision',
    uploaded: 'uploaded',
    processing: 'processing',
    imported: 'imported',
    denied: 'denied',
  },
  form: {
    words: 'Bitte nur Buchstaben verwenden',
    maxLength: 'Die max. Anzahl an Zeichen wurde überschritten',
    isEqual: 'Die angegebenen Passwörter stimmen nicht überein.',
    isFloat: 'Bitte nur Zahlen und "." verwenden.',
    isEuro: 'Bitte nur Zahlen und "," verwenden.',
    isPhone: 'Bitte eine gültige Telefonnr. verwenden. Erlaubte Zeichen Zahlen, Leerzeichen, +, -',
    isEmail: 'Bitte eine gültige E-Mail Adresse verwenden.',
    isAlphanumeric: 'Bitte nur Zahlen und Buchstaben verwenden.',
    isUsername: 'Bitte nur Zahlen und Buchstaben verwenden, max. 1 Wort erlaubt.',
    numeric: 'Bitte nur Zahlen verwenden.',
    url: 'Bitte eine gültige URL eingeben verwenden.',
  },
};

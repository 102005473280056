import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { Schemas } from '../../schemas';
import type { RootState } from '../../reducers';
import { MenuItem } from './MenuItemType';
import handleAxiosError, { RejectedValue } from '../../utils/ErrorHandler';

const menuAdapter = createEntityAdapter<MenuItem>({});

type LoadMenuPayload = {
  entities: {
    menuitem: Record<string, MenuItem>;
  };
};

export const loadMenu = createAsyncThunk<LoadMenuPayload, void, { rejectValue: RejectedValue }>(
  'menu/loadMenu',
  async (_, { rejectWithValue }) =>
    axios
      .get(Routing.generate('menu_v3', null, true), {
        withCredentials: true,
      })
      .then((response) => {
        const normalized = normalize<
          schema.Array<MenuItem>,
          {
            menuitem: Record<string, MenuItem>;
          },
          string[]
        >(response.data, Schemas.MENU);

        return { entities: normalized.entities };
      })
      .catch((err) => rejectWithValue(handleAxiosError(err))),
);

type SliceState = {
  loading: boolean;
  error?: RejectedValue;
};

const initialState: SliceState = {
  loading: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState: menuAdapter.getInitialState(initialState),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadMenu.fulfilled, (state, action) => {
      const { menuitem } = action.payload.entities;
      state.error = undefined;

      if (menuitem) {
        menuAdapter.upsertMany(state, menuitem);
      }
    });
    builder.addCase(loadMenu.rejected, (state, action) => {
      state.error = action.payload;
    });
  },
});

export const {
  selectAll: selectAllMenuItems,
  selectById: selectMenuItemById,
  selectIds,
} = menuAdapter.getSelectors<RootState>((state) => state.menu);

export default menuSlice.reducer;

import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import SeasonCausality from './SeasonCausality';
import { withRouter } from '../../withRouter';

class SeasonCausalityIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN', 'ROLE_PRO'],
  };

  render() {
    return (
      <ACL authorized={this.props.requiredRoles}>
        <SeasonCausality campaignId={this.props.match.params.campaignId} seasonId={this.props.match.params.seasonId} />
      </ACL>
    );
  }
}

export default withRouter(SeasonCausalityIndex);

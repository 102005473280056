import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Translate from '../../../components/service/Translate';
import { isEqual, isEmpty } from 'lodash';
import Log from './Log';
import Edit from './Edit';
import Show from './Show';
import Mailings from './Mailings';
import FullWidthTabs from '../../../components/FullWidthTabs';
import ClippedDrawer from '../../../components/ClippedDrawer';
import ErrorBoundary from '../../../components/ErrorBoundary';
import { PaperWidthDockedList } from '../../../components/StyledElements/StyledElements';

class Grid extends Component {
  static defaultProps = {
    mode: 'show',
  };

  // eslint-disable-next-line no-unused-vars
  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(nextProps.letters, this.props.letters)
      || !isEqual(nextProps.mode, this.props.mode)
      || !isEqual(nextProps.loading, this.props.loading)
      || !isEqual(nextProps.success, this.props.success)
      || !isEqual(nextProps.current, this.props.current)
    );
  };

  renderContent() {
    const { letters, onFormMount, onSubmit, onValidityChange, mode, current, bindSubmitForm } = this.props;

    if (mode === 'adding') {
      return (
        <ErrorBoundary>
          <Edit
            onMount={onFormMount}
            bindSubmitForm={bindSubmitForm}
            onSubmit={onSubmit}
            onValidityChange={onValidityChange}
            letterId={null}
          />
        </ErrorBoundary>
      );
    }

    if (mode === 'editing') {
      return (
        <ErrorBoundary>
          <Edit
            onMount={onFormMount}
            bindSubmitForm={bindSubmitForm}
            onSubmit={onSubmit}
            onValidityChange={onValidityChange}
            letterId={current}
          />
        </ErrorBoundary>
      );
    }

    if (isEmpty(letters)) {
      return <Translate>No entries found.</Translate>;
    }

    return (
      <ErrorBoundary>
        <Show letterId={current} />
      </ErrorBoundary>
    );
  }

  render() {
    const { letters, current, onSelection, resultset, loading, success } = this.props;

    const tabs = [
      { title: 'Settings', content: this.renderContent() },
      {
        title: 'Log',
        content: (
          <ErrorBoundary>
            <Log letterId={parseInt(current)} />
          </ErrorBoundary>
        ),
      },
    ];

    const tabSecond = [
      {
        title: 'Mailings',
        content: (
          <ErrorBoundary>
            <Mailings noPaper={true} embedded={true} letterId={current} />
          </ErrorBoundary>
        ),
      },
    ];

    if (!isEmpty(loading) && !success.includes('LETTERS')) {
      return (
        <PaperWidthDockedList>
          {' '}
          <Translate>Data loading...</Translate>{' '}
        </PaperWidthDockedList>
      );
    }
    return (
      <>
        <ErrorBoundary>
          <ClippedDrawer
            onItemSelect={onSelection}
            resultset={resultset}
            listItems={letters}
            defaultSelectedIndex={current}
          />
        </ErrorBoundary>

        <PaperWidthDockedList>
          <ErrorBoundary>
            <FullWidthTabs tabs={tabs} />
          </ErrorBoundary>
        </PaperWidthDockedList>
        {!isEmpty(letters) ? (
          <PaperWidthDockedList>
            <ErrorBoundary>
              {' '}
              <FullWidthTabs tabs={tabSecond} />
            </ErrorBoundary>
          </PaperWidthDockedList>
        ) : null}
      </>
    );
  }
}

Grid.propTypes = {
  resultset: PropTypes.array.isRequired,
  letters: PropTypes.object.isRequired,
  onFormMount: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onValidityChange: PropTypes.func.isRequired,
  onSelection: PropTypes.func.isRequired,
  mode: PropTypes.string,
  current: PropTypes.number,
};

export default Grid;

import React, { Component } from 'react';
import { withRouter } from '../../../withRouter';
import { connect } from 'react-redux';

import { cloneDeep, isEmpty } from 'lodash';
import ACL from '../../../components/ACL';
import FullWidthTabs from '../../../components/FullWidthTabs';
import ProductEdit from './ProductEdit';
import { makeChildProduct } from '../selectors';
import { compose } from 'redux';

class ProductTab extends Component {
  handleAddTab = (tabList) => {
    let tabListCopy = cloneDeep(tabList);
    tabListCopy.push({
      title: 'Kind ' + tabListCopy.length,
      content: <ProductEdit master={false} onCancel={this.props.onCancel} />,
    });

    return tabListCopy;
  };

  render() {
    const { childProduct, onCancel, onDelete } = this.props;

    const tabs = [
      {
        title: 'Master',
        content: <ProductEdit master={true} onCancel={onCancel} childProduct={childProduct} />,
      },
    ];

    // if (!isEmpty(childProduct)) {
    //     tabs.push({'title' : 'Kind 1' , 'content' : <ProductEdit master={false} onCancel={onCancel} childProduct={childProduct}/>});
    // }

    return (
      <ACL authorized={['ROLE_SUPERADMIN']} silent={true}>
        <ProductEdit master={true} onCancel={onCancel} childProduct={childProduct} />
        {/*<FullWidthTabs
          tabs={tabs}
          orientation="vertical"
          childProduct={childProduct}
          onDelete={onDelete}
          onAddTab={this.handleAddTab}
        />*/}
      </ACL>
    );
  }
}

function mapStateToProps(state, props) {
  const getChildProduct = makeChildProduct();
  const {
    entities: { product },
  } = state;

  return {
    productId: props?.match?.params?.productId ? props.match.params.productId : null,
    products: !isEmpty(product) ? product : {},
    childProduct: getChildProduct(state),
  };
}

const enhance = compose(withRouter, connect(mapStateToProps, {}));

export default enhance(ProductTab);

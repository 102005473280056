import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import { Paper, IconButton, Typography } from '@mui/material';
import Show from './components/Show';
import Edit from '../Staff/components/Edit';
import Translate from '../../components/service/Translate';
import ActionAccountCircle from '@mui/icons-material/AccountCircle';
import ContentSave from '@mui/icons-material/Save';
import NavigationCancel from '@mui/icons-material/Cancel';
import EditorModeEdit from '@mui/icons-material/Edit';
import { loadProfile } from './actions';
import { updateStaff } from '../Staff/actions';
import { outdateEntity } from '../../actions';
import FullWidthTabs from '../../components/FullWidthTabs';
import ErrorBoundary from '../../components/ErrorBoundary';
import { ColorActions, Spacer, Title, ToolbarRoot } from '../../components/StyledElements/StyledElements';

class Profile extends Component {
  static propTypes = {
    updateStaff: PropTypes.func.isRequired,
    outdateEntity: PropTypes.func.isRequired,
    loadProfile: PropTypes.func.isRequired,
    currentUser: PropTypes.object,
  };

  state = {
    editing: false,
    valid: false,
    isSubmitting: false,
  };

  componentDidMount() {
    this.props.loadProfile(['firstname', 'name']);
    this.props.outdateEntity('staff'); // otherwise staff list wouldn't be loaded
  }

  shouldComponentUpdate = (nextProps, nextState) => {
    return (
      !isEqual(this.props.currentUser, nextProps.currentUser)
      || !isEqual(this.state.editing, nextState.editing)
      || !isEqual(this.state.isSubmitting, nextState.isSubmitting)
      || !isEqual(this.state.valid, nextState.valid)
    );
  };

  submit = () => {
    this.form.submit();
  };

  onTouchEdit = () => {
    this.setState({ editing: true, valid: false });
  };

  onTouchCancel = () => {
    this.setState({ editing: false, valid: false });
  };

  handleFormMount = (form) => {
    this.form = form;
  };

  handleSubmit = (form) => {
    const promises = [];
    const { updateStaff, currentUser } = this.props;

    this.setState({ valid: false, isSubmitting: true });
    promises.push(updateStaff(form, currentUser.id));

    return Promise.all(promises).then(
      (values) => {
        if (!(values && values[0] && values[0].error)) {
          this.setState({ editing: false });
        }
        this.setState({ isSubmitting: false });
      },
      () => {
        this.setState({ isSubmitting: false });
      },
    );
  };

  submitMyForm = null;

  handleSubmitMyForm = (e) => {
    if (this.submitMyForm) {
      this.submitMyForm(e);
    }
  };

  renderToolbar = () => {
    const { editing, isSubmitting } = this.state;

    if (!editing) {
      return (
        <div>
          <IconButton onClick={this.onTouchEdit} size="large">
            <EditorModeEdit />
          </IconButton>
        </div>
      );
    }

    return (
      <div>
        <IconButton
          aria-label={<Translate>save</Translate>}
          onClick={this.handleSubmitMyForm}
          color="primary"
          disabled={isSubmitting}
          size="large"
        >
          <ContentSave />
        </IconButton>
        <IconButton aria-label={<Translate>cancel</Translate>} onClick={this.onTouchCancel} size="large">
          <NavigationCancel />
        </IconButton>
      </div>
    );
  };

  render() {
    const { currentUser } = this.props;
    const { editing } = this.state;

    const tabs = [
      {
        title: 'Settings',
        content: editing ? (
          <ErrorBoundary>
            <Edit comingFromProfile={true} onSubmit={this.handleSubmit} staffId={currentUser.id} />
          </ErrorBoundary>
        ) : (
          <ErrorBoundary>
            <Show data={currentUser} />
          </ErrorBoundary>
        ),
      },
    ];

    if (!currentUser) {
      return null;
    }

    return (
      <div>
        <Paper>
          <ToolbarRoot>
            <Title>
              <Typography variant="h5" id="tableTitle">
                <ActionAccountCircle
                  sx={{
                    width: 40,
                    height: 40,
                    padding: 0,
                  }}
                />
                {currentUser.primaryText}
              </Typography>
            </Title>
            <Spacer />
            {!currentUser.keycloakUser && <ColorActions>{this.renderToolbar()}</ColorActions>}
          </ToolbarRoot>
          <ErrorBoundary>
            <FullWidthTabs tabs={tabs} />
          </ErrorBoundary>
        </Paper>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const {
    entities: { currentuser, staff },
  } = state;

  if (!currentuser || !staff) {
    return {};
  }

  const current = staff[currentuser.current.staff];

  return {
    currentUser: current,
  };
}

export default connect(mapStateToProps, {
  outdateEntity,
  loadProfile,
  updateStaff,
})(Profile);

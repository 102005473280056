import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ACL from '../../components/ACL';
import Affiliate from './Affiliate';

class AffiliateIndex extends Component {
  static propTypes = {
    requiredRoles: PropTypes.array,
    embed: PropTypes.bool,
  };

  static defaultProps = {
    requiredRoles: ['ROLE_SUPERADMIN'],
    resultset: [],
    embed: false,
  };

  render() {
    const { requiredRoles } = this.props;

    return (
      <ACL authorized={requiredRoles} silent={this.props.embed}>
        <Affiliate />
      </ACL>
    );
  }
}

export default AffiliateIndex;

import { GridFilterModel } from '@mui/x-data-grid';
import { GridParams, EeloyRequestParams } from './DataGridTypes';

export const getFilterType = (filterModel: GridFilterModel) => {
  let filterType;

  return filterModel.items.map((item) => {
    switch (item.operator) {
      case 'is':
        switch (item.field) {
          case 'affiliateHead':
          case 'headquarter':
          case 'embedded':
          case 'enabled':
          case 'local':
          case 'modified':
            filterType = 'boolean';
            break;
          case 'created':
          case 'updated':
            filterType = 'date_eq';
            break;
          default:
            filterType = 'string_eq';
            break;
        }
        break;
      case 'not':
        switch (item.field) {
          case 'affiliateHead':
          case 'headquarter':
          case 'embedded':
          case 'enabled':
          case 'local':
          case 'modified':
            filterType = 'boolean';
            break;
          case 'created':
          case 'updated':
            filterType = 'date_not';
            break;
          default:
            filterType = 'string_not';
            break;
        }
        break;
      default:
        filterType = item.operator;
    }
    return {
      field: item.field,
      type: filterType,
      value: item.value,
    };
  });
};

export const convertGridParamsToRequestParams = (gridParams?: GridParams): EeloyRequestParams => {
  if (!gridParams) {
    return {};
  }
  const sortColumns = gridParams.sortModel ? gridParams.sortModel?.map((value) => value.field) : [];
  const sortOrders = gridParams.sortModel ? gridParams.sortModel?.map((value) => value.sort) : [];
  const limit = gridParams.paginationModel?.pageSize;
  const page = gridParams.paginationModel?.page;
  const offset = page && limit ? page * limit : undefined;
  const filter = gridParams.filterModel ? getFilterType(gridParams.filterModel) : [];
  const join = gridParams.joinModel;
  return {
    sortColumns,
    sortOrders,
    limit,
    join,
    offset,
    filter,
  };
};

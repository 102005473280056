import PropTypes from 'prop-types';
import React, { Component } from 'react';
import Translate from '../../../components/service/Translate';
import DateTime from '../../../components/service/DateTime';
import { Table, TableData } from '../../../components/StyledElements/StyledElements';
import { Box } from '@mui/material';

class Log extends Component {
  static propTypes = {
    owner: PropTypes.object.isRequired,
  };

  render() {
    const { owner } = this.props;

    if (!owner) {
      return null;
    }

    return (
      <Box sx={{ pt: 3 }}>
        <Table width="100%">
          <thead>
            <tr>
              <TableData width="15%">
                <Translate>Date</Translate>
              </TableData>
              <TableData width="15%">
                <Translate>Activity</Translate>
              </TableData>
            </tr>
          </thead>
          <tbody>
            <tr key="ownerlog_created">
              <TableData width="15%">
                <DateTime format="dd.mm.yyyy HH:MM">{owner.created}</DateTime>
              </TableData>
              <TableData width="35%">
                <Translate>Created</Translate>
              </TableData>
            </tr>
            <tr key="ownerlog_updated">
              <TableData width="15%">
                <DateTime format="dd.mm.yyyy HH:MM">{owner.updated}</DateTime>
              </TableData>
              <TableData width="35%">
                <Translate>Updated</Translate>
              </TableData>
            </tr>
          </tbody>
        </Table>
      </Box>
    );
  }
}

export default Log;

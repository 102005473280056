import React, { Component } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@mui/material';
import Translate from './service/Translate';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class DeleteDialog extends Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    current: PropTypes.object,
  };

  state = {
    open: true,
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    return !isEqual(nextProps.rowId, this.props.rowId) || !isEqual(nextState.open, this.state.open);
  };

  handleDelete = () => {
    this.setState({ open: false });
    this.props.onSubmit(this.props.current.id, this.props.current.deleteToken);
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  render() {
    const { current, onCancel } = this.props;
    return (
      <>
        <Dialog
          open={this.state.open}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">{<Translate>Are you sure?</Translate>}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              <Translate wildcards={{ '%current.primaryText%': current.primaryText }}>
                Do you really want to remove %current.primaryText%?
              </Translate>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancel} color="primary">
              <Translate>cancel</Translate>
            </Button>
            <Button onClick={this.handleDelete} color="primary">
              <Translate>remove</Translate>
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default DeleteDialog;

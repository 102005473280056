import { isAxiosError } from 'axios';

export type RejectedValue = {
  status: number;
  message: {
    type: string;
    text: null | string;
  };
};

export default function handleAxiosError(err: unknown): RejectedValue {
  if (isAxiosError(err) && err.response) {
    return {
      status: err.response.status,
      message: {
        type: 'error',
        text: null,
      },
    };
  }

  return {
    status: 500,
    message: {
      type: 'error',
      text: null,
    },
  };
}

import React, { useEffect, useState } from 'react';
import { Skeleton } from '@mui/material';
import Grid from '@mui/material/Grid2';
import CustomerGroupChart from './CustomerGroupChart';
import StatisticTable from './StatisticTable';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { fetchRecipients } from '../../StatisticCampaignSlice';

type RecipientsProps = {
  campaignId: number;
};

export default function Recipients({ campaignId }: RecipientsProps) {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const { customerGroup, customerType, campaignVersion } = useAppSelector(
    (state) => state.statisticCampaign.recipients,
  );

  useEffect(() => {
    setLoading(true);
    dispatch(fetchRecipients({ id: campaignId })).then(() => setLoading(false));
  }, []);

  return (
    <Grid container spacing={3}>
      {loading && (
        <>
          <Grid
            size={{
              xs: 12,
              md: 8,
            }}
          >
            <Skeleton variant="rounded" width="100%" height="560px" sx={{ mb: 3, mt: 3 }} />
          </Grid>
          <Grid
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <Grid container spacing={{ xs: 1, sm: 2, md: 0 }}>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 12,
                }}
              >
                <Skeleton variant="rounded" width="100%" height="215px" sx={{ mb: 3, mt: 3 }} />
              </Grid>
              <Grid
                size={{
                  xs: 12,
                  sm: 6,
                  md: 12,
                }}
              >
                <Skeleton variant="rounded" width="100%" height="270px" sx={{ mb: 3, mt: 3 }} />
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      {customerGroup ? (
        <>
          <Grid
            size={{
              xs: 12,
              md: 8,
            }}
          >
            <CustomerGroupChart title="Empfänger je Kundengruppe" data={customerGroup.data} />
          </Grid>
          <Grid
            size={{
              xs: 12,
              md: 4,
            }}
          >
            <Grid container spacing={3}>
              {customerType && (
                <Grid
                  size={{
                    xs: 12,
                    sm: 6,
                    md: 12,
                  }}
                >
                  <StatisticTable title="Empfänger je Kundentyp" header="Kundentyp" rows={customerType} context="app" />
                </Grid>
              )}
              {campaignVersion && (
                <Grid
                  size={{
                    xs: 12,
                    sm: 6,
                    md: 12,
                  }}
                >
                  <StatisticTable
                    title="Empfänger je Sparte"
                    header="Sparte"
                    rows={campaignVersion}
                    context="campaignVersion"
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          {customerType && (
            <Grid size={4}>
              <StatisticTable title="Empfänger je Kundentyp" header="Kundentyp" rows={customerType} context="app" />
            </Grid>
          )}
          {campaignVersion && (
            <Grid size={4}>
              <StatisticTable
                title="Empfänger je Sparte"
                header="Sparte"
                rows={campaignVersion}
                context="campaignVersion"
              />
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
}

import ValidationRules from '../ValidationRules';

function testPassword(pw: string | undefined): boolean {
  if (pw === '' || typeof pw === 'undefined') {
    return true;
  }

  const categories = ValidationRules.passwordRegexCategories;
  let counter = 0;

  categories.forEach((category) => {
    if (category.test(pw)) {
      counter += 1;
    }
  });

  return counter >= 3;
}

export default testPassword;

import React from 'react';
import PropTypes from 'prop-types';
import { Table, TableData } from './StyledElements/StyledElements';
import { Box } from '@mui/material';

const renderRow = (rowData, index) => {
  if (!Array.isArray(rowData.row)) {
    return (
      <tr key={index}>
        <TableData width="15%" textColor="text.secondary">
          {rowData.row.label}
        </TableData>
        <TableData width="35%">
          {rowData.row.data || '--'} {rowData.row.unit || ''}
        </TableData>
      </tr>
    );
  } else {
    return (
      <tr key={index}>
        <TableData width="15%" textColor="text.secondary">
          {rowData.row[0].label}
        </TableData>
        <TableData width="35%">
          {rowData.row[0].data || '--'} {rowData.row[0].unit || ''}
        </TableData>
        <TableData width="15%" textColor="text.secondary">
          {rowData.row[1].label}
        </TableData>
        <TableData width="35%">
          {rowData.row[1].data || '--'} {rowData.row[1].unit || ''}
        </TableData>
      </tr>
    );
  }
};

const PropertiesTable = (props) => {
  const { tableData } = props;
  return (
    <Box sx={{ p: 3 }}>
      <Table width="100%">
        <tbody>{tableData.map((rowData, index) => renderRow(rowData, index))}</tbody>
      </Table>
    </Box>
  );
};

PropertiesTable.propTypes = {
  tableData: PropTypes.array.isRequired,
};

export default PropertiesTable;

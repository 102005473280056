import { Middleware } from 'redux';
import { normalize, schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import { get, isEmpty, unset } from 'lodash';
import 'isomorphic-fetch';

// Define types for pagination configuration
type PaginationConfig = {
  total: number | null;
  url: string | null;
  current: number | null;
  limit: number | null;
  prev: number;
  next: number | null;
  more: boolean;
};

type PaginationConfigReturnType = { pagination: PaginationType };

type PaginationType =
  | {
      [key: string]: {
        [entityId: string]: PaginationConfig;
      };
    }
  | {
      [key: string]: PaginationConfig;
    };

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.

const usedKeys: string[] = [];
const usedControllers: Record<string, AbortController> = {};

function generatePagination(
  key: string,
  entityId: string | null,
  offset: number | null,
  limit: number | null,
  total: number | null,
  url: string | null,
  paged: number | null,
): PaginationConfigReturnType {
  const prev = offset !== null ? offset - (limit || 0) : 0;
  const next = offset !== null && limit !== null ? offset + limit : null;

  let pagination: PaginationType = {};

  if (entityId !== null) {
    pagination = {
      [key]: {
        [entityId]: {
          total,
          url: url || '',
          current: offset ?? 0,
          limit,
          prev,
          next,
          more: url?.indexOf('limit') === -1 || paged === limit || (next !== null && next <= (total || 0)),
        },
      },
    };
  } else {
    pagination = {
      [key]: {
        total,
        url: url || '',
        current: offset ?? 0,
        limit,
        prev,
        next: null, // Set to null for non-entity case
        more: url?.indexOf('limit') === -1 || paged === limit || (next !== null && next <= (total || 0)),
      },
    };
  }

  return { pagination };
}

type MiddlewareOptions = RequestInit & {
  signal?: AbortSignal;
  headers?: Record<string, string>;
};

type ApiResponse = {
  results: never;
  message?: string;
  newId?: string;
};

async function callApi(
  endpoint: string,
  method: string,
  schema?: schema.Entity | schema.Array,
  data?: FormData,
  headers?: Record<string, string>,
  path?: string,
  key?: string,
): Promise<ApiResponse> {
  let controller: AbortController | null;
  if (key) {
    controller = new AbortController();
    if (usedControllers[key]) {
      usedControllers[key].abort();
    }
    usedControllers[key] = controller!;
  }

  const options: MiddlewareOptions = {
    credentials: 'include',
    method,
    mode: 'cors',
    ...(controller! && controller.signal ? { signal: controller.signal } : {}),
    ...(method === 'POST' || method === 'DELETE' ? { body: data } : {}),
    ...(headers !== null ? { headers } : {}),
  };

  const response = await fetch(endpoint, options);
  const json = await response.json();
  const { json: json1, response: response1 } = { json, response };
  if (key && usedKeys.includes(key)) {
    const index = usedKeys.indexOf(key);
    if (index > -1) {
      usedKeys.splice(index, 1);
      // @ts-ignore todo later
      usedControllers.splice(index, 1);
    }
  }
  if (!response1.ok) {
    const out = { ...json1, status: response1.status };
    return Promise.reject(out);
  }
  let results;
  let msg = {};
  let newId = {};
  let id = null;
  let paged = null;
  let pagination = {};
  let entityKey = '';
  if (schema) {
    if (json1) {
      if (json1.results) {
        results = json1.results;
        id = json1.results.id ? json1.results.id : null;
      } else if (json1.data) {
        results = json1.data;
        id = json1.data.id ?? null;
      } else {
        results = json1;
      }
    } else {
      results = json1;
      id = results.id ? results.id : null;
    }

    results = normalize(camelizeKeys(results), schema);
    // @ts-ignore todo later
    entityKey = schema.key || schema[0]?.key;
    // @ts-ignore todo later
    results.key = entityKey;

    // @ts-ignore todo later
    if (schema.key) {
      unset(results, 'result');
    }

    if (path) {
      paged = get(results.entities, path);
    }

    pagination = generatePagination(
      entityKey,
      id,
      Number.isNaN(json1.offset) ? null : json1.offset,
      Number.isNaN(json1.limit) ? null : json1.limit,
      Number.isNaN(json1.total) ? null : json1.total,
      response1.url,
      path && paged ? paged.length : null,
    );
  } else {
    results = json1;
  }
  if (json1.message) {
    msg = { info: json1.message };
  }
  if (json1.newId) {
    newId = { newId: json1.newId, newUpdateToken: json1.newUpdateToken };
  }
  return { ...msg, ...newId, ...results, ...pagination };
}

export const CALL_API = 'Call API';

function getApiError(error: { code: number }) {
  let errorMessage = '';

  if (error.code === 20) {
    errorMessage = 'Abort Error';
  } else if (isEmpty(error)) {
    errorMessage = 'Something bad happened';
  } else {
    Object.entries(error).forEach(([key, value]) => {
      if (key === 'message') {
        errorMessage += value;
      } else if (key !== 'status') {
        // @ts-ignore todo later
        errorMessage += `${value.property_path}: ${value.message} `;
      }
    });
  }
  return errorMessage;
}

function actionWith(
  data: {
    response?: ApiResponse;
    type?: string;
    path?: string;
    error?: string;
  },
  action: Action,
) {
  const finalAction = { ...action, ...data };
  // @ts-ignore will be removed after refactoring
  delete finalAction[CALL_API];
  return finalAction;
}

interface CallApiAction {
  [CALL_API]: {
    types: [string, string, string];
    endpoint: string;
    method?: string;
    data?: FormData;
    headers?: Record<string, string>;
    path?: string;
    key?: string;
    schema?: schema.Entity | schema.Array;
  };
}

type Action = CallApiAction;
function isCallApiAction(action: unknown): action is CallApiAction {
  return (action as CallApiAction)[CALL_API] !== undefined;
}

// A Redux middleware that interprets actions with CALL_API info specified.
// Performs the call and promises when such actions are dispatched.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const apiMiddleware: Middleware = (_api) => (next) => (action) => {
  if (!isCallApiAction(action)) {
    return next(action);
  }

  // Aktion ist vom Typ Action
  const callAPI = action[CALL_API];

  if (typeof callAPI === 'undefined') {
    return next(action);
  }

  const { endpoint } = callAPI;
  let { method } = callAPI;
  const { data, headers, path, key, schema, types } = callAPI;

  if (typeof endpoint !== 'string') {
    throw new Error('Specify a string endpoint URL.');
  }

  if (!schema && method !== 'POST' && method !== 'DELETE') {
    throw new Error('Specify one of the exported Schemas.');
  }

  if (!Array.isArray(types) || types.length !== 3) {
    throw new Error('Expected an array of three action types.');
  }

  if (!types.every((type) => typeof type === 'string')) {
    throw new Error('Expected action types to be strings.');
  }

  if (typeof method === 'undefined') {
    method = 'GET';
  }

  const [requestType, successType, failureType] = types;
  next(actionWith({ type: requestType }, action));

  return callApi(endpoint, method, schema, data, headers, path, key).then(
    (response) =>
      next(
        actionWith(
          {
            response,
            type: successType,
            path,
          },
          action,
        ),
      ),
    (error) =>
      next(
        actionWith(
          {
            response: error,
            type: failureType,
            error: getApiError(error),
          },
          action,
        ),
      ),
  );
};

export default apiMiddleware;

import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { normalize, schema } from 'normalizr';
import axios from 'axios';
import { camelizeKeys } from 'humps';
import { Affiliate } from './AffiliateType';
import { Schemas } from '../../schemas';
import type { RootState } from '../../reducers';

const affiliateAdapter = createEntityAdapter<Affiliate>({
  sortComparer: (a, b) => a.id - b.id,
});

export const fetchAllAffiliates = createAsyncThunk('affiliate/fetchAll', async () => {
  const response = await axios.get(Routing.generate('affiliate_index', null, true), { withCredentials: true });

  const normalized = normalize<
    schema.Entity<Affiliate>,
    {
      affiliate: Record<string, Affiliate>;
    }
  >(camelizeKeys(response.data.data), Schemas.AFFILIATES);

  return {
    entities: normalized.entities,
    pagination: response.data.pagination,
    resultset: normalized.result,
  };
});

const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState: affiliateAdapter.getInitialState({
    updateToken: null,
    pagination: {},
    resultset: [],
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllAffiliates.fulfilled, (state, action) => {
      const { affiliate } = action.payload.entities;

      if (affiliate) {
        affiliateAdapter.upsertMany(state, affiliate);
      }

      state.pagination = action.payload.pagination;
      state.resultset = action.payload.resultset;
    });
  },
});

export const {
  selectAll: selectAllAffiliates,
  selectById: selectAffiliateById,
  selectIds: selectAffiliatesByIds,
} = affiliateAdapter.getSelectors<RootState>((state) => state.affiliate);

export default affiliateSlice.reducer;

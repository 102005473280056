const dueDateConditions = [
  {
    type: 'Due Date',
    label: 'Wartungsfälligkeit',
    description: 'Fragt ab ob, die Wartung im nächsten Monat fällig ist. (Hinweis: Ausgehend vom Produktionsdatum)',
    before: '{%if car.nextService and car.nextService|date("Y-m") == job.normalizedPrinted|date("Y-m") %}',
    after: '{% endif %}',
  },
  {
    type: 'Due Date',
    label: 'Wartungsfälligkeit 2 Monate',
    description: 'Fragt ab, ob die Wartung in 2 Monaten fällig ist. (Hinweis: Ausgehend vom Produktionsdatum)',
    before:
      '{%if car.nextService and car.nextService|date("Y-m") == job.normalizedPrinted|date_modify("+1 month")|date("Y-m") %}',
    after: '{% endif %}',
  },
  {
    type: 'Due Date',
    label: 'HU Fälligkeit',
    description: 'Fragt ab ob, die HU im nächsten Monat fällig ist. (Hinweis: Ausgehend vom Produktionsdatum)',
    before: '{% if car.hu and car.hu|date("Y-m") == job.normalizedPrinted|date("Y-m") %}',
    after: '{% endif %}',
  },
  {
    type: 'Due Date',
    label: 'Hu Fälligkeit 2 Monate',
    description: 'Fragt ab ob, die HU in 2 Monaten fällig ist. (Hinweis: Ausgehend vom Produktionsdatum)',
    before: '{% if car.hu and car.hu|date("Y-m") == job.normalizedPrinted|date_modify("+1 month")|date("Y-m") %}',
    after: '{% endif %}',
  },
  {
    type: 'Due Date',
    label: 'FSP Fälligkeit',
    description: 'Fragt ab ob, die FSP im nächsten Monat fällig ist. (Hinweis: Ausgehend vom Produktionsdatum)',
    before: '{% if car.tp and car.tp|date("Y-m") == job.normalizedPrinted| date("Y-m") %}',
    after: '{% endif %}',
  },
  {
    type: 'Due Date',
    label: 'FSP Fälligkeit 2 Monate',
    description: 'Fragt ab, ob die SP in 2 Monaten fällig ist. (Hinweis: Ausgehend vom Produktionsdatum)',
    before: '{% if car.tp and car.tp|date("Y-m") == job.normalizedPrinted|date_modify("+1 month")|date("Y-m") %}',
    after: '{% endif %}',
  },
  {
    type: 'Due Date',
    label: 'SP Fälligkeit',
    description: 'Fragt ab, ob die SP im nächsten Monat fällig ist. (Hinweis: Ausgehend vom Produktionsdatum)',
    before: '{% if car.sp and car.sp|date("Y-m") == job.normalizedPrinted|date("Y-m") %}',
    after: '{% endif %}',
  },
  {
    type: 'Due Date',
    label: 'SP Fälligkeit 2 Monate',
    description: 'Fragt ab, ob die SP in 2 Monaten fällig ist. (Hinweis: Ausgehend vom Produktionsdatum)',
    before: '{% if car.sp and car.sp|date("Y-m") == job.normalizedPrinted|date_modify("+1 month")|date("Y-m") %}',
    after: '{% endif %}',
  },
];

export default dueDateConditions;

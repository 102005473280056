import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { normalize, schema } from 'normalizr';
import { camelizeKeys } from 'humps';
import { Schemas } from '../../schemas';
import { Email } from './EmailType';
import type { RootState } from '../../reducers';
import { Season } from '../Season/SeasonType';

const emailAdapter = createEntityAdapter<Email>({
  sortComparer: (a, b) => a.id - b.id,
});

export const fetchAllEmails = createAsyncThunk('email/fetchAll', async () => {
  const response = await axios.get(Routing.generate('email_index', null, true), { withCredentials: true });
  const normalized = normalize<
    schema.Entity<Email>,
    {
      email: Record<string, Email>;
      season: Record<string, Season>;
    }
  >(camelizeKeys(response.data.data), Schemas.EMAILS);

  return { entities: normalized.entities };
});

const emailSlice = createSlice({
  name: 'email',
  initialState: emailAdapter.getInitialState(),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchAllEmails.fulfilled, (state, action) => {
      const { email } = action.payload.entities;

      if (email) {
        emailAdapter.upsertMany(state, action.payload.entities.email);
      }
    });
  },
});

export const {
  selectAll: selectAllEmails,
  selectById: selectEmailById,
  selectIds: selectEmailsByIds,
} = emailAdapter.getSelectors<RootState>((state) => state.email);

export default emailSlice.reducer;
